import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { PageTitle, Loading } from '../../../components/StyledComponents';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { searchFunc } from '../../../components/lib';
import { Visibility } from '@mui/icons-material';
import { Tooltip, Grid, FormControl, InputLabel, OutlinedInput, IconButton, InputAdornment, Typography } from '@mui/material';

const Table = styled.div`
	margin-top: 2rem;	
`;
const Action = styled.div`
	.edit {
		color: #38383d;
		margin: 0 0.25rem;
		cursor: pointer;
	}
	.delete {
		color: #F43232;
		margin: 0 0.25rem;
		cursor: pointer;
	}
`;
const Wrapper = styled.div`
	position: relative;
	width: 100%;
	.top-box {
		display: flex;
		gap: 1rem;
		justify-content: space-between;
		.formControl {
			width: 100%;
			max-width: 400px;
		}
		.add-btn {
			height: 3.625rem;
			width: 10rem;
			border: 1px solid rgba(0, 0, 0, 0.3);
			&:hover {
				border: 1px solid #F43232;
			}
		}
	}

	@media(max-width: 768px) {
		.top-box {
			flex-direction: column;
			align-items: flex-end;
			.formControl {
				max-width: 100%;
			}
		}
	}
`;

const Customers = () => {
	let token = sessionStorage.getItem('token');
	const [loading, setLoading] = useState(false);
	const [tempData, setTempData] = useState(null);
	const [customers, setCustomers] = useState(null);
	const [error, setError] = useState(null);

	const cols = [
		{
			name: 'Name',
			selector: row => row.account_name,
			sortable: true
		},
		{
			name: 'Account Number',
			selector: row => row.account_number,
			sortable: true
		},
		{
			name: 'Balance',
			selector: row => Number(row.balance).toLocaleString(),
			sortable: true
		},		
	];

	const getAccounts = async () => {
		setLoading(true);
		const config = {
			method: 'get',
			url: "list/customer/above50",
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			}
		}

		try {
			const res = await axios(config);
			setLoading(false);
			if(res.status === 200) {
				const { data } = res;
				setTempData(data);
				setCustomers(data);
			}
		} catch (error) {
			console.log(error);
			setError(error);
			setLoading(false);
		}
	};

	// search
	const [searchTxt, setSearchTxt] = useState('');
	const onSearch = e => {
		e.preventDefault();
		let val = e.target.value;
		searchFunc(e, val, searchTxt, setSearchTxt, setCustomers, tempData);
	}

	useEffect(() => {
		getAccounts();
	}, []);

	return (
			<Wrapper>
				<PageTitle>Notifications</PageTitle>
				<Grid container sx={{ alignItems: "center", justifyContent: "space-between", marginY: '2rem' }}>
					<Grid item xs={12} className="top-box" >
						<FormControl className='formControl' variant="outlined">
							<InputLabel htmlFor="search-input">Search</InputLabel>
							<OutlinedInput
								id="search-input"
								onChange={onSearch}
								value={searchTxt}
								endAdornment={
									<InputAdornment position="end">
										<IconButton edge="end">
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								}
								label="Search"
							/>
						</FormControl>
					</Grid>
				</Grid>
				<Fragment>
					{
						loading === true ? <Loading text="Please wait" /> :
							(
								error ? <Typography sx={{ textAlign: 'center' }} color="error">{error.message}</Typography> :
								customers !== null &&
								<>
									<Typography variant="h5">Customers with balance above 50,000</Typography>
									<Table>
										<DataTable
											columns={cols}
											data={customers}
											pagination paginationPerPage={10}
										/>
									</Table>
								</>
							)
					}
				</Fragment>
			</Wrapper>
	)
};

export default Customers;