import React from 'react';
import { Modal, Paper, Button, Box, Grid, TextField, Select, MenuItem, FormControl, InputLabel, Autocomplete } from '@mui/material';
import { Header } from '../../../components/StyledComponents';
import dayjs from 'dayjs';

const modalStyle = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
};

const style = {
	boxShadow: 6,
	width: '100%',
	maxWidth: '750px',
	margin: '2rem',
}

const boxStyle = {
	padding: '2rem',
}

const FilterModal = ({ open, close, reporting, setReport, customers, areacodes }) => {	
	const [data, setData] = React.useState({
		startDate: dayjs(new Date()).startOf('M').$d.toLocaleDateString("en-CA"),
		endDate: dayjs(new Date()).$d.toLocaleDateString("en-CA"),
		transactionType: '', areaCode: ''
	});

	const [customerName, setCustomerName] = React.useState(null);

	const handleChange = (prop) => (e) => {
		let { value } = e.target;
    setData({ ...data, [prop]: value });
  };
	
	const handleSubmit = () => {
		let start = data.startDate;
		let end = data.endDate;
		let filteredReport = reporting.filter(item => (Date.parse(item["Transaction date"]) >= Date.parse(start)) && (Date.parse(end) >= Date.parse(item["Transaction date"])));
		if(data.transactionType !== "") {
			filteredReport = filteredReport.filter(item => item["Transaction Type"].toLowerCase() === data.transactionType);
		}
		if(customerName) {
			filteredReport = filteredReport.filter(item => Number(item.account_number) === customerName.id);
		}
		if(data.areaCode.trim() !== "") {
			filteredReport = filteredReport.filter(item => Number(item["Area Code"]) === Number(data.areaCode));
		}
		setReport(filteredReport);
		close();
	};

	return (
		<Modal open={open} onClose={() => null} sx={modalStyle}>
			<Paper sx={style}>
				<Box sx={boxStyle}>
					<Header>Filter Report</Header>
					<Box sx={{ height: '3vh' }} />
					<Grid container spacing={4}>
						<Grid item xs={12} sm={6}>
							<TextField name="startDate" defaultValue={data.startDate} onChange={handleChange("startDate")} label="Start Date" type="date" fullWidth InputLabelProps={{ shrink: true }} />
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField name="startDate" defaultValue={data.endDate} onChange={handleChange("endDate")} label="End Date" type="date" fullWidth InputLabelProps={{ shrink: true }} />
						</Grid>
						<Grid item xs={12}>
							<FormControl fullWidth>
								<InputLabel>Transaction Type</InputLabel>
								<Select name="transactionType" onChange={handleChange("transactionType")} value={data.transactionType} label="Transaction Type" fullWidth>
									<MenuItem value="">All</MenuItem>
									<MenuItem value="deposit">Deposit</MenuItem>
									<MenuItem value="investment">Investment</MenuItem>
									<MenuItem value="loan repayment">Loan Repayment</MenuItem>
									<MenuItem value="transfer">Transfer</MenuItem>
									<MenuItem value="withdrawal">Withdrawal</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControl fullWidth>
								<InputLabel>Area Code</InputLabel>
								<Select name="areaCode" onChange={handleChange("areaCode")} value={data.areaCode} label="Area Code" fullWidth>
									<MenuItem value="">All</MenuItem>
									{
										areacodes && areacodes.map(item => (
											<MenuItem value={item.area_code} key={item.area_code}>{item.area_name + " (" + item.area_code + ")"}</MenuItem>
										))
									}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControl fullWidth>
								<Autocomplete
									disablePortal
									options={customers}
									value={customerName}
									onChange={(e, val) => setCustomerName(val)}
									renderInput={(params) => 
										<TextField
											{...params} label="Search Customer"
											variant="outlined" fullWidth
										/>
									}
									sx={{ width: '100%' }}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Button onClick={handleSubmit} sx={{ height: '3.625rem' }} variant="contained" fullWidth>Submit</Button>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Button onClick={close} sx={{ height: '3.625rem' }} variant="outlined" fullWidth>cancel</Button>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Modal>
	)
}

export default FilterModal;