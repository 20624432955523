import React from 'react';
import { Modal } from 'react-responsive-modal';
import styled from 'styled-components';
import { Container, Header, TextInput, SelectMenu } from '../../components/StyledComponents';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { CircularProgress, Grid, Typography, Button, Input } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const FormWrapper = styled.div`
	margin: 2rem 0;
	padding-bottom: 1.5rem;
`;

const NewUser = ({ open, close }) => {
	let token = JSON.parse(sessionStorage.getItem('token'));
	const INIT = {
		userRoleId: '', firstName: '', lastName: '', otherName: '', phone: '', email: '', passcode: '', photo: '', area_code: '', address: '', nearest_bus_stop: '', city: '', gender: '', state: '', country: '', date_of_birth: '', next_of_kin_name: '', kin_phone: '', kin_address: '', kin_relationship: '', guarantor_name: '', guarantor_phone: '', guarantor_address: ''
	};
	
	const VALIDATE = Yup.object().shape({
		firstName: Yup.string().trim().required('Required'),
		otherName: Yup.string().trim().required('Required'),
		lastName: Yup.string().trim().required('Required'),
		phone: Yup.string().trim().required('Required'),
		email: Yup.string().email('Please enter a valid email address').required('Required'),
		gender: Yup.string().required('Required'),
		date_of_birth: Yup.string().required('Required'),
		address: Yup.string().required('Required'),
		nearest_bus_stop: Yup.string().required('Required'),
		city: Yup.string().required('Required'),
		state: Yup.string().required('Required'),
		country: Yup.string().required('Required'),
		area_code: Yup.string().required('Required'),
		next_of_kin_name: Yup.string().required('Required'),
		kin_phone: Yup.string().required('Required'),
		kin_address: Yup.string().required('Required'),
		kin_relationship: Yup.string().required('Required'),
		guarantor_name: Yup.string().required('Required'),
		guarantor_phone: Yup.string().required('Required'),
		guarantor_address: Yup.string().required('Required'),
		userRoleId: Yup.number().required('Required'),
		passcode: Yup.string().trim().required('Required'),
		photo: Yup.mixed().required('Please upload a jpg/png file'),
	});

	const usrType = [
		{ val: 1, name: 'Super Admin'},
		{ val: 2, name: 'Auditor'},
		{ val: 3, name: 'Loan Admin'},
		{ val: 4, name: 'Investment Admin'},
		{ val: 5, name: 'Transfer Admin'},
		{ val: 6, name: 'Field Agent'},
		{ val: 7, name: 'Admin'},
	];

	const gender = [
		{ val: "F", name: 'Female'},
		{ val: "M", name: 'Male'},
		{ val: "Other", name: 'Other'}
	];

	const [countries, setCountries] = React.useState(null);
	const getCountries = async () => {
		let URL = "https://restcountries.com/v3.1/all";
		try {
			let res = await axios.get(URL);
			let { data } = res;
			data = data.map(item => {
				return {
					name: item.name.common,
					val: item.name.common,
				}
			})
			setCountries(data);
		} catch (error) {
			console.log(error);
		}
	}

	const [areas, setAreas] = React.useState(null);
	const getMarketAreas = async () => {
		const config = {
			method: 'get',
			url: "list/market_area",
			headers: {
				'Authorization': 'Bearer ' + token
			}
		}
	
		try {
			const res = await axios(config);
			if(res.status === 200) {
					let { data } = res;
					data = data.sort((a, b) => a.area_name.localeCompare(b.area_name));
					data = data.map(item => {
						return {
							name: item.area_name + " (" + item.area_code + ")" ,
							val: item.area_code,
						}
					})
					setAreas(data);
				}
			} catch (error) {
				console.log(error);
			}
	}

	React.useEffect(() => {
		getCountries();
	}, []);

	React.useEffect(() => {
		getMarketAreas();
	}, []);

	return (
		<Modal classNames={{ modal: 'customModal' }} open={open} onClose={close} center>
			<Container>
				<Header>New User</Header>
				<FormWrapper>
					<Formik
						initialValues={INIT}
						validationSchema={VALIDATE}
						validateOnChange={false}
						validateOnBlur={false}
						onSubmit={async (values, { setSubmitting }) => {
							const data = new FormData();
							const { userRoleId, firstName, lastName, otherName, phone, email, passcode, photo, area_code, address, nearest_bus_stop, city, gender, state, country, date_of_birth, next_of_kin_name, kin_phone, kin_address, kin_relationship, guarantor_name, guarantor_phone, guarantor_address } = values;

							data.append('user_role_id', userRoleId);
							data.append('firstname', firstName);
							data.append('lastname', lastName);
							data.append('middlename', otherName);
							data.append('area_code', area_code);
							data.append('phone_no', phone);
							data.append('personal_email', email);
							data.append('password', passcode);
							data.append('address', address);
							data.append('nearest_bus_stop', nearest_bus_stop);
							data.append('gender', gender);
							data.append('city', city);
							data.append('state', state);
							data.append('country', country);
							data.append('date_of_birth', date_of_birth);
							data.append('next_of_kin_name', next_of_kin_name);
							data.append('kin_phone', kin_phone);
							data.append('kin_address', kin_address);
							data.append('kin_relationship', kin_relationship);
							data.append('guarantor_name', guarantor_name);
							data.append('guarantor_phone', guarantor_phone);
							data.append('guarantor_address', guarantor_address);
							data.append('image', photo);
							
							const config = {
								method: 'post',
								url: 'register/user',
								headers: { 
									'Authorization': 'Bearer ' + token
								},
								data : data
							};
							try {
								const res = await axios(config);
								if(res.status === 200) {
									toast.success("User created successfully");
									setSubmitting(false);
									close();
								}
							} catch (error) {
								console.log(error);
								toast.error(JSON.stringify(error.response.data));
								setSubmitting(false);
								close();
							}
						}}
					>
						{({ values, setFieldValue, isSubmitting }) => {
							return (
								<Form>
									<Grid container columnSpacing={3} rowSpacing={4} alignItems="center">
										<Grid item xs={12}>
											<Typography variant="button">Account information</Typography>
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="firstName" label="First name" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="otherName" label="Middle name" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="lastName" label="Last name" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="phone" label="Phone number" />
										</Grid>
										<Grid item xs={12} md={6}>
											<SelectMenu
												label="Gender"
												name="gender"
												options={gender.sort((a, b) => a.name.localeCompare(b.name))}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="email" label="Email" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="date_of_birth" label="Date of Birth" type="date" InputLabelProps={{ shrink: true }} />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="address" label="Address" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="nearest_bus_stop" label="Nearest Bus Stop" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="city" label="City" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="state" label="State" />
										</Grid>
										<Grid item xs={12} md={6}>
											{
												countries &&
												<SelectMenu
													label="Country"
													name="country"
													options={countries.sort((a, b) => a.name.localeCompare(b.name))}
												/>
											}
										</Grid>
										<Grid item xs={12}></Grid>
										<Grid item xs={12}>
											<Typography variant="button">Next of kin information</Typography>
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="next_of_kin_name" label="Next of Kin Name" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="kin_phone" label="Phone" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="kin_address" label="Address" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="kin_relationship" label="Relationship" />
										</Grid>
										<Grid item xs={12}></Grid>
										<Grid item xs={12}>
											<Typography variant="button">guarantor information</Typography>
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="guarantor_name" label="Guarantor Name" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="guarantor_phone" label="Guarantor Phone" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="guarantor_address" label="Guarantor Address" />
										</Grid>
										<Grid item xs={12}></Grid>
										<Grid item xs={12}>
											<Typography variant="button">user Login information</Typography>
										</Grid>
										<Grid item xs={12} md={6}>
											{
												areas &&
												<SelectMenu
													label="Area Code"
													name="area_code"
													options={areas}
												/>
											}
										</Grid>
										<Grid item xs={12} md={6}>
											<SelectMenu
												label="User Role"
												name="userRoleId"
												options={usrType.sort((a, b) => a.name.localeCompare(b.name))}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="passcode" label="Password" type="password" />
										</Grid>
										<Grid item xs={12} md={6}>
                  		<Input type="file" name="photo" onChange={e => setFieldValue("photo", e.target.files[0])} />
											<Typography sx={{ fontSize: '0.85rem', color: '#d32f2f', margin: '0.25rem 0.75rem' }}>
												<ErrorMessage name="photo" />
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Button
												disabled={isSubmitting} variant="contained" type="submit" fullWidth
												sx={{ height: '3.5rem' }}
											>
												{
													isSubmitting === true ?
													<CircularProgress sx={{ color: '#FFFFFF' }} /> :
													'Add user'
												}
											</Button>
										</Grid>
									</Grid>
								</Form>
							)
						}}
					</Formik>
				</FormWrapper>
			</Container>
		</Modal>
	)
}

export default NewUser;