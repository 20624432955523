import React from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getTransfers } from '../../redux/slice';

const Item = styled.div`
	width: 100%;
	height: 200px;
	box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.32);
	border-radius: 0.25rem;
	padding: 0.75rem;
	background-color: #E6E6E690;
	overflow: hidden;
	transition: transform ease-in-out 0.3s;
	cursor: pointer;
	&:hover {
		transform: scale(1.0125);
	}
`;

const card = {
	box: {
		backgroundColor: '#FFFFFF',
		height: '100%',
		padding: '0.75rem',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		gap: '1rem'
	},
	top: {
		display: 'flex',
		alignItems: 'center'
	},
	title: {
		width: '100%',
		fontWeight: 'bold',
		textAlign: 'center'
	},
	amount: {
		textAlign: 'center',
		fontSize: '2rem',
		fontWeight: '600'
	}
}

const Cards = () => {
	const dispatch = useDispatch();
	const transfers = useSelector(state => state.nebo.transfers.data);
	
	React.useEffect(() => {
		const token = JSON.parse(sessionStorage.getItem('token'));
		dispatch(getTransfers(token));
	}, []);

	const [totals, setTotals] = React.useState({ all: 0, successful: 0, pending: 0 });
	const successfulTransfers = transfers?.filter(item => item.status === "success");
	const pendingTransfers = transfers?.filter(item => item.status === "pending");

	React.useEffect(() => {
		let allTotal = 0;
		let successfulTotal = 0;
		let pendingTotal = 0;

		transfers?.forEach(item => allTotal += Number(item.amount));
		successfulTransfers?.length > 0 && successfulTransfers?.forEach(item => successfulTotal += Number(item.amount));
		pendingTransfers?.length > 0 && pendingTransfers.forEach(item => pendingTotal += Number(item.amount));
		setTotals({ all: allTotal, successful: successfulTotal, pending: pendingTotal });
	}, [transfers]);

	return (
		<Grid container spacing={4}>
				<Grid item xs={12} md={6} lg={4}>
					<Item>
						<Box sx={card.box}>
							<Box sx={card.top}>
								<Typography sx={card.title}>Total Transfer Requests</Typography>
							</Box>
							<Typography sx={card.amount}>{!transfers ? <CircularProgress /> : "₦" + totals.all.toLocaleString()}</Typography>
						</Box>
					</Item>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Item>
						<Box sx={card.box}>
							<Box sx={card.top}>
								<Typography sx={card.title}>Total Successful Transfers</Typography>
							</Box>
							<Typography sx={card.amount}>{!transfers ? <CircularProgress /> : "₦" + totals.successful.toLocaleString()}</Typography>
						</Box>
					</Item>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Item>
						<Box sx={card.box}>
							<Box sx={card.top}>
								<Typography sx={card.title}>Total Pending Transfers</Typography>
							</Box>
							<Typography sx={card.amount}>{!transfers ? <CircularProgress /> : "₦" + totals.pending.toLocaleString()}</Typography>
						</Box>
					</Item>
				</Grid>
			</Grid>
	)
}

export default Cards;