import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Title, PageTitle } from '../../../components/StyledComponents';
import { borrowers } from '../data';
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, Grid, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import { searchFunc } from '../../../components/lib';

const Container = styled.div`
	${Title} {
		font-size: 2.25rem;
		flex: 1;
	}
	a {
		text-decoration: none;
		color: inherit;
		&:hover {
			color: #F43232;
		}
	}
`;
const Table = styled.div`
	margin: 2rem 0;
	.rdt_TableHeadRow {
		background: #F43232;
		font-weight: 700;
		font-size: 1rem;
		color: #FFFFFF;
	}
`;

const List = () => {
	const [text, setText] = useState('');
	const [borrowersData, setBorrowersData] = useState([]);
	const onSearch = e => {
		let val = e.target.value;
		searchFunc(e, val, text, setText, setBorrowersData, borrowers);
	}

	const cols = [
		{
			name: 'Customer Name',
			selector: row => <Link to={`/loans/borrowers/` + row.id}>{row.name}</Link>,
			sortable: true
		},
		{
			name: 'Account Number',
			selector: row => row.account,
			sortable: true
		},
		{
			name: 'Active Loans',
			selector: row => row.loans ? row.loans.length : 0,
			sortable: true
		},
		{
			name: 'Total Loans',
			selector: row => <>{Number(row.totalAmount).toLocaleString()}</>,
			sortable: true
		},
		{
			name: 'Status',
			selector: row => row.status,
			sortable: true
		}
	];

	useEffect(() => {
		setBorrowersData(borrowers);
	}, []);

	
	
	return (
		<Container>
			<PageTitle>Loans List</PageTitle>
			<Grid container columnSpacing={4} sx={{ alignItems: "center", justifyContent: "space-between", marginY: '2rem' }}>
				<Grid item xs={12} sm={4}>
					<FormControl sx={{ width: '100%',  }} variant="outlined">
						<InputLabel htmlFor="search-input">Search</InputLabel>
						<OutlinedInput
							onChange={onSearch}
							value={text}
							endAdornment={
								<InputAdornment position="end">
									<IconButton edge="end">
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							}
							label="Search"
						/>
					</FormControl>
				</Grid>
			</Grid>
			<Table>
				<DataTable
					columns={cols}
					data={borrowersData}
					noDataComponent={<h3>Data not found.</h3>}
					progressComponent={<CircularProgress size="5rem" />}
				/>
			</Table>
		</Container>
	)
}

export default List;