import { Fragment, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { Container, Header, Loading } from '../../components/StyledComponents';
import { Box, Grid, Button, Typography, CircularProgress, Tooltip } from '@mui/material';
import axios from 'axios';
import { CancelPresentation } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const View = ({ open, close, data, token, renderDate, getInvestments }) => {
	const gridStyle = {
		container: {
			marginTop: '0rem',
			marginBottom: '2rem'
		},
		gridItem: {
			display: 'flex',
			gap: '1rem',
		},
		gridItemHeader: {
			fontWeight: 'bold',
			color: 'rgba(0, 0, 0, 0.7)'
		},
		btn: {
			height: '3.5rem'
		},
		status: {
			color: data.status === "Pending approval" ? "orange" : "green",
		},
		loading: {
			height: '20vh',
			display: 'grid',
			placeItems: 'center',
		}
	};

	console.log(data);

	console.log(data)

	const  { id } = data;

	const [loading, setLoading] = useState(false);

	const approve = async () => {
		setLoading(true);
		const data = {
			investment_id: id
		}
		const config = {
			method: 'post',
			url: 'investment/approve',
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			},
			data: data
		}
		try {
			const res = await axios(config);
			setLoading(false);
			if(res.status === 200) {
				toast.success("Investment approved successfully!");
				close();
				getInvestments();
			} else {
				toast.warn(JSON.stringify(res.data));
				close();
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
			const errorMessage = error.response.data;
    		toast.error(errorMessage);
			close();
		}
	}

	const CloseIcon = (
		<Tooltip title="Close" sx={{ color: '#F43232' }}>
			<CancelPresentation />
		</Tooltip>
	);
	
	return (
		<Modal classNames={{ modal: 'customModal' }} open={open} onClose={close} center closeIcon={CloseIcon} >
			<Container>
				<Header>Transaction summary</Header>
						<Grid container spacing={4} sx={gridStyle.container}>
							<Grid item xs={12}>
								<Typography sx={{ fontWeight: '600' }} variant="h6">Transaction Details</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>Investor Name:</Typography>
								<Typography>{data["Accout Name"]}</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>Account number:</Typography>
								<Typography>{data["Accout Number"]}</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>Offered Amount:</Typography>
								<Typography>{ Number(data.amount_offered).toLocaleString() }</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>Status:</Typography>
								<Typography sx={gridStyle.status}>{data["Investment status"]}</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>Request Date:</Typography>
								<Typography>{ renderDate(data["Request Date"]) }</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>Approval Date:</Typography>
								<Typography>{ data["Approval Date"] && renderDate(data["Approval Date"]) }</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Button onClick={approve} disabled={loading === true || data["Approval Date"] !== null ? true : false} variant="contained" fullWidth sx={gridStyle.btn}>
									{ loading === true ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : "approve" }
								</Button>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Button onClick={close} disabled={loading} variant="outlined" fullWidth sx={gridStyle.btn}>close</Button>
							</Grid>
						</Grid>
			</Container>
		</Modal>
	)
}

export default View;