import React, { useState } from 'react';
import styled from 'styled-components';
import { Card, IconWrapper, Icon, Title, FormTitle, PageTitle, TextInput, Dropdown } from '../../components/StyledComponents';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NewCustomer from './NewCustomer';
import AddLoan from './AddLoan';
import { Grid, Button, CircularProgress } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Calculator from './Calculator';
import { useDispatch, useSelector } from 'react-redux';
import { getLoans } from '../../redux/slice';
// import DataTable from 'react-data-table-component';
// import { activeLoans } from './data';

const Container = styled.div``;
const Qty = styled.div`
	display: flex;
`;
const Text = styled.p`
	font-size: ${props => props.size};
	font-weight: ${props => props.weight};
	margin: 1rem 0 0 0.5rem;
	line-height: 1rem;
`;
const Content = styled.div`
	margin: 3rem 0;
	padding: 1rem 0;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1rem;
	@media(max-width: 825px) {
		grid-template-columns: 1fr;
		grid-gap: 4rem;
	}
`;
const ItemWrapper = styled.div`
	border: 1px solid rgba(0, 0, 0, 0.1);
`;

const Main = () => {
	let token = JSON.parse(sessionStorage.getItem('token'));
	const dispatch = useDispatch();
	const loans = useSelector(state => state.nebo.loans.data);
	const totalLoans = loans?.length;
	const totalActiveLoans = loans?.filter(item => item["Loan status"] !== "Pending approval")?.length
	const totalPendingLoans = loans?.filter(item => item["Loan status"] === "Pending approval")?.length
	const [borrower, setBorrower] = useState(false);
	const [loanModal, setLoanModal] = useState(false);
	const [loan, setLoan] = useState(null);
	const [calcModal, setCalcModal] = useState(false);
	// const loanCols = [
	// 	{
	// 		name: 'Agent',
	// 		selector: row => row.agent,
	// 		sortable: true
	// 	},
	// 	{
	// 		name: 'Customer Name',
	// 		selector: row => row.customer,
	// 		sortable: true
	// 	},
	// 	{
	// 		name: 'Amount',
	// 		selector: row => Number(row.amount).toLocaleString(),
	// 		sortable: true
	// 	}
	// ]
	const init = {
		amount: '', tenure: '', period: '', rate: ''
	}
	const validate = Yup.object().shape({
		amount: Yup.number().required('Required'),
		tenure: Yup.number().required('Required'),
		period: Yup.string().required('Required'),
		rate: Yup.number().required('Required'),
	});

	const options = [
		{ name: 'Days', val: 'days' },
		{ name: 'Months', val: 'months' },
		{ name: 'Years', val: 'years' }
	];

	React.useEffect(() => {
		dispatch(getLoans(token));
	}, [token]);

	return (
		<Container>
			<PageTitle>Loans Dashboard</PageTitle>
			<Grid container spacing={4}>
				<Grid item xs={12} md={4}>
					<Card>
						<IconWrapper bg="#1890FF">
							<Icon src="/main/assets/depo.png" alt="" />
						</IconWrapper>
						<Qty>
							<Title>
								{
									!loans ? <CircularProgress /> : totalPendingLoans
								}
							</Title>
							<Text size="1.25rem">Pending <br />Loan {totalPendingLoans > 1 && "s"}</Text>
						</Qty>
					</Card>
				</Grid>
				<Grid item xs={12} md={4}>
					<Card>
						<IconWrapper bg="#47D024">
							<Icon src="/main/assets/withdraw.png" alt="" />
						</IconWrapper>
						<Qty>
							<Title>
								{
									!loans ? <CircularProgress /> : totalLoans
								}
							</Title>
							<Text size="1.25rem">Loan<br /> requests</Text>
						</Qty>
					</Card>
				</Grid>
				<Grid item xs={12} md={4}>
					<Card>
						<IconWrapper bg="#FF0000">
							<Icon src="/main/assets/transfer.png" alt="" />
						</IconWrapper>
						<Qty>
							<Title>
								{
									!loans ? <CircularProgress /> : totalActiveLoans
								}
							</Title>
							<Text size="1.25rem">Active<br />loan {totalActiveLoans > 1 && "s"}</Text>
						</Qty>
					</Card>
				</Grid>
				<Grid item xs={12} md={4}>
					<Card style={{ flexDirection: 'row' }} onClick={() => setBorrower(true)}>
						<div>
							<Text size="1.25rem" weight="700">New Customer</Text>
							<Text size="1.25rem" spacing="15px" mt="0.5rem">Add a new customer</Text>
						</div>
						<AddCircleIcon style={{ fontSize: '3rem', color: '#F43232', marginLeft: '1rem' }} />
					</Card>
				</Grid>
				<Grid item xs={12} md={4}>
					<Card onClick={() => setLoanModal(true)} style={{ flexDirection: 'row' }}>
						<div>
							<Text size="1.25rem" weight="700">New Loan</Text>
							<Text size="1.25rem" spacing="15px" mt="0.5rem">Create a new loan</Text>
						</div>
						<AddCircleIcon style={{ fontSize: '3rem', color: '#F43232', marginLeft: '1rem' }} />
					</Card>
				</Grid>
			</Grid>
			<Content>
				<ItemWrapper>
					<FormTitle>Loan Calculator</FormTitle>
					<Formik
						initialValues={init}
						validationSchema={validate}
						validateOnBlur={false}
						validateOnChange={false}
						onSubmit={ async (values) => {
							const data = {
								amount: values.amount,
								tenure: values.tenure,
								period: values.period.toLowerCase(),
								rate: values.rate
							}
							const config = {
								method: 'post',
								url: 'loan/emi_calculator',
								headers: {
									'Content-Type': 'application/json'
								},
								data: data
							}
							try {
								const res = await axios(config);
								if(res.status === 200){
									setLoan(res.data);
									setCalcModal(true);
								}
							} catch (error) {
								console.log(error);
							}
						}}
					>
						{({ values, isSubmitting }) => {
							return (
								<Form>
									<Grid container spacing={4} sx={{ padding: '1rem', marginTop: '-1rem' }}>
										<Grid item xs={12}>
											<TextInput name="amount" label="Loan Amount" />
										</Grid>
										<Grid item xs={12}>
											<TextInput name="tenure" label="Loan Tenure" />
										</Grid>
										<Grid item xs={12}>
											<Dropdown name="period" options={options} label="Loan Period" />
										</Grid>
										<Grid item xs={12}>
											<TextInput name="rate" label="Charge Rate" />
										</Grid>
										<Grid item xs={12}>
											<Button onClick={() => localStorage.setItem('calc', JSON.stringify(values))} disabled={isSubmitting} sx={{ height: '3.625rem' }} type="submit" variant="contained" fullWidth>
												{
													isSubmitting === true ? <CircularProgress sx={{ color: '#FFFFFF'}} /> : 'Calculate'
												}
											</Button>
										</Grid>
									</Grid>
								</Form>
							)
						}}
					</Formik>
				</ItemWrapper>
				{/* <ItemWrapper>
					<FormTitle>Active Loan Requests</FormTitle>
					<DataTable columns={loanCols} data={activeLoans} />
				</ItemWrapper> */}
			</Content>
			<React.Fragment>
				{
					borrower === true &&
					<NewCustomer open={borrower} close={() => setBorrower(false)} />
				}
				{
					loanModal === true &&
					<AddLoan open={loanModal} close={() => setLoanModal(false)} />
				}
				{
					calcModal === true &&
					<Calculator open={calcModal} close={() => setCalcModal(false)} data={loan} />
				}
			</React.Fragment>
		</Container>
	)
}

export default Main;