import React from 'react';
import { Modal } from 'react-responsive-modal';
import styled from 'styled-components';
import { Container, Header, TextInput } from '../../../components/StyledComponents';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { CircularProgress, Grid, Typography, Button, Box } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const FormWrapper = styled.div`
	margin: 2rem 0;
	padding-bottom: 1.5rem;
	.mainForm {
		display: grid;
		grid-template-columns: 1fr 1fr;
		@media(max-width: 625px) {
			grid-template-columns: 1fr;
		}
	}
	.image-upload {
		border: 1px solid #C4C4C4;
		padding: 0 0.5rem;
		height: 2.5rem;
		margin-top: 1.5rem;
		label {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;
		}
		.img-input {
			display: none;
		}
	}
`;

const EditRate = ({ open, close, customer, closeParent }) => {
	const INIT = {
		newRate: customer.rate
	}

	const VALIDATE = Yup.object().shape({
		newRate: Yup.number().typeError("This field must be a non-zero number").required("Required")
	});

	let token = sessionStorage.getItem('token');


	return (
		<Modal classNames={{ modal: 'customModal' }} open={open} onClose={close} center>
			<Container>
				<Header>Edit Loan Rate - {customer.account_name}</Header>
				<FormWrapper>
					<Formik
						initialValues={INIT}
						validationSchema={VALIDATE}
						validateOnBlur={false}
						validateOnChange={false}
						onSubmit={async (values) => {
							const data = {
								loan_id: customer.id,
								rate: values.newRate
							}

							const config = {
								method: 'post',
								url: 'loan/update/rate',
								headers: {
									'Authorization': 'Bearer ' + JSON.parse(token)
								},
								data: data
							}

							try {
								const res = await axios(config);
								if(res.status === 200) {
									toast.success("Loan rate updated successfully!");
									close();
									closeParent();
								} else {
									toast.warn(res.data.message);
								}
							} catch (error) {
								console.log(error);
								toast.error(error.response.data);
								close();
							}
						}}
					>
						{({ isSubmitting }) => {
							return (
								<Form>
									<Grid container spacing={4} alignItems="center">
										<Grid item xs={12}>
											<Typography variant="button">Customer information</Typography>
										</Grid>
										{/* <Grid item xs={12}>
											<TextInput name="loanId" label="Account Number" disabled />
										</Grid> */}
										<Grid item xs={12}>
											<TextInput name="newRate" label="New Loan Rate" />
										</Grid>
										<Grid item xs={12}>
											<Box sx={{ display: 'flex', gap: '1rem' }}>
												<Button
													variant="contained" type="submit" fullWidth
													sx={{ height: '3.5rem' }}
												>
													{
														isSubmitting === true ?
														<CircularProgress sx={{ color: '#FFFFFF' }} /> :
														'update'
													}
												</Button>
												<Button
													variant="outlined" fullWidth
													onClick={close}
													sx={{ height: '3.5rem' }}
												>
													close
												</Button>
											</Box>
										</Grid>
									</Grid>
								</Form>
							)
						}}
					</Formik>
				</FormWrapper>
			</Container>
		</Modal>
	)
}

export default EditRate;