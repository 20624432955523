import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import AppBar from './AppBar';
import Drawer from './Drawer';
import DrawerHeader from './Drawer/DrawerHeader';

class Layout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: true,
			currentUser: null
		};
	}
	handleDrawerClose = () => {
		this.setState({ ...this.state, open: false });
	}
	handleDrawerOpen = () => {
		this.setState({ ...this.state, open: true });
	}
	componentDidMount() {
		let user = sessionStorage.getItem('user');
		if (user) {
			user = JSON.parse(user);
			this.setState({ ...this.state, currentUser: user });
		}
	}

	render() {
		const { open, currentUser } = this.state;
		return (
			<Box sx={{ display: 'flex' }}>
				<CssBaseline />
				<AppBar open={open} user={currentUser} handleDrawerOpen={this.handleDrawerOpen} />
				<Drawer open={open} handleDrawerClose={this.handleDrawerClose} user={currentUser} />
				<Box component="main"
					sx={{
						p: 3, flexGrow: 1,
						width: 'calc(100% - 200px)',
					}}
				>
					<DrawerHeader />
					{ this.props.children }
				</Box>
			</Box>
		)
	}
}

export default Layout;