import React from 'react';
import { Modal } from 'react-responsive-modal';
import { Container, Header } from '../../components/StyledComponents';
import { Grid, Box, Typography, Button, CircularProgress } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextInput, Check } from '../../components/StyledComponents';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import axios from 'axios';

const FormWrapper = styled.div`
	margin: 2rem 0;
	padding-bottom: 1.5rem;
`;

const Withdrawal = ({ open, close }) => {
	const INIT = {
		accountNumber: '',
		cardNumber: '',
		amount: '',
		approvalRequired: false
	}
	const VALIDATE = Yup.object().shape({
		accountNumber: Yup.number().required('Required'),
		cardNumber: Yup.number().required('Required'),
		amount: Yup.number().required('Required'),
		approvalRequired: Yup.boolean().oneOf[true, false]
	});

	const token = sessionStorage.getItem('token');
	
	const [name, setName] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	
	const validate = async (acct, card) => {
		setLoading(true);
		const data = {
			account_number: acct,
			card_number: card
		}
		const config = {
			method: 'post',
			url: 'transaction/balance',
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			},
			data: data
		}
		try {
			const res = await axios(config);
			if(res.status === 200) {
				setLoading(false);
				setName(res.data.result);
			} else {
				toast.warn(res.data.message);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
			toast.error(error.response.data);
		}
	}

	return (
		<Modal classNames={{ modal: 'customModal' }} open={open} onClose={close} center>
			<Container>
				<Header>Withdrawal</Header>
				<FormWrapper>
					<Formik
						initialValues={INIT}
						validationSchema={VALIDATE}
						onSubmit={ async (values) => {
							const data = {
								account_number: values.accountNumber,
								card_number: values.cardNumber,
								amount: values.amount
							}
							const config = {
								method: 'post',
								url: 'transaction/withdraw',
								headers: {
									'Authorization': 'Bearer ' + JSON.parse(token)
								},
								data: data
							}
								try {
									const res = await axios(config);
									close();
									if(res.status === 200) {
										toast.success("Withdrawal logged Successfully!");
									} else {
										toast.warn(res.data.message);
									}
								} catch (error) {
									console.log(error);
									toast.error(error.message);
									close();
								}
						}}
					>
						{({ values, isSubmitting }) => {
							let { accountNumber, cardNumber } = values;
							return (
								<Form>
									<Grid container columnSpacing={3} rowSpacing={4} alignItems="center">
										<Grid item xs={12}>
											<Typography variant="button">Transaction Details</Typography>
										</Grid>
										<Grid item xs={12}>
											<Box sx={{ display: 'flex', gap: '0.5rem' }}>
												<TextInput name="accountNumber" label="Account Number" />
												<Button disabled={loading} onClick={() => validate(accountNumber, cardNumber)} type="button" variant='contained' sx={{ width: '10rem', height: '3.625rem' }}>
													{
														loading === true ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : 'Validate'
													}
												</Button>
											</Box>
											<React.Fragment>
												{
													name &&
													<Typography sx={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, 0.54)', marginLeft: '0.75rem' }}>{name.account_name}, balance: {Number(name.balance).toLocaleString()}</Typography>
												}
											</React.Fragment>
										</Grid>
										<Grid item xs={12}>
											<TextInput name="cardNumber" label="Card Number" />
										</Grid>
										<Grid item xs={12}>
											<TextInput name="amount" label="Amount" />
										</Grid>
										<Grid item xs={12}>
											<Check name="approvalRequired" label="Above N10,000" />
										</Grid>
										<Grid item xs={12}>
											<Button disabled={isSubmitting || (Number(values.amount) > 10000 && !values.approvalRequired)} type="submit" variant="contained" fullWidth>
												{ isSubmitting === true ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : 'withdraw' }
											</Button>
										</Grid>
									</Grid>
								</Form>
							)
						}}
					</Formik>
				</FormWrapper>
			</Container>
		</Modal>
	)
}

export default Withdrawal;