export const activeLoans = [
	{
		id: 1,
		agent: 'Agent 47',
		customer: 'Rowa Mitcher',
		amount: 11000
	},
	{
		id: 2,
		agent: 'Agent 47',
		customer: 'Jamie Carr',
		amount: 120000
	},
	{
		id: 3,
		agent: 'John Doe',
		customer: 'Koa Andrews',
		amount: 21000
	},
	{
		id: 4,
		agent: 'John Doe',
		customer: 'Rowa Mitcher',
		amount: 11000
	},
	{
		id: 5,
		agent: 'John Doe',
		customer: 'Rowa Mitcher',
		amount: 11000
	}
];

export const borrowers = [
	{
		id: 1,
		name: 'Rowa Mitcher',
		account: '7123400783',
		loanCount: 4,
		totalAmount: 559364,
		status: 'Active',
		loans: [
			{
				id: 392044,
				loanAmount: 20000,
				loanType: 'Housing',
				status: 'active',
				date: '2021/12/22',
				period: '3 months',
				paymentPlan: 'monthly'
			},
			{
				id: 382954,
				loanAmount: 13000,
				loanType: 'Education',
				status: 'completed',
				date: '2021/9/22',
				period: '1 month',
				paymentPlan: 'weekly'
			}
		]
	},
	{
		id: 2,
		name: 'Benson Quiver',
		account: '7123456783',
		loanCount: 14,
		totalAmount: 129364,
		status: 'Active',
		loans: [
			{
				id: 934940,
				loanAmount: 20000,
				loanType: 'Housing',
				status: 'active',
				date: '2021/12/22',
				period: '3 months',
				paymentPlan: 'monthly'
			},
			{
				id: 392044,
				loanAmount: 20000,
				loanType: 'Housing',
				status: 'active',
				date: '2021/12/22',
				period: '3 months',
				paymentPlan: 'monthly'
			}
		]
	},
	{
		id: 3,
		name: 'James Moore',
		account: '3789234628',
		loanCount: 6,
		totalAmount: 489573,
		status: 'Active',
		loans: [
			{
				id: 934940,
				loanAmount: 400000,
				loanType: 'Housing',
				status: 'active',
				date: '2021/12/22',
				period: '8 months',
				paymentPlan: 'monthly'
			},
			{
				id: 392044,
				loanAmount: 120000,
				loanType: 'Housing',
				status: 'active',
				date: '2021/12/22',
				period: '3 months',
				paymentPlan: 'monthly'
			}
		]
	},
	{
		id: 4,
		name: 'Ikei Solander',
		account: '8934721345',
		loanCount: 9,
		totalAmount: 4784723,
		status: 'Active'
	},
	{
		id: 5,
		name: 'Henry Bossman',
		account: '4833874123',
		loanCount: 2,
		totalAmount: 493092,
		status: 'Active',
		loans: [
			{
				id: 934940,
				loanAmount: 20000,
				loanType: 'Housing',
				status: 'completed',
				date: '2021/12/22',
				period: '3 months',
				paymentPlan: 'monthly'
			},
			{
				id: 392044,
				loanAmount: 20000,
				loanType: 'Housing',
				status: 'completed',
				date: '2021/12/22',
				period: '3 months',
				paymentPlan: 'monthly'
			}
		]
	}
];

export const collections = [
	{
		id: 1,
		agent: 'Agent 47',
		customer: 'Rowa Mitcher',
		amount: 11000,
		loanType: 'Housing',
		customerAccountNumber: 378437426637,
		status: 'Paid',
		date: '2021/12/22',
	},
	{
		id: 2,
		agent: 'Agent 49',
		customer: 'John Sallyman',
		amount: 11000,
		loanType: 'Loan against salary',
		customerAccountNumber: 3783252533434,
		status: 'Active',
		date: '2021/11/22',
	},
	{
		id: 3,
		agent: 'Agent 47',
		customer: 'Adrian Johnson',
		amount: 20000,
		loanType: 'Housing',
		customerAccountNumber: 472235362632,
		status: 'Active',
		date: '2021/12/24',
	},
	{
		id: 4,
		agent: 'Agent 42',
		customer: 'Raymond Red',
		amount: 8000,
		loanType: 'Car Loan',
		customerAccountNumber: 347235365252,
		status: 'Active',
		date: '2021/10/29',
	}
]
