import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Title, PageTitle, Loading } from '../../components/StyledComponents';
import { Search, Visibility } from '@mui/icons-material';
import { Button, Box, Tooltip, FormControl, InputLabel, OutlinedInput, IconButton, InputAdornment } from '@mui/material';
import axios from 'axios';
import View from './View';
import { CSVLink } from 'react-csv';


const Container = styled.div`
	${Title} {
		font-size: 2.25rem;
		flex: 1;
	}
	a {
		text-decoration: none;
		color: inherit;
		&:hover {
			color: #F43232;
		}
	}
	.top-box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;
		margin: 2rem 0;
		@media (max-width: 768px) {
			flex-direction: column;
			align-items: flex-end;
		}
	}
	.top-box-search {
		width: 100%;
		max-width: 400px;
		@media (max-width: 768px) {
			max-width: 100%;
		}
	}
`;
const Table = styled.div`
	margin: 2rem 0;
`;

const Investors = () => {
	const token = sessionStorage.getItem('token');
	const [text, setText] = useState('');
	const [investors, setInvestors] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [open, setOpen] = useState(false);
	const [investor, setInvestor] = useState(null);
	const handleChange = e => {
		let val = e.target.value;
		setText(val);
	}
	const renderDate = (date) => {
		let newdate = new Date(date);
		return newdate.toLocaleDateString("en-GB") + " " + newdate.toLocaleTimeString("en-GB");
	}

	const view = val => {
		setInvestor(val);
		setOpen(true);
	}

	const cols = [
		{
			name: 'Customer Name',
			selector: row => <Link to={`/investments/investor/` + row.customer_id}>{row["Accout Name"]}</Link>,
			sortable: true
		},
		{
			name: 'Account Number',
			selector: row => row["Accout Number"],
			sortable: true
		},
		{
			name: 'Transaction Date',
			selector: row => renderDate(row["Request Date"]),
			sortable: true,
			minWidth: '11rem'
		},
		{
			name: 'Status',
			selector: row => <span style={{ color: row["Investment status"] === "Pending approval" ? "orange" : (row["Investment status"] === "Cancelled" || row["Investment status"] === "Declined" ? "red" : "green") }}>
				{ row["Investment status"] }
			</span>,
			sortable: true
		},
		{
			name: 'Action',
			cell: row => 
							<Tooltip title="View item" placement="left">
								<Visibility
									sx={{ cursor: 'pointer', color: 'rgba(0, 0, 0, 0.54)' }}
									onClick={() => view(row)}
								/>
							</Tooltip>
		}
	];

	const getInvestments = async () => {
		setLoading(true);
		const config = {
			method: 'get',
			url: 'investment/all',
			headers: { 
				'Authorization': 'Bearer ' + JSON.parse(token),
			}
		}

		try {
			const response = await axios(config);
			if(response.status === 200) {
				setLoading(false);
				let data = response.data.Investments;
				data = data.sort((a, b) => new Date(a.date_requested) > new Date(b.date_requested) ? -1 : 1);
				setInvestors(data);
			}		
		} catch (error) {
			setLoading(false);
			setError(error);
			console.log(error);
		}
	}

	useEffect(() => {
		getInvestments();
	}, []);
	
	return (
		<Container>
			<PageTitle>Investors</PageTitle>
			<Box className="top-box">
				<Box className="top-box-search">
					<FormControl fullWidth variant="outlined">
						<InputLabel htmlFor="search-input">Search</InputLabel>
						<OutlinedInput
							id="search-input"
							onChange={handleChange}
							value={text}
							endAdornment={
								<InputAdornment position="end">
									<IconButton edge="end">
										<Search />
									</IconButton>
								</InputAdornment>
							}
							label="Search"
						/>
					</FormControl>
				</Box>		
				<Button variant="outlined" sx={{ height: '3.625rem', paddingX: '2rem' }}>
					{
						investors &&
						<CSVLink data={investors} filename="investors.csv">
							download
						</CSVLink>
					}
				</Button>
			</Box>
			<React.Fragment>
				{
					loading === true ?
					<Box sx={{ height: '40vh', display: 'grid', placeItems: 'center' }}>
						<Loading text="Loading investors" />
					</Box> :
					(
						error ? JSON.stringify(error.message) :
						investors &&
						<Table>
							<DataTable
								columns={cols}
								data={
									investors.filter(item => {
										if(text.trim() === "") {
											return item;
										} else if(item["Accout Name"].toLowerCase().includes(text.toLowerCase())) {
											return item;
										} else if(item["Accout Number"].toLowerCase().includes(text.toLowerCase())) {
											return item;
										}
									})
								}
								pagination paginationPerPage={15}
							/>
						</Table>
					)
				}
			</React.Fragment>
			<React.Fragment>
				{
					open === true &&
					<View 
						open={open}
						close={() => setOpen(false)}
						getInvestments={getInvestments}
						data={investor}
						token={token}
						renderDate={renderDate}
					/>
				}
			</React.Fragment>
		</Container>
	)
}

export default Investors;