import React from 'react';
import { Modal } from 'react-responsive-modal';
import { Button} from '@mui/material';
import { Header, Container } from '../../components/StyledComponents';
import EditUser from './EditUser';
import styled from 'styled-components';

const HeaderBox = styled.div`
  border: 2px solid #F4323290;
  margin: 2rem 0;
  padding: 1rem;
  display: flex;
`;
const Picture = styled.div`
    width: 100%;
    max-width: 11.5rem;
    height: 12rem;
    background: #C4C4C4;
    display: flex;
    justify-content: center;
    align-items: center;
  overflow: hidden;
    img {
        max-width: 100%;
        max-height: 100%;
    object-fit: cover;
    }
`;
const Info = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  width: 100%;
`;
const InfoItem = styled.div`
  padding: 0 1rem;
`;
const Item = styled.div`
  margin-bottom: 0.75rem;
  .name {
    font-weight: bold;
  }
`;
const Text = styled.p`
  font-weight: ${props => props.weight};
  font-size: 1.25rem;
`;

const View = ({ open, close, data, onUserUpdate }) => {
  const [editModal, setEditModal] = React.useState(false);

  return (
    <Modal classNames={{ modal: 'auditModal' }} open={open} onClose={close} center>
      <Container>
        <Header>User Information</Header>
        <HeaderBox>
          <Picture>
            <img src={data.profile_pic} alt="user" />
          </Picture>
          <Info>
            <InfoItem>
              <Item>
                <Text className='name'>{data.Name}</Text>
              </Item>
              <Item>
                <Text>User ID: {data.id}</Text>
              </Item>
              <Item>
                <Text>Designation: {data["User Role"]}</Text>
              </Item>
              <Item>
                <Text>Phone: {data.phone_no}</Text>
              </Item>
              <Item>
                <Text>Email: {data.personal_email}</Text>
              </Item>
              <Item>
                <Text>Address: {data.address}</Text>
              </Item>
            </InfoItem>
            <InfoItem>
              <Item><Text className='name'>Guarantor</Text></Item>
              <Item><Text>Name: {data.guarantor_name}</Text></Item>
              <Item><Text>Phone: {data.guarantor_phone}</Text></Item>
              <Item><Text>Address: {data.guarantor_address}</Text></Item>
            </InfoItem>
            <InfoItem>
              <Item><Text className='name'>Next of kin</Text></Item>
              <Item><Text>Name: {data.next_of_kin_name}</Text></Item>
              <Item><Text>Relationship: {data.kin_relationship}</Text></Item>
              <Item><Text>Phone: {data.kin_phone}</Text></Item>
              <Item><Text>Address: {data.kin_address}</Text></Item>
            </InfoItem>
          </Info>
        </HeaderBox>
      </Container>
      <Button variant="outlined" onClick={() => setEditModal(true)}>
        Edit User
      </Button>
      <React.Fragment>
        {
          editModal === true &&
          <EditUser
            open={editModal}
            close={() => {setEditModal(false)}}
            closeParent={close}
            data={data}
            onUserUpdate={onUserUpdate}
          />
        }
      </React.Fragment>
    </Modal>
  )
}

export default View;