import React, { useState, useEffect, Fragment } from 'react';
import { Loading, PageTitle } from '../../components/StyledComponents';
import DataTable from 'react-data-table-component';
import { Visibility } from '@mui/icons-material';
import { Grid, Select, MenuItem, FormControl, InputLabel, Box, Typography } from '@mui/material';
import View from './View';
import axios from 'axios';

const Withdrawal = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [tempData, setTempData] = useState(null);
	const [withdrawals, setWithdrawals] = useState(null);
	const [withdrawal, setWithdrawal] = useState({});
	const [viewModal, setViewModal] = useState(false);
	const [filter, setFilter] = useState('');
	const token = sessionStorage.getItem('token');

	const openViewModal = item => {
		setWithdrawal(item);
		setViewModal(true);
	}

	const renderColor = val => {
		if(val.status.toLowerCase() === "pending") {
			return 'orange'
		} else {
			return "green"
		}
	}

	const getWithdrawals = async () => {
		setLoading(true);
		const configI = {
			method: 'get',
			url: "transaction/pending",
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			}
		}
		const configII = {
			method: 'get',
			url: "transaction/approved",
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			}
		}

		try {
			const resI = await axios(configI);
			const resII = await axios(configII);
			
			setLoading(false);
			if(resI.status === 200 && resII.status === 200) {
				let dataI = resI.data;
				let dataII = resII.data;
				let data = [...dataI, ...dataII];
				data = data.sort((a, b) => new Date(a.date_created) > new Date(b.date_created) ? -1 : 1).filter(item => item.type_id === 4);
				setTempData(data);
				setWithdrawals(data);
			}
		} catch (error) {
			setError(error);
			setLoading(false);
		}
	}

	const renderDate = (date) => {
		let newdate = new Date(date);
		return newdate.toLocaleDateString("en-GB") + " " + newdate.toLocaleTimeString("en-GB");
	}

	const cols = [
		{
			name: 'Transaction Date',
			selector: row => renderDate(row.date_created),
			sortable: true,
			minWidth: '11rem',
		},
		{
			name: 'Account Name',
			selector: row => row.account_name,
			sortable: true,
			minWidth: '11rem',
		},
		{
			name: 'Account No.',
			selector: row => row.account_number,
			sortable: true,
			minWidth: '9rem',
		},
		{
			name: 'Amount',
			selector: row => <>N {Number(row.amount).toLocaleString()}</>,
			sortable: true, 
		},
		{
			name: 'Status',
			selector: row =>
				<Box
					sx={{
							// fontSize: '13px',/
							color: renderColor(row),
							textAlign: 'center',
							padding: '0.5rem',
							display: 'flex',
							alignItems: 'center',
							gap: '0.25rem'
						}}
				>
					<Box sx={{
						height: '0.75rem',
						width: '0.75rem',
						borderRadius: '50%',
						backgroundColor: renderColor(row),
					}} />
					<Box>{row.status}</Box>
				</Box>,
			sortable: true,
		},
		{
			name: 'Completion Date',
			selector: row => row.status === "pending" ? null : renderDate(row.last_updated),
			sortable: true,
			minWidth: '11rem',
		},
		{
			name: 'Action',
			selector: row => 
				<Visibility 
					onClick={() => openViewModal(row)}
					sx={{ cursor: 'pointer', color: 'rgba(0, 0, 0, 0.54)' }}
				/>
		}
	];

	useEffect(() => {
		getWithdrawals();
		// eslint-disable-next-line
	}, []);

	const handleFilter = (e) => {
		const { value } = e.target;
		setFilter(value);
		if(value.trim() === '') {
			setWithdrawals(tempData);
		} else  {
			const items = tempData.filter(item => item.status === value);
			setWithdrawals(items);
		}
	}
	
	return (
		<Box>
			<PageTitle>Withdrawals</PageTitle>
			<Grid container columnSpacing={4} rowSpacing={4} sx={{ alignItems: "center", justifyContent: "space-between", marginBottom: '2rem' }}>
				<Grid item xs={12}>
					<FormControl sx={{ width: '100%', maxWidth: '400px' }}>
						<InputLabel>Filter Transaction</InputLabel>
						<Select value={filter} onChange={handleFilter} label="Filter Transaction">
							<MenuItem value=''>Select</MenuItem>
							<MenuItem value='pending'>Pending</MenuItem>
							<MenuItem value='success'>Successful</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			<Fragment>
				{
					loading === true ? <Loading text="Please wait" /> :
					(
						error ? <Typography sx={{ textAlign: 'center' }} color="error">{error.message}</Typography> :
						withdrawals !== null &&
						<DataTable
							columns={cols}
							data={withdrawals}
							pagination paginationPerPage={20}
						/>
					)
				}
			</Fragment>
			<Fragment>
				{
					viewModal === true &&
					<View
						open={viewModal}
						close={() => {
							setViewModal(false);
							setWithdrawal({});
						}}
						token={token}
						data={withdrawal}
						renderColor={renderColor}
						renderDate={renderDate}
						getWithdrawals={getWithdrawals}
					/>
				}
			</Fragment>
		</Box>
	)
}

export default Withdrawal;