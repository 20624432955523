import { Modal } from 'react-responsive-modal';
import { Container, Header } from '../../components/StyledComponents';
import { Grid, Button, Typography, TextField, Box } from '@mui/material';
import { Fragment } from 'react';

const gridStyle = {
	container: {
		marginTop: '0rem',
		marginBottom: '2rem'
	},
	gridItem: {
		display: 'flex',
		gap: '1rem',
	},
	gridItemHeader: {
		fontWeight: 'bold',
		color: 'rgba(0, 0, 0, 0.7)'
	},
	btn: {
		height: '3.5rem'
	},
	status: {
		color: 'rgba(0, 0, 0, 0.54)',
	}
};

const AuditCustomerView = ({ open, close, data }) => {
	
	return (
		<Modal classNames={{ modal: 'customModal' }} open={open} onClose={close} center>
			<Container>
				<Header>Transaction summary</Header>
				<Fragment>
					{
						data.transactionType !== "transfer" &&
						<Grid container spacing={4} sx={gridStyle.container}>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>
									Customer Name:
								</Typography>
								<Typography>
									{data.account_name}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>
								Account Number:
								</Typography>
								<Typography>
									{data.account_number}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>
								Amount:
								</Typography>
								<Typography>
									N {Number(data.amount).toLocaleString()}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>
								Transaction type:
								</Typography>
								<Typography sx={{ textTransform: 'capitalize' }}>
									{data["Transaction Type"]}
								</Typography>
							</Grid>
							<Grid item xs={12} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>
								Transaction Date:
								</Typography>
								<Typography>
									{new Date(data["Transaction date"]).toUTCString()}
								</Typography>
							</Grid>
						</Grid>
					}
				</Fragment>
				<Grid container spacing={4} sx={gridStyle.container}>
					<Grid item xs={12} sx={{ marginY: '1rem' }}>
						<Box component="form">
							<TextField label="Add comment" multiline rows={4} fullWidth />
						</Box>
					</Grid>
					<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
						<Button variant="contained" fullWidth sx={gridStyle.btn}>submit</Button>
					</Grid>
					<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
						<Button variant="outlined" fullWidth sx={gridStyle.btn} onClick={close}>Cancel</Button>
					</Grid>
				</Grid>
			</Container>
		</Modal>
	)
}

export default AuditCustomerView;