import React from 'react';
import styled from 'styled-components';
import { PageTitle } from '../../components/StyledComponents';
import Cards from './Cards';

const Container = styled.div`
	.rdt_TableHeadRow {
		background: #F43232;
		font-weight: 700;
		font-size: 1rem;
		color: #FFFFFF;
	}
`;

const TransferIndex = () => {
	return (
		<Container>
			<PageTitle>Transfer Summary</PageTitle>
			<Cards />
		</Container>
	)
}

export default TransferIndex;