import React from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { ArrowDropUp } from '@mui/icons-material';
import styled from 'styled-components';
import { PageTitle } from '../../components/StyledComponents';
import { getLoans, getReports } from '../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

const Container = styled.div``;
const Item = styled.div`
	width: 100%;
	max-width: 460px;
	height: 225px;
	box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.32);
	border-radius: 0.125rem;
	padding: 0.75rem;
	background-color: #E6E6E690;
	cursor: pointer;
`;
const Image = styled.img``;

const cardStyle = {
	box: {
		backgroundColor: '#FFFFFF',
		height: '100%',
		padding: '0.5rem',
		display: 'flex',
		flexDirection: 'column',
		// justifyContent: 'center'
	},
	titleSmall: {
		color: 'rgba(0, 0, 0, 0.45)',
		fontSize: '1.25rem',
	},
	titleLarge: {
		fontWeight: 700,
		fontSize: '2.5rem',
		color: 'rgba(0, 0, 0, 0.85)'
	},
	titleBox: {
		display: 'flex',
		alignItems: 'center'
	},
	subTitle: {
		fontSize: '1.25rem',
	},
	icon: {
		color: '#52C41A',
		fontSize: '2.5rem',
	},
	footer: {
		marginTop: '0.5rem',
		fontSize: '0.875rem'
	}
}

export const Cards = () => {
	const dispatch = useDispatch();
	let token = JSON.parse(sessionStorage.getItem('token'));
	const [users, setUsers] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState(null);
	const { report, loans } = useSelector(state => state.nebo);

	const getUsers = async () => {
		setLoading(true);
		const config = {
			method: 'get',
			url: "list/users",
			headers: {
				'Authorization': 'Bearer ' + token
			}
		}
	
		try {
			const res = await axios(config);
			setLoading(false);
			if(res.status === 200) {
				let { data } = res;
				data = data.sort((a, b) => a.Name.localeCompare(b.Name));
				setUsers(data);
			}
		} catch (error) {
				console.log(error);
				setError(error);
				setLoading(false);
		}
	}

	React.useEffect(() => {
		getUsers();
		dispatch(getLoans(token));
		dispatch(getReports(token));
	}, []);

	return (
		<Grid container spacing={4}>
				<Grid item xs={12} sm={6} md={4} lg={3}>
					<Item>
						<Box sx={cardStyle.box}>
							<Typography sx={cardStyle.titleSmall} variant='button'>Contributors</Typography>
							<Typography sx={cardStyle.titleLarge}>{ loading ? <CircularProgress /> : ((users && !loading) && users.length) }</Typography>
							<Box sx={cardStyle.titleBox}>
								<Typography sx={cardStyle.subTitle}>Active Users</Typography>
								<ArrowDropUp sx={cardStyle.icon} />
							</Box>
						</Box>
					</Item>
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={3}>
					<Item>
						<Box sx={cardStyle.box}>
							<Typography sx={cardStyle.titleSmall} variant='button'>transactions</Typography>
							<Typography sx={cardStyle.titleLarge}>{ !report.data ? <CircularProgress /> : report?.data?.length }</Typography>
							<Image src="/main/assets/trx-chart.svg" alt="trx" />
							<Typography sx={cardStyle.footer}>Daily visits: 1,234</Typography>
						</Box>
					</Item>
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={3}>
					<Item>
						<Box sx={cardStyle.box}>
							<Typography sx={cardStyle.titleSmall} variant='button'>active loans</Typography>
							<Typography sx={cardStyle.titleLarge}>{ !loans.data ? <CircularProgress /> : loans?.data?.length }</Typography>
							<Image src="/main/assets/loans-chart.svg" alt="loans" />
							<Typography sx={cardStyle.footer}>Conversion Rate: 60%</Typography>
						</Box>
					</Item>
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={3}>
					<Item>
						<Box sx={cardStyle.box}>
							<Typography sx={cardStyle.titleSmall} variant='button'>Marketers</Typography>
							<Typography sx={cardStyle.titleLarge}>
								{
									loading ? <CircularProgress /> : users && users?.filter(item => item["User Role"] === "Field Agent")?.length
								}
							</Typography>
							<Image src="/main/assets/loans-chart.svg" alt="marketers" />
							<Typography sx={cardStyle.footer}>Conversion Rate: 60%</Typography>
						</Box>
					</Item>
				</Grid>
			</Grid>
	)
}

const Audit = () => {
	return (
		<Container>
			<PageTitle>Auditing</PageTitle>
			<Cards />
		</Container>		
	)
}

export default Audit;