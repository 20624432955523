import { Modal } from 'react-responsive-modal';
import { Container, Header } from '../../components/StyledComponents';
import { Grid, Typography, Button } from '@mui/material';

const Calculator = ({ open, close, data }) => {
	let calc = JSON.parse(localStorage.getItem('calc'));
	const boxStyle = {
		topSpace: {
			marginY: '1rem'
		},
		flex: {
			display: 'flex',
			alignItems: 'center',
			gap: '1rem'
		},
		title: {
			fontWeight: 'bold',
			color: 'rgba(0, 0, 0, 0.54)'
		}
	}
	return (
		<Modal classNames={{ modal: 'customModal' }} open={open} onClose={close} center>
			<Container>
				<Header>Loan Calculator</Header>
				<Grid container spacing={3} sx={boxStyle.topSpace}>
					<Grid item xs={12} sx={boxStyle.flex}>
						<Typography sx={boxStyle.title} variant="h6">Loan Amount:</Typography>
						<Typography variant="h6">{Number(calc.amount).toLocaleString()}</Typography>
					</Grid>
					<Grid item xs={12} sx={boxStyle.flex}>
						<Typography sx={boxStyle.title} variant="h6">Loan Tenure:</Typography>
						<Typography variant="h6">{ data.tenure + " " + data.period}</Typography>
					</Grid>
					<Grid item xs={12} sx={boxStyle.flex}>
						<Typography sx={boxStyle.title} variant="h6">Charge Rate:</Typography>
						<Typography variant="h6">{ calc.rate }%</Typography>
					</Grid>
					<Grid item xs={12} sx={boxStyle.flex}>
						<Typography sx={boxStyle.title} variant="h6">Total Repayment Amount:</Typography>
						<Typography variant="h6">{Number(data.total_repayment).toLocaleString()}</Typography>
					</Grid>
					<Grid item xs={12} sx={boxStyle.flex}>
						<Typography sx={boxStyle.title} variant="h6">Repayment Amount Per Interval:</Typography>
						<Typography variant="h6">{Number(data.EPI).toLocaleString()}</Typography>
					</Grid>
					<Grid item xs={12} sx={boxStyle.flex}>
						<Button onClick={() => { localStorage.removeItem('calc'); close() }} fullWidth variant="contained" sx={{ height: '3.625rem' }}>close</Button>
					</Grid>
				</Grid>
			</Container>
		</Modal>
	)
}

export default Calculator;