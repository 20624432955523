import React, { Fragment } from 'react';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import AuditCustomerView from '../View';
import axios from 'axios';

const Container = styled.div`
	margin: 2rem 0;
`;

const iconStyle = {
	cursor: 'pointer',
	color: 'rgba(0, 0, 0, 0.54)'
}

const Table = ({ data }) => {
	const token = sessionStorage.getItem('token');
	const [details, setDetails] = React.useState({});
	const [detailsModal, setDetailsModal] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [report, setReport] = React.useState(null);
	const [error, setError] = React.useState(null);

	const view = item => {
		setDetails(item);
		setDetailsModal(true);
	};

	const cols = [
		{
			name: 'Customer name',
			selector: row => row.account_name,
			sortable: true
		},
		{
			name: 'Account Number',
			selector: row => row.account_number,
			sortable: true
		},
		{
			name: 'Amount',
			selector: row => <>N {Number(row.amount).toLocaleString()}</>
		},
		{
			name: 'Timestamp',
			selector: row => new Date(row["Transaction date"]).toLocaleDateString("en-GB") + new Date(row["Transaction date"]).toLocaleTimeString("en-GB"),
			sortable: true,
			minWidth: '11rem'
		},
		{
			name: 'Status',
			selector: row => <span style={{ color: 'green', fontWeight: '700' }}>Active</span>
		},
		{
			name: 'Transaction Type',
			selector: row => row["Transaction Type"],
			sortable: true,
		},
		{
			name: 'User ID',
			selector: row => row.initiator_id
		},
		{
			name: 'Action',
			selector: row => <Visibility sx={iconStyle} onClick={() => view(row)} />
		}
	];

	const getTransactions = async () => {
		setLoading(true);
		const config = {
			method: 'get',
			url: 'list/transactions',
			headers: { 
				'Authorization': 'Bearer ' + JSON.parse(token),
			}
		}

		try {
			const response = await axios(config);
			if(response.status === 200) {
				setLoading(false);
				let data = response.data;
				data = data.sort((a, b) => new Date(a["Transaction date"]) > new Date(b["Transaction date"]) ? -1 : 1);
				setReport(data);
			}		
		} catch (error) {
			setLoading(false);
			setError(error);
			console.log(error);
		}
	}

	React.useEffect(() => {
		getTransactions();
	}, []);

	return (
		<Container>
			<Typography variant="button">Transactions</Typography>
			<React.Fragment>
				{
					loading === true ? 
					<Box sx={{ height: '20vh', display: 'grid', placeItems: 'center' }}>
						<CircularProgress />
					</Box> :
					(
						error ? <Typography sx={{ textAlign: 'center' }} color="error">{error.response.data}: {error.message}</Typography> :
						report &&
						<DataTable
							columns={cols}
							data={report}
							pagination paginationPerPage={10}
						/>
					)
				}
			</React.Fragment>
			<Fragment>
				{
					detailsModal === true &&
					<AuditCustomerView
						open={detailsModal}
						close={() => setDetailsModal(false)}
						data={details}
					/>
				}
			</Fragment>
		</Container>
	)
}

export default Table;