import React from 'react';
import { Container, Header, Loading, PageTitle } from '../../../components/StyledComponents';
import { Grid, Button, Typography, Box } from '@mui/material';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import AddComment from './AddComment';
import { Visibility } from '@mui/icons-material';
import Modal from 'react-responsive-modal';

const Wrapper = styled.div`
	position: relative;
	width: 100%;
`;
const Picture = styled.div`
	width: 100%;
	max-width: 10rem;
	height: 11rem;
	padding: 0.25rem;
	border: 1px solid rgba(0, 0, 0, 0.54);
	img {
		max-width: 100%;
		height: 100%;
	}
`;

const gridStyle = {
	box: {
		marginTop: '0.5rem',
		marginBottom: '2rem',
	},
	userBox: {
		border: '2px solid #F4323290',
		padding: '1rem',
		width: '100%'
	},
	picture: {
		width: '100%',
		maxWidth: '11.5rem',
		height: '12rem',
		background: '#C4C4C4'
	},
	container: {
		marginTop: '0rem',
		marginBottom: '2rem'
	},
	gridItem: {
		display: 'flex',
		gap: '1rem',
	},
	btnArray: {
		display: 'flex',
		gap: '1rem',
		maxWidth: '25rem',
	},
	btn: {
		height: '3.5rem',
		width: '100%'
	},
	trxHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: '1rem'
	},
	balance: {
		display: 'flex',
		alignItems: 'center',
		gap: '1.5rem',
		justifyContent: 'space-between',
		padding: '1rem',
		border: '1px solid #F4323290',
		borderRadius: '0.25rem',
		marginBottom: '2rem'
	},
	amount: {
		background: '#F43232',
		padding: '0.75rem 1rem',
		color: '#FFFFFF',
		minWidth: '10rem',
		textAlign: 'center'
	}
};

const ViewCustomer = () => {
	const data = JSON.parse(localStorage.getItem('customerInfo'));
	const token = sessionStorage.getItem('token');
	const [comment, setComment] = React.useState(false);
	const [commentsArray, setCommentsArray] = React.useState(null);
	const [commentsLoading, setCommentsLoading] = React.useState(false);
	const [viewCommentModal, setViewCommentModal] = React.useState(false);
	const [viewComment, setViewComment] = React.useState(null);
	const [commentsError, setCommentsError] = React.useState(false);
	const [transactions, setTransactions] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState(null);

	const view = val => {
		setViewComment(val);
		setViewCommentModal(true);
	}
	const renderDate = (date) => {
		let newdate = new Date(date);
		return newdate.toLocaleDateString("en-GB") + " " + newdate.toLocaleTimeString("en-GB");
	}
	const cols = [
		{
			name: 'Transaction Type',
			selector: row => row["Transaction Type"],
			sortable: true,
		},
		{
			name: 'Transaction Date',
			selector: row => renderDate(row["Transaction date"]),
			sortable: true,
		},
		{
			name: 'Amount',
			selector: row => Number(row.amount).toLocaleString()
		}
	];
	const commentsCols = [
		{
			name: 'Customer Name',
			selector: row => row["Account Name"],
			sortable: true,
		},
		{
			name: 'Comment',
			selector: row => <>{row.comment.length > 40 ? row.comment.substring(0, 40) + "..." : row.comment}</>,
			sortable: true,
		},
		{
			name: 'Added By',
			selector: row => row["created by"],
			sortable: true,
		},
		{
			name: 'Date Added',
			selector: row => renderDate(row.created_on),
			sortable: true,
		},
		{
			name: 'View',
			selector: row => <Visibility onClick={() => view(row)} sx={{ cursor: 'pointer', color: 'rgba(0, 0, 0, 0.54)' }} />,
		}
	];

	const getTransactions = async () => {
		setLoading(true);
		const config = {
			method: 'get',
			url: 'list/transactions',
			headers: { 
				'Authorization': 'Bearer ' + JSON.parse(token),
			}
		}

		try {
			const response = await axios(config);
			if(response.status === 200) {
				setLoading(false);
				let res = response.data.filter(item => Number(item.account_number) === Number(data.account_number)).sort((a, b) => new Date(a["Transaction date"]) > new Date(b["Transaction date"]) ? -1 : 1);
				setTransactions(res);
			}		
		} catch (error) {
			setLoading(false);
			setError(error);
			console.log(error);
		}
	};

	const getComments = async () => {
		setCommentsLoading(true);
		const config = {
			method: 'get',
			url: 'audit/comments',
			headers: { 
				'Authorization': 'Bearer ' + JSON.parse(token),
			}
		}

		try {
			const response = await axios(config);
			if(response.status === 200) {
				const results = response?.data?.data?.filter(item => item["Customer ID"] === data.customer_id).sort((a, b) => new Date(a.created_on) > new Date(b.created_on) ? -1 : 1)
				setCommentsLoading(false);
				setCommentsArray(results);
			}
		} catch (error) {
			setCommentsLoading(false);
			setCommentsError(error);
			console.log(error);
		}
	}

	React.useEffect(() => {
		getTransactions();
		getComments();
	}, []);

	
	return (
		<>
			<Wrapper>
				<PageTitle>Customer Audit - Summary</PageTitle>
					<Grid container spacing={2}>
						<Grid item xs={12} md={3}>
							<Picture>
								<img src="https://www.leafstudio.co.uk/wp-content/uploads/2019/11/person-icon-silhouette-png-0.png" alt="[image]" />
							</Picture>
						</Grid>
						<Grid item xs={12} md={9} sx={{ padding: '2rem 0' }}>
							<Box
								sx={{ 
									display: 'flex',
									gap: '1.5rem',
									flexDirection: {
										xs: 'column',
										sm: 'row'
									}
								}}
							>
								<Box sx={{ display: 'grid', gap: '0.5rem' }}>
									<Typography sx={{ fontSize: '1.25rem' }}>{data.account_name}</Typography>
									<Typography sx={{ fontSize: '1.25rem' }}>Account Number:<br /> {data.account_number}</Typography>
									<Typography sx={{ fontSize: '1.25rem' }}>Phone Number:<br /> {data.phone_no}</Typography>
								</Box>
								<Box sx={{ display: 'grid', gap: '0.5rem' }}>
									<Typography sx={{ fontSize: '1.25rem' }}>Home address:<br /> {data.home_address}</Typography>
									<Typography sx={{ fontSize: '1.25rem' }}>Business address:<br /> {data.business_address}</Typography>
									<Typography sx={{ fontSize: '1.25rem' }}>Area code:</Typography>
									<Typography sx={{ fontSize: '1.25rem' }}>State: {data.state}</Typography>
								</Box>
							</Box>
						</Grid>
					</Grid>
					<Box sx={gridStyle.balance}>
						<Typography variant='button'>account balance</Typography>
						<Typography sx={gridStyle.amount}>{ Number(data.balance).toLocaleString("en-GB", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }</Typography>
					</Box>
					<Grid container spacing={4} sx={gridStyle.container}>
						<Grid xs={12} item>
							<Box sx={gridStyle.trxHeader}>
								<Typography variant='h6'>Transaction History</Typography>
								<Button type="button" variant="outlined">
									{
										transactions &&
										<CSVLink data={transactions} filename="transactions.csv" className='download-link'>
											download
										</CSVLink>
									}
								</Button>
							</Box>
							<React.Fragment>
								{
									loading === true ? <Loading text="Loading report" /> :
									(
										error ? JSON.stringify(error.message) :
										transactions &&
										<Box sx={{ marginY: '1rem' }}>
											<DataTable
												columns={cols} pagination paginationPerPage={10}
												data={transactions}
											/>
										</Box>
									)
								}
							</React.Fragment>
						</Grid>
						<Grid xs={12} item>
							<Box sx={gridStyle.trxHeader}>
								<Typography variant='h6'>Comments</Typography>
								<Button type="button" variant="outlined">
									{
										commentsArray &&
										<CSVLink data={commentsArray} filename="comments.csv" className='download-link'>download</CSVLink>
									}
								</Button>
							</Box>
							<React.Fragment>
								{
									commentsLoading === true ? <Loading text="Loading comments" /> :
									(
										commentsError ? JSON.stringify(commentsError.message) :
										commentsArray &&
										<Box sx={{ marginY: '1rem' }}>
											<DataTable
												pagination paginationPerPage={10}
												columns={commentsCols}
												data={commentsArray}
											/>
										</Box>
									)
								}
							</React.Fragment>
						</Grid>
						<Grid item xs={4}>
							<Button variant="contained" sx={gridStyle.btn} onClick={() => setComment(true)}>Add Comment</Button>
						</Grid>
					</Grid>
			</Wrapper>
			<React.Fragment>
				{ comment && <AddComment open={comment} close={() => setComment(false)} data={data} getComments={getComments} /> }
			</React.Fragment>
			<React.Fragment>
				{
					viewCommentModal &&
					<Modal open={viewCommentModal} onClose={() => setViewCommentModal(false)} classNames={{ modal: 'filterModal' }} center>
						<Container>
							<Header>View Comment</Header>
							<Grid container spacing={2} sx={{ marginTop: '1rem' }}>
								<Grid item xs={12}>
									<Typography variant="h5">{data.account_name}</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography>Added on {renderDate(viewComment.created_on)} by {viewComment["created by"]}</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography>{viewComment.comment}</Typography>
								</Grid>
								<Grid item xs={12} sx={{ marginBottom: '1rem' }}>
									<Button variant="outlined" fullWidth onClick={() => setViewCommentModal(false)}>close</Button>
								</Grid>
							</Grid>
						</Container>
					</Modal>
				}
			</React.Fragment>
		</>
	)
}

export default ViewCustomer;