import React from 'react';
import { Modal } from 'react-responsive-modal';
import { Container, Header, TextInput, Dropdown } from '../../components/StyledComponents';
import { Grid, Button, CircularProgress, Box, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';

const NewInvestment = ({ open, close }) => {
	const token = sessionStorage.getItem('token');
	const INIT = {
		tenure: '', period: '', rate: '', amount: '', accountNumber: ''
	}

	const schema = Yup.object().shape({
		tenure: Yup.number().required('Required'),
		period: Yup.string().required('Required'),
		rate: Yup.number().required('Required'),
		amount: Yup.number().required('Required'),
		accountNumber: Yup.number().required('Required')
	});

	const [name, setName] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const validate = async (acct) => {
		setLoading(true);
		const data = {
			account_number: acct
		}
		const config = {
			method: 'post',
			url: 'transaction/confirm',
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			},
			data: data
		}
		try {
			const res = await axios(config);
			if(res.status === 200) {
				setLoading(false);
				setName(res.data.account_name);
			} else {
				toast.warn(res.data.message);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
			toast.error(error.message);
		}
	};

	const options = [
		{ name: 'Weeks', val: 'weeks' },
		{ name: 'Months', val: 'months' },
		{ name: 'Years', val: 'years' },
	];

	return (
		<Modal classNames={{ modal: 'customModal' }} open={open} onClose={close} center>
			<Container>
				<Header>New Investment</Header>
				<Formik
					initialValues={INIT}
					validationSchema={schema}
					validateOnBlur={false}
					validateOnChange={false}
					onSubmit={async (values) => {
						const data = {
							tenure: values.tenure,
							period: values.period.toLowerCase(),
							rate: values.rate,
							amount: values.amount,
							account_number: values.accountNumber
						}
						const config = {
							method: 'post',
							url: 'investment/request',
							headers: {
								'Authorization': 'Bearer ' + JSON.parse(token)
							},
							data: data
						}
						try {
							const res = await axios(config);
							close();
							if(res.status === 200) {
								toast.success("Investment request submitted!");
							} else {
								toast.warn(res.data.message);
							}
						} catch (error) {
							console.log(error);
							toast.error(error.message);
							close();
						}
					}}
				>
					{({ values, isSubmitting }) => {
						const { accountNumber } = values;
						return (
							<Form>
								<Grid container spacing={3} sx={{ marginY: '1.5rem' }}>
									<Grid item xs={12}>
										<Box sx={{ display: 'flex', gap: '0.5rem' }}>
											<TextInput name="accountNumber" label="Account Number" />
											<Button disabled={loading} onClick={() => validate(accountNumber)} type="button" variant='contained' sx={{ width: '10rem' }}>
												{
													loading === true ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : 'Validate'
												}
											</Button>
										</Box>
										<React.Fragment>
											{
												name && <Typography sx={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, 0.54)', marginLeft: '0.75rem' }}>{name}</Typography>
											}
										</React.Fragment>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextInput name="tenure" label="Investment Tenure" />
									</Grid>
									<Grid item xs={12} md={6}>
										<Dropdown name="period" label="Period" options={options} />
									</Grid>
									<Grid item xs={12} md={6}>
										<TextInput name="rate" label="Investment Rate" />
									</Grid>
									<Grid item xs={12} md={6}>
										<TextInput name="amount" label="Amount" />
									</Grid>
									<Grid item xs={12}>
										<Button disabled={isSubmitting} variant="contained" type="submit" fullWidth sx={{ height: '3.625rem' }}>
											{
												isSubmitting === true ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : 'add investment'
											}
										</Button>
									</Grid>
								</Grid>
							</Form>
						)
					}}
				</Formik>
			</Container>
		</Modal>
	)
}

export default NewInvestment;