import styled from 'styled-components';
import { CircularProgress, TextField, MenuItem, FormControl, FormControlLabel, FormGroup, Checkbox as MuiCheckbox } from '@mui/material';
import { useField, useFormikContext } from 'formik';

export const Container = styled.div`
	width: 100%;
	margin-top: 2rem;
`;
export const Header = styled.div`
	color: #FFFFFF;
	background-color: #F43232;
	font-size: 2rem;
	padding: 0.5rem 1rem;
`;
export const Form = styled.form`
	border: 1px solid #C4C4C4;
	margin: 3rem auto 1rem auto;
	max-width: 500px;
	min-height: 400px;
`;
export const FormTitle = styled.h2`
	font-size: 1.5rem;
	padding: 0.5rem 1.5rem;
	border-bottom: 1px solid #C4C4C4;
	font-weight: 500;
`;
export const FormItem = styled.div`
	margin: 1.25rem;
`;
export const Label = styled.label`
	font-size: ${props => props.size};
	font-weight: ${props => props.weight};
`;
export const Input = styled.input`
	display: block;
	height: 2.5rem;
	margin-top: 0.25rem;
	width: 100%;
	font-size: 0.875rem;
	padding: 0 0.5rem;
	letter-spacing: 1px;
	border: 1px solid #C4C4C4;
	&:focus {
		border: 2px solid #F43232;
		outline: none;
	}
`;
export const CheckBox = styled.input`
	width: ${props => props.width};
	height: ${props => props.height};
	margin-right: ${props => props.mr};
	margin-left: ${props => props.ml};
	margin-top: ${props => props.mt};
	margin-bottom: ${props => props.mb};
`;
export const Button = styled.button`
	display: block;
	margin: 1rem 0;
	background-color: #F43232;
	width: 100%;
	font-size: 1.25rem;
	color: #FFFFFF;
	border: transparent;
	height: 2.75rem;
	font-weight: 500;
	cursor: pointer;
	outline: none;
`;
export const Select = styled.select`
display: block;
width: 100%;
height: 2.5rem;
padding: 0 0.5rem;
margin-top: 0.25rem;
cursor: pointer;
border: 1px solid #C4C4C4;
outline: none;
&:focus {
	border: 2px solid #F43232;
	outline: none;
}
`;
export const TextArea = styled.textarea`
	display: block;
	width: 100%;
	resize: none;
	height: 5rem;
	padding: 0.5rem;
	font-size: inherit;
	border: 1px solid #C4C4C4;
	&:focus {
		border: 1px solid #F43232;
		outline: none;
	}
`;
export const CardRow = styled.div`
	padding: 1rem 0;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-gap: 2rem;
`;
export const Card = styled.div`
	box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.32);
	border-radius: 0.125rem;
	padding: 2rem;
	display: flex;
	min-height: 12.5rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: rgba(0, 0, 0, 0.6);
	transition: 0.2s ease-in-out;
	&:hover {
		transform: scale(1.025);
	}
`;
export const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${props => props.bg};
	padding: 0.5rem;
	width: 3.5rem;
	height: 56px;
`;
export const Icon = styled.img``;
export const Title = styled.h1`
	margin-top: 0.5rem;
	font-size: 2.25rem;
	@media(max-width: 768px) {
		font-size: 1.5rem;
	}
`;
export const PageTitle = styled.h1`
	border-bottom: 1px solid #C4C4C4;
	padding: 1rem 0;
	margin-bottom: 1rem;
	color: rgba(0, 0, 0, 0.87);
	font-size: 2.125rem;
`;
const Section = styled.div`
	/* position: absolute;
	top: 12.5rem;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10; */
	width: 100%;
	display: grid;
	place-items: center;
`;
const Wrapper = styled.div`
	text-align: center;
	p {
		font-size: 2rem;
		font-weight: 700;
		margin-bottom: 2rem;
		color: rgba(0, 0, 0, 0.75);
	}
`;
export const SearchForm = styled.form`
	width: 100%;
	max-width: 300px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #C4C4C4;
	.icon {
		margin: 0 0.5rem;
		color: #C4C4C4;
	}
	${Input} {
		margin: 0;
		border: none;
		outline: none;
	}
	@media(max-width: 625px) {
		max-width: unset;
	}
`;

export const Loading = ({ text }) => {
	return(
		<Section>
			<Wrapper>
				<p>{text}</p>
				<CircularProgress size="5rem" sx={{ color: '#F43232' }} />
			</Wrapper>			
		</Section>
	)
};

// formik
export const TextInput = ({ name, ...props }) => {
	const [field, meta] = useField(name);
	const config = { ...props, ...field, fullWidth: true, variant: 'outlined', autoComplete: 'new-txt' };
	if(meta && meta.error && meta.touched) {
		config.error = true;
		config.helperText = meta.error;
	}
	return <TextField {...config} />;
}

export const Check = ({ name, label, legend, ...others }) => {

	const { setFieldValue } = useFormikContext();
	const [field] = useField(name);
	const handleChange = e => {
		const { checked } = e.target;
		setFieldValue(name, checked);
	}
	const config = {
		...field, ...others,
		onChange: handleChange
	}

	return (
		<FormControl>
			<FormGroup>
				<FormControlLabel
					control={<MuiCheckbox {...config}  />}
					label={label}
				>
				</FormControlLabel>
			</FormGroup>
		</FormControl>
	)
}

export const Dropdown = ({ name, options, ...props }) => {
	const { setFieldValue } = useFormikContext();
	const [field, meta] = useField(name);
	const handleChange = e => {
		setFieldValue(name, e.target.value);
	}

	const config = {
		...field,
		...props,
		select: true,
		variant: 'outlined',
		fullWidth: true,
		onChange: handleChange
	}
	if(meta && meta.error && meta.touched) {
		config.error = true;
		config.helperText = meta.error;
	}

	return <TextField {...config}>
		{ options.map(item => (
			<MenuItem key={item.val} value={item.name}>{item.name}</MenuItem>
		)) }
	</TextField>
}

export const SelectMenu = ({ name, options, ...props }) => {
	const { setFieldValue } = useFormikContext();
	const [field, meta] = useField(name);
	const handleChange = e => {
		setFieldValue(name, e.target.value);
	}

	const config = {
		...field,
		...props,
		select: true,
		variant: 'outlined',
		fullWidth: true,
		onChange: handleChange
	}
	if(meta && meta.error && meta.touched) {
		config.error = true;
		config.helperText = meta.error;
	}

	return <TextField {...config}>
		{ options.map((item, i) => (
			<MenuItem key={item.val} value={item.val}>{item.name}</MenuItem>
		)) }
	</TextField>
}