import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { PageTitle, Loading } from '../../../components/StyledComponents';
import SearchIcon from '@mui/icons-material/Search';
import FilterModal from './FilterModal';
import { Grid, FormControl, InputLabel, OutlinedInput, IconButton, InputAdornment, Button as MuiButton, Box } from '@mui/material';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { getAreaCodes } from '../../../redux/slice';

const Container = styled.div`
	.formControl {
		width: 100%;
		max-width: 400px;
	}
	.top-box {
		display: flex;
		gap: 2rem;
		justify-content: space-between;
	}
	@media(max-width: 768px) {
		.top-box {
			flex-direction: column;
			align-items: flex-end;
		}
		.formControl {
			max-width: unset;
		}
	}
`;
const Table = styled.div`
	margin: 2rem 0;
`;

const Reports = () => {
	const dispatch = useDispatch();

	const renderDate = (date) => {
		let newdate = new Date(date);
		return newdate.toLocaleDateString("en-GB") + " " + newdate.toLocaleTimeString("en-GB");
	}

	let token = sessionStorage.getItem('token');
	const cols = [
		{
			name: 'Transaction Date',
			selector: row => renderDate(row["Transaction date"]),
			sortable: true,
			// width: '11rem',
		},
		{
			name: 'Transaction ID',
			selector: row => row.transaction_id,
			sortable: true,
			// width: '12rem',
		},
		{
			name: 'Account Number',
			selector: row => row.account_number,
			sortable: true
		},
		{
			name: 'Amount',
			selector: row => Number(row.amount).toLocaleString(),
			sortable: true
		},
		{
			name: 'Transaction Type',
			selector: row => <>{row["Transaction Type"] === "Charges" ? "Loan" : row["Transaction Type"]}</>,
			sortable: true,
			// width: '11rem'
		}
	];
	
	const [filterModal, setFilterModal] = useState(false);
	const [error, setError] = useState(null);
	const [report, setReport] = useState([]);
	const [reporting, setReporting] = useState([]);
	const [loading, setLoading] = useState(false);
	const [text, setText] = useState('');
	const [customers, setCustomers] = useState([]);

	const getTransactions = async () => {
		setLoading(true);
		const config = {
			method: 'get',
			url: 'list/transactions',
			headers: { 
				'Authorization': 'Bearer ' + JSON.parse(token),
			}
		}

		try {
			const response = await axios(config);
			if(response.status === 200) {
				setLoading(false);
				let data = response.data;
				data = data.sort((a, b) => new Date(a["Transaction date"]) > new Date(b["Transaction date"]) ? -1 : 1);
				setReport(data);
				setReporting(data);
			}		
		} catch (error) {
			setLoading(false);
			setError(error);
			console.log(error);
		}
	}

	const getCustomers = async () => {
		setLoading(true);
		const config = {
			method: 'get',
			url: "list/customers",
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			}
		}

		try {
			const res = await axios(config);
			setLoading(false);
			if(res.status === 200) {
				const { data } = res;
				setCustomers(data);
			}
		} catch (error) {
			console.log(error);
			setError(error);
			setLoading(false);
		}
	};

	let newcustomers = customers.map(item => {
		return {
			label: item.account_name,
			id: Number(item.account_number)
		}
	});

	useEffect(() => {
		getTransactions();
	}, []);

	useEffect(() => {
		getCustomers();
	}, []);

	useEffect(() => {
		token = JSON.parse(token);
		dispatch(getAreaCodes(token));
	}, []);

	const areacodes = useSelector(state => state.nebo.areaCodes.data);

	return (
		<Container>
			<PageTitle>Transactions</PageTitle>
			<Grid container columnSpacing={4} sx={{ alignItems: "center", justifyContent: "space-between", marginY: '2rem' }}>
				<Grid item xs={12} className="top-box">
					<FormControl className='formControl' variant="outlined">
						<InputLabel htmlFor="search-input">Search</InputLabel>
						<OutlinedInput
							id="search-input"
							onChange={(e) => setText(e.target.value)}
							value={text}
							endAdornment={
								<InputAdornment position="end">
									<IconButton edge="end">
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							}
							label="Search"
						/>
					</FormControl>
					<Box sx={{ display: 'flex', gap: '0.5rem' }}>
						<MuiButton
							variant="contained" fullWidth color="primary" 
							onClick={() => setFilterModal(true)}
							sx={{ height: '3.75rem', width: '7.5rem' }}
						>
							Filter
						</MuiButton>
						<MuiButton variant="contained" sx={{ height: '3.625rem', width: '7.5rem', paddingX: '2rem' }}>
							{	report &&
								<CSVLink data={report} filename="transactions.csv" className='download-link'>
									download
								</CSVLink>
							}
						</MuiButton>
					</Box>
				</Grid>
			</Grid>
			<React.Fragment>
				{
					loading === true ? <Loading text="Loading report" /> :
					(
						error ? JSON.stringify(error.message) :
						report &&
						<Table>
							<DataTable
								columns={cols} pagination paginationPerPage={15}
								data={
									report.filter(item => {
										if(text.trim() === "") {
											return item;
										} else if(item.account_name.toLowerCase().includes(text.toLowerCase())) {
											return item;
										} else if(item.account_number.toLowerCase().includes(text.toLowerCase())) {
											return item;
										} else if(item.transaction_id.toLowerCase().includes(text.toLowerCase())) {
											return item;
										}
									})
								}
							/>
						</Table>
					)
				}
			</React.Fragment>
			<React.Fragment>
				{
					filterModal === true &&
					<FilterModal
						open={filterModal}
						close={() => setFilterModal(false)}
						reporting={reporting}
						setReport={setReport}
						customers={newcustomers}
						areacodes={areacodes}
					/>
				}
			</React.Fragment>
		</Container>
	)
}

export default Reports