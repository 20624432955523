import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

const initialState = {
	report: {
		data: null,
		loading: false,
		error: null,
	},
	investments: {
		data: null,
		loading: false,
		error: null,
	},
	loans: {
		data: null,
		loading: false,
		error: null
	},
	customers: {
		data: null,
		loading: false,
	},
	customerLoans: {
		data: null,
		loading: false,
		error: null,
	},
	areaCodes: {
		data: null,
		loading: false,
		error: null,
	},
	transfers: {
		data: null,
		loading: false,
		error: null,
	},
}

export const getCustomers = createAsyncThunk("reports/getCustomers", async (token) => {
	const config = {
		method: 'get',
		url: 'list/customers',
		headers: { 
			'Authorization': 'Bearer ' + token,
		}
	}

	try {
		const response = await axios(config);
		let data = response.data;
		return data;
	} catch (error) {
		console.log(error);
	}
});

export const getReports = createAsyncThunk("reports/getTransactions", async (token) => {
	const config = {
		method: 'get',
		url: 'list/transactions',
		headers: { 
			'Authorization': 'Bearer ' + token,
		}
	}

	try {
		const response = await axios(config);
		let data = response.data;
		data = data.sort((a, b) => new Date(a["Transaction date"]) > new Date(b["Transaction date"]) ? -1 : 1);	
		return data;
	} catch (error) {
		console.log(error);
	}
});

export const getInvestments = createAsyncThunk("reports/getInvestments", async (token, { rejectWithValue }) => {
	const config = {
		method: 'get',
		url: 'investment/all',
		headers: { 
			'Authorization': 'Bearer ' + token,
		}
	}

	try {
		const response = await axios(config);
		let data = response.data.Investments;
		data = data.sort((a, b) => new Date(a["Request Date"]) > new Date(b["Request Date"]) ? -1 : 1);	
		return data;
	} catch (error) {
		console.log(error);
		throw rejectWithValue(error);
	}
});

export const getLoans = createAsyncThunk("reports/getLoans", async (token, { rejectWithValue }) => {
	const config = {
		method: 'get',
		url: 'loan/all',
		headers: { 
			'Authorization': 'Bearer ' + token,
		}
	}

	try {
		const response = await axios(config);
		let data = response.data;
		data = data.sort((a, b) => new Date(a["Request Date"]) > new Date(b["Request Date"]) ? -1 : 1);	
		return data;
	} catch (error) {
		console.log(error.message);
		throw rejectWithValue(error);
	}
});

export const getTransfers = createAsyncThunk("reports/getTransfers", async (token, { rejectWithValue }) => {
	const config = {
		method: 'get',
		url: 'transaction/transfers',
		headers: { 
			'Authorization': 'Bearer ' + token,
		}
	}

	try {
		const response = await axios(config);
		let data = response.data;
		data = data.sort((a, b) => new Date(a.date_created) > new Date(b.date_created) ? -1 : 1);
		localStorage.setItem('transfers', JSON.stringify(data));
		return data;
	} catch (error) {
		console.log(error.message);
		throw rejectWithValue(error);
	}
});

export const getAreaCodes = createAsyncThunk("reports/getAreaCodes", async (token, { rejectWithValue }) => {
	const config = {
		method: 'get',
		url: 'list/market_area',
		headers: { 
			'Authorization': 'Bearer ' + token,
		}
	}

	try {
		const response = await axios(config);
		let data = response.data?.sort((a, b) => a.area_name.localeCompare(b.area_name));
		return data;
	} catch (error) {
		console.log(error.message);
		throw rejectWithValue(error);
	}
});

export const mainSlice = createSlice({
	name: 'nebo',
	initialState,
	reducers: {
		updateLoanSearch: (state, action) => {
			state.loans.data = action.payload;
		}
	},
	extraReducers: {
		[getReports.pending]: state => {
			state.report.loading = true;
		},
		[getReports.fulfilled]: (state, action) => {
			state.report.data = action.payload;
			state.report.loading = false;
		},
		[getReports.rejected]: state => {
			state.report.loading = false;
		},
		[getInvestments.pending]: state => {
			state.investments.loading = true;
		},
		[getInvestments.fulfilled]: (state, action) => {
			state.investments.data = action.payload;
			state.investments.loading = false;
		},
		[getInvestments.rejected]: (state, action) => {
			state.investments.loading = false;
			state.investments.error = action.payload;
		},
		[getLoans.pending]: state => {
			state.loans.loading = true;
		},
		[getLoans.fulfilled]: (state, action) => {
			state.loans.data = action.payload;
			state.loans.loading = false;
		},
		[getLoans.rejected]: (state, action) => {
			state.loans.loading = false;
			state.loans.error = action.payload;
		},
		[getCustomers.pending]: state => {
			state.customers.loading = true;
		},
		[getCustomers.fulfilled]: (state, action) => {
			state.customers.data = action.payload;
			state.customers.loading = false;
		},
		[getCustomers.rejected]: state => {
			state.customers.loading = false;
		},
		[getAreaCodes.pending]: state => {
			state.customers.loading = true;
		},
		[getAreaCodes.fulfilled]: (state, action) => {
			state.areaCodes.data = action.payload;
			state.areaCodes.loading = false;
		},
		[getAreaCodes.rejected]: (state, action) => {
			state.areaCodes.loading = false;
			state.areaCodes.error = action.payload;
		},
		[getTransfers.pending]: state => {
			state.transfers.loading = true;
		},
		[getTransfers.fulfilled]: (state, action) => {
			state.transfers.data = action.payload;
			state.transfers.loading = false;
		},
		[getTransfers.rejected]: (state, action) => {
			state.transfers.loading = false;
			state.transfers.error = action.payload;
		},
	}
});

export const  { updateLoanSearch } = mainSlice.actions;
export default mainSlice.reducer;
