import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Card, IconWrapper, Icon, Title, PageTitle } from '../../components/StyledComponents';
// components
import Deposit from './Deposit';
import Withdrawal from './Withdrawal';
import Transfer from './Transfer';
import LoanCollection from './LoanCollection';
import NewCustomer from './NewCustomer';

const Text = styled.p`
	font-weight: ${props => props.weight};
	font-size: ${props => props.size};
	line-height: ${props => props.spacing};
	margin-top: ${props => props.mt};
	margin-right: ${props => props.mr};
`;
const CardFooter = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Marketer = () => {
	const [depositOpen, setDepositOpen] = useState(false);
	const [withdrawal, setwithdrawal] = useState(false);
	const [transfer, setTransfer] = useState(false);
	const [loan, setLoan] = useState(false);
	const [onboard, setOnboard] = useState(false);
	return (
		<Fragment>
				<PageTitle>Customer Center</PageTitle>
				<Grid container spacing={4}>
					<Grid item xs={12} md={4}>
						<Card onClick={() => setDepositOpen(true)}>
							<IconWrapper bg="#1890FF">
								<Icon src="/main/assets/depo.png" alt="" />
							</IconWrapper>
							<Title>Deposit</Title>
						</Card>
					</Grid>
					<Grid item xs={12} md={4}>
						<Card onClick={() => setwithdrawal(true)}>
							<IconWrapper bg="#47D024">
								<Icon src="/main/assets/withdraw.png" alt="" />
							</IconWrapper>
							<Title>Withdrawal</Title>
						</Card>
					</Grid>
					<Grid item xs={12} md={4}>
						<Card onClick={() => setTransfer(true)}>
							<IconWrapper bg="#FF0000">
								<Icon src="/main/assets/transfer.png" alt="" />
							</IconWrapper>
							<Title>Transfer</Title>
						</Card>
					</Grid>
					<Grid item xs={12} md={4}>
						<Card onClick={() => setOnboard(true)}>
							<Title weight="700" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
								<AddCircleIcon style={{ fontSize: '2.5rem', marginLeft: '0.5rem', color: '#F43232' }} />
								<span>Onboard</span>
							</Title>
							<CardFooter>
								<Text size="0.875rem" spacing="15px" mt="0.5rem" mr="0.75rem">
									Select product to fulfil <br /> customer financial needs.
								</Text>
							</CardFooter>
						</Card>
					</Grid>
					<Grid item xs={12} md={4}>
						<Card onClick={() => setLoan(true)}>
							<Title weight="700">Loan collection</Title>
							<Text size="0.875rem" mt="0.5rem">20 loans</Text>
							<CardFooter style={{ borderTop: '1px solid gray', marginTop: '0.5rem'}}>
								<Text size="0.875rem" spacing="15px">
									View all
								</Text>
								<ChevronRightIcon sx={{ cursor: 'pointer' }} />
							</CardFooter>
						</Card>
					</Grid>
				</Grid>

				<React.Fragment>
					{
						depositOpen === true && 
						<Deposit depositOpen={depositOpen} close={() => setDepositOpen(false)} />
					}
					{
						withdrawal === true &&
						<Withdrawal open={withdrawal} close={() => setwithdrawal(false)} />
					}
					{
						transfer === true &&
						<Transfer open={transfer} close={() => setTransfer(false)} />
					}
					{
						loan === true &&
						<LoanCollection open={loan} close={() => setLoan(false)} />
					}
					{
						onboard === true &&
						<NewCustomer open={onboard} close={() => setOnboard(false)} />
					}
				</React.Fragment>
		</Fragment>
	)
}

export default Marketer;