import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Marketer from './pages/Marketer/Marketer';
import Transfers from './pages/Transfer';
import TransferInfo from './pages/Transfer/Transfers';
import Withdrawal from './pages/Withdrawal';
import Loans from './pages/Loans/Loans';
import Borrowers from './pages/Loans/Borrowers';
import LoanList from './pages/Loans/List';
import BorrowerInfo from './pages/Loans/BorrowerInfo';
import Collections from './pages/Loans/Collections';
import CollectionInfo from './pages/Loans/CollectionInfo';
import Investment from './pages/Investment';
import Investors from './pages/Investment/Investors';
import Investor from './pages/Investment/Investor';
import Reports from './pages/Admin/Reports';
import Admin from './pages/Admin/Admin';
import Users from './pages/Admin/Users';
import Customers from './pages/Marketer/Customers';
import Audit from './pages/Audit';
import Data from './pages/Audit/Data';
import AuditUser from './pages/Audit/Users';
import AuditCustomer from './pages/Audit/Customers';
import ViewAuditCustomer from './pages/Audit/Customers/ViewCustomer';
import AuditNotifications from './pages/Audit/Notifications';
import PrivateRoute from './utils/PrivateRoute';
import Privacy from './pages/Privacy';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-modal/styles.css';

// axios


class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Switch>
          <Route exact path="/auth/login" component={Login} />
          <Route exact path="/privacy-policy" component={Privacy} />
          <PrivateRoute exact path="/" component={Marketer} />
          <PrivateRoute exact path="/transfers" component={Transfers} />
          <PrivateRoute exact path="/transfers/transactions" component={TransferInfo} />
          <PrivateRoute exact path="/withdrawals" component={Withdrawal} />
          <PrivateRoute exact path="/loans" component={Loans} />
          <PrivateRoute exact path="/loans/list" component={LoanList} />
          <PrivateRoute exact path="/loans/borrowers" component={Borrowers} />
          <PrivateRoute exact path="/loans/borrowers/:id" component={BorrowerInfo} />
          <PrivateRoute exact path="/loans/collection" component={Collections} />
          <PrivateRoute exact path="/loans/collections/customer-summary/:id" component={CollectionInfo} />
          <PrivateRoute exact path="/investments" component={Investment} />
          <PrivateRoute exact path="/investments/investors" component={Investors} />
          <PrivateRoute exact path="/investments/investor/:id" component={Investor} />
          <PrivateRoute exact path="/admin/dashboard" component={Admin} />
          <PrivateRoute exact path="/admin/users" component={Users} />
          <PrivateRoute exact path="/admin/customers" component={Customers} />
          <PrivateRoute exact path="/admin/reporting" component={Reports} />
          <PrivateRoute exact path="/admin/audit" component={Audit} />
          <PrivateRoute exact path="/admin/audit/data" component={Data} />
          <PrivateRoute exact path="/admin/audit/customers" component={AuditCustomer} />
          <PrivateRoute exact path="/admin/audit/customers/:customerId" component={ViewAuditCustomer} />
          <PrivateRoute exact path="/admin/audit/users" component={AuditUser} />
          <PrivateRoute exact path="/admin/audit/notifications" component={AuditNotifications} />
          <PrivateRoute path="*" component={NotFound} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default App;