import React from 'react';
import { Modal } from 'react-responsive-modal';
import { Header, Loading } from '../../../components/StyledComponents';
import { Grid, Button, Typography, Box, CircularProgress } from '@mui/material';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import EditRate from './EditRate';
import { getLoans, getInvestments } from '../../../redux/slice';
import { useSelector, useDispatch } from 'react-redux';


const Container = styled.div`
	width: 100%;
	margin-top: 2rem;
`;
const Picture = styled.div`
	width: 100%;
	max-width: 10rem;
	height: 11rem;
	padding: 0.25rem;
	border: 1px solid rgba(0, 0, 0, 0.54);
	img {
		max-width: 100%;
		height: 100%;
	}
`;
const Info = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1rem;
	width: 100%;
`;
const InfoItem = styled.div`
	padding: 0 1rem;
`;
const Item = styled.div`
	margin-bottom: 0.75rem;
	.name {
		font-weight: bold;
	}
`;
const Text = styled.p`
	font-weight: ${props => props.weight};
	font-size: 1.25rem;
`;
const HeaderBox = styled.div`
	border: 2px solid #F4323290;
	margin: 2rem 0;
	padding: 1rem;
	display: flex;
	@media(max-width: 768px) {
		flex-direction: column;
		gap: 2rem;
		align-items: center;
		br {
			display: none;
		}
		${Info} {
			grid-template-columns: 1fr;
			text-align: center;
		}
	}
`;

const gridStyle = {
	box: {
		marginTop: '0.5rem',
		marginBottom: '2rem',
	},
	userBox: {
		border: '2px solid #F4323290',
		padding: '1rem',
		width: '100%'
	},
	picture: {
		width: '100%',
		maxWidth: '11.5rem',
		height: '12rem',
		background: '#C4C4C4'
	},
	container: {
		marginTop: '0rem',
		marginBottom: '2rem'
	},
	gridItem: {
		display: 'flex',
		gap: '1rem',
	},
	btnArray: {
		display: 'flex',
		gap: '1rem',
		maxWidth: '25rem',
	},
	btn: {
		height: '3.5rem',
		width: '100%'
	},
	trxHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: '1rem'
	},
	balanceContainer: {
		border: '1px solid #F4323290',
	},
	balance: {
		display: 'flex',
		alignItems: 'center',
		gap: '1.5rem',
		justifyContent: 'space-between',
		padding: '1rem',
		borderRadius: '0.25rem',
	},
	amount: {
		background: '#F43232',
		padding: '0.75rem 1rem',
		color: '#FFFFFF',
		minWidth: '10rem',
		textAlign: 'center'
	}
};

const View = ({ open, close, data, getAccounts }) => {
	const dispatch = useDispatch();
	let token = sessionStorage.getItem('token');
	const [transactions, setTransactions] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [editModal, setEditModal] = React.useState(false);
	const [error, setError] = React.useState(null);
	let user = sessionStorage.getItem('user');
	let [userRole, setUserRole] = React.useState(null);
	const renderDate = (date) => {
		let newdate = new Date(date);
		return newdate.toLocaleDateString("en-GB") + " " + newdate.toLocaleTimeString("en-GB");
	}

	const cols = [
		{
			name: 'Transaction Type',
			selector: row => <>{row["Transaction Type"] === "Charges" ? "Loan" : row["Transaction Type"]}</>,
			sortable: true,
		},
		{
			name: 'Transaction Date',
			selector: row => renderDate(row["Transaction date"]),
			sortable: true,
		},
		{
			name: 'Amount',
			selector: row => Number(row.amount).toLocaleString()
		}
	];

	const getTransactions = async () => {
		setLoading(true);
		const config = {
			method: 'get',
			url: 'list/transactions',
			headers: { 
				'Authorization': 'Bearer ' + JSON.parse(token),
			}
		}

		try {
			const response = await axios(config);
			if(response.status === 200) {
				setLoading(false);
				let res = response.data.filter(item => Number(item.account_number) === Number(data.account_number)).sort((a, b) => new Date(a["Transaction date"]) > new Date(b["Transaction date"]) ? -1 : 1);
				setTransactions(res);
			}		
		} catch (error) {
			setLoading(false);
			setError(error);
			console.log(error);
		}
	};

	React.useEffect(() => {
		getTransactions();
		// eslint-disable-next-line
	}, []);

	React.useEffect(() => {
		if(user) setUserRole(JSON.parse(user).role_id);
	}, [user]);

	React.useEffect(() => {
		token = JSON.parse(token);
		dispatch(getLoans(token));
		// eslint-disable-next-line
	}, []);


	// get loans balance
	const loans = useSelector(state => state.nebo.loans.data)
	const loansLoading = useSelector(state => state.nebo.loans.loading);
	let customerLoans;
	const [loansTotal, setLoansTotal] = React.useState(0);
	if(loans) {
		customerLoans = loans.filter(item => Number(item.account_number) === Number(data.account_number));
	}

	React.useEffect(() => {
		let totals = 0;
		if(customerLoans) {
			customerLoans.forEach(item => {
				totals += Number(item.amount_unpaid);
			});
			setLoansTotal(totals);
		}
	}, [customerLoans]);

	
	// investment balance
	React.useEffect(() => {
		// token = JSON.parse(token);
		dispatch(getInvestments(token));
	}, []);
	const investments = useSelector(state => state.nebo.investments.data);
	const investmentsLoading = useSelector(state => state.nebo.investments.loading);
	let customerInvestments;
	const [investmentsTotal, setInvestmentsTotal] = React.useState(0);
	if(investments) {
		customerInvestments = investments.filter(item => Number(item["Accout Number"]) === Number(data.account_number))?.filter(item => item["Investment status"] === "Running");
	}

	React.useEffect(() => {
		let totals = 0;
		if(customerInvestments) {
			customerInvestments.forEach(item => {
				totals += Number(item.total_returns);
			});
			setInvestmentsTotal(totals);
		}
	}, [customerInvestments]);

	return (
		<Modal classNames={{ modal: 'auditModal' }} open={open} onClose={close} center>
			<Container>
				<Header>Customer Summary</Header>
				<HeaderBox>
					<Picture>
						<img src="https://www.leafstudio.co.uk/wp-content/uploads/2019/11/person-icon-silhouette-png-0.png" alt="profile" />
					</Picture>
					<Info>
						<InfoItem>
							<Item>
								<Text className='name'>{data.account_name}</Text>
							</Item>
							<Item>
								<Text>Account Number:<br /> {data.account_number}</Text>
							</Item>
							<Item>
								<Text>Phone Number:<br /> {data.phone_no}</Text>
							</Item>
							<Item>
								<Text>Market Area: {data.area_code}</Text>
							</Item>
						</InfoItem>
						<InfoItem>
							<Item><Text>Home address:<br /> {data.home_address}</Text></Item>
							<Item><Text>Business address:<br /> {data.business_address}</Text></Item>
							{/* <Item><Text>Area code:</Text></Item> */}
							<Item><Text>State: {data.state}</Text></Item>
							<Item><Text>Account Status: {data["Activity Status"]}</Text></Item>
						</InfoItem>
					</Info>
				</HeaderBox>
				<Box sx={{ marginBottom: '1rem', display: 'flex', gap: '0.5rem', alignItems: 'flex-end'  }}>
					<Item>
						<Text>
							Current rate: {Number(data.current_rate)}%
						</Text>
					</Item>
					<>{
						(userRole && userRole === 1) &&
						<Button onClick={() => setEditModal(true)} variant="outlined">Edit</Button>
					}</>
				</Box>
				<Box sx={gridStyle.balanceContainer}>
					<Box sx={gridStyle.balance}>
						<Typography variant='button'>savings account balance</Typography>
						<Typography sx={gridStyle.amount}>{ Number(data.balance).toLocaleString("en-GB", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }</Typography>
					</Box>
					<Box sx={gridStyle.balance}>
						<Typography variant='button'>active investments</Typography>
						<Typography sx={gridStyle.amount}>
							{
								investmentsLoading === true ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : investmentsTotal.toLocaleString("en-GB", {minimumFractionDigits: 2, maximumFractionDigits: 2})
							}
						</Typography>
					</Box>
					<Box sx={gridStyle.balance}>
						<Typography variant='button'>unpaid loan balance</Typography>
						<Typography sx={gridStyle.amount}>
							{
								loansLoading === true ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : loansTotal.toLocaleString("en-GB", {minimumFractionDigits: 2, maximumFractionDigits: 2})
							}
						</Typography>
					</Box>
				</Box>
				<Grid container spacing={4} sx={gridStyle.container}>
					<Grid xs={12} item>
						<Box sx={gridStyle.trxHeader}>
							<Typography variant='h6'>Transaction History</Typography>
							<Button type="button" variant="outlined">
								{
									transactions &&
									<CSVLink data={transactions} filename="transactions.csv" className='download-link'>
										download
									</CSVLink>
								}
							</Button>
						</Box>
						<React.Fragment>
							{
								loading === true ? <Loading text="Loading report" /> :
								(
									error ? JSON.stringify(error.message) :
									transactions &&
									<Box sx={{ marginY: '1rem' }}>
										<DataTable
											columns={cols}
											pagination paginationPerPage={5}
											data={transactions}
										/>
									</Box>
								)
							}
						</React.Fragment>
					</Grid>
					<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
						<Button variant="contained" onClick={close} fullWidth sx={gridStyle.btn}>done</Button>
					</Grid>
					<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
						<Button variant="outlined" fullWidth sx={gridStyle.btn} onClick={close}>Cancel</Button>
					</Grid>
				</Grid>
			</Container>
			<React.Fragment>
				{
					editModal === true &&
					<EditRate
						open={editModal}
						close={() => setEditModal(false)}
						closeParent={close}
						customer={data}
						getAccounts={getAccounts}
					/>
				}
			</React.Fragment>
		</Modal>
	)
}

export default View;