import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import { Provider } from 'react-redux';
import store from './redux/store';
import axios from 'axios';


const theme = createTheme({
  palette: {
    primary: {
      main: '#F43232'
    },
    action: {
      disabled: '#F4323270',
      disabledBackground: '#F4323270',
    }
  },
  typography: {
    fontFamily: 'Lato, Segoe UI',
    fontSize: 14
  }
});

// axios.defaults.baseURL = 'https://apps-1.lampnets.com/nebo-backend-prod/';
axios.defaults.headers.post['Content-Type'] ='application/json';
axios.defaults.baseURL = 'https://apps-1.lampnets.com/nebo-backend-stage/';
// axios.defaults.baseURL = 'https://shielded-anchorage-14639.herokuapp.com/';
// axios.defaults.baseURL = 'http://localhost:5000/';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename='/sandbox'>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
);

