import React from 'react';
import { Modal, Paper, Button, Box, Grid, CircularProgress } from '@mui/material';
import { Header, TextInput } from '../../components/StyledComponents';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';

const modalStyle = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
};

const style = {
	boxShadow: 6,
	width: '100%',
	maxWidth: '750px',
	margin: '2rem',
}

const boxStyle = {
	padding: '2rem',
}

const NewAreaCode = ({ open, close }) => {
	let token = JSON.parse(sessionStorage.getItem('token'));
	const INIT = { areaCode: '', areaName: '' };
	const VALIDATE = Yup.object().shape({
		areaCode: Yup.number().required('Required'),
		areaName: Yup.string().required('Required')
	});

	return (
		<Modal open={open} onClose={close} sx={modalStyle}>
			<Paper sx={style}>
				<Box sx={boxStyle}>
					<Header>New Area Code</Header>
					<Box sx={{ height: '3vh' }} />
					<Formik
						initialValues={INIT}
						validationSchema={VALIDATE}
						validateOnChange={false}
						validateOnBlur={false}
						onSubmit={async (values, { setSubmitting }) => {
							const data = new FormData();
							data.append('area_code', values.areaCode);
							data.append('area_name', values.areaName);
							const config = {
								method: 'post',
								url: 'register/market_area',
								headers: { 
									'Authorization': 'Bearer ' + token
								},
								data : data
							};
							try {
								const res = await axios(config);
								if(res.status === 200) {
									toast.success("Area code created successfully");
									setSubmitting(false);
									close();
								}
							} catch (error) {
								console.log(error);
								toast.error(JSON.stringify(error.response.data));
								setSubmitting(false);
								close();
							}
						}}
					>
						{({ isSubmitting }) => {
							return (
								<Form>
									<Grid container columnSpacing={3} rowSpacing={4} alignItems="center">
										<Grid item xs={12}>
											<TextInput name="areaName" label="Area Name" />
										</Grid>
										<Grid item xs={12}>
											<TextInput name="areaCode" label="Area Code" />
										</Grid>
										<Grid item xs={12}>
											<Button
												disabled={isSubmitting} variant="contained" type="submit" fullWidth
												sx={{ height: '3.5rem' }}
											>
												{
													isSubmitting === true ?
													<CircularProgress sx={{ color: '#FFFFFF' }} /> :
													'Add area code'
												}
											</Button>
										</Grid>
									</Grid>
								</Form>
							)
						}}
					</Formik>
				</Box>
			</Paper>
		</Modal>
	)
}

export default NewAreaCode;