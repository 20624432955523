import React, { Fragment } from 'react';
import styled from 'styled-components';
import { PageTitle, IconWrapper, Title } from '../../components/StyledComponents';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import NewInvestment from './NewInvestment';
import NewCustomer from './NewCustomer';
import { getCustomers, getInvestments } from '../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';

const Wrapper = styled.div`
	width: 100%;
`;
const Item = styled.div`
	width: 100%;
	height: 200px;
	box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.32);
	border-radius: 0.125rem;
	padding: 0.75rem;
	background-color: #E6E6E690;
	overflow: hidden;
	cursor: pointer;
	transition: all 0.2s;
	&:hover {
		transform: scale(1.0125);
	}
`;
const Img = styled.img``;
const Qty = styled.div`
	display: flex;
`;
const Text = styled.p`
	font-size: ${props => props.size};
	font-weight: ${props => props.weight};
	margin: 1rem 0 0 0.5rem;
	line-height: 1rem;
`;

const card = {
	box: {
		backgroundColor: '#FFFFFF',
		height: '100%',
		padding: '0.75rem',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '1rem'
	},
	boxII: {
		backgroundColor: '#FFFFFF',
		height: '100%',
		padding: '0.75rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		gap: '1rem'
	},
	boxIIHeader: {
		fontSize: '1.25rem',
		fontWeight: 'bold',
		marginBottom: '0.5rem'
	},
	icon: {
		color: '#F43232',
		fontSize: '3rem'
	}
}

const Investment = () => {
	const [newInvestment, setNewInvestment] = React.useState(false);
	const [newInvestor, setNewInvestor] = React.useState(false);
	const [avgRate, setAvgRate] = React.useState(0);
	const token = JSON.parse(sessionStorage.getItem('token'));
	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(getCustomers(token));
		dispatch(getInvestments(token));
	}, [token])

	const { customers, investments } = useSelector(state => state.nebo);

	React.useEffect(() => {
		const rateArray = [];
		investments?.data?.forEach(item => {
			rateArray.push(Number(item.rate));
		});
		if(rateArray.length > 0) {
			const sum = rateArray.reduce((x, y) => x + y);
			const avg = Math.ceil(sum / rateArray.length);
			setAvgRate(avg);
		} else {
			setAvgRate(0);
		}
	}, [investments.data]);

	
	return (
		<Wrapper>
			<PageTitle>Investments</PageTitle>
			<Grid container spacing={4}>
				<Grid item xs={12} md={6} lg={4}>
					<Item>
						<Box sx={card.box}>
							<IconWrapper bg="#1890FF">
								<Img src="/main/assets/depo.png" />
							</IconWrapper>
							<Qty>
								<Title>
									{
										!investments?.data ? <CircularProgress /> : investments?.data?.length
									}
								</Title>
								<Text>New<br />Investment {investments?.data?.length > 1 && "s"}</Text>
							</Qty>
						</Box>
					</Item>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Item>
						<Box sx={card.box}>
							<IconWrapper bg="#47D024">
								<Img src="/main/assets/withdraw.png" />
							</IconWrapper>
							<Qty>
								<Title>{avgRate}%</Title>
								<Text>Current<br />ROI</Text>
							</Qty>
						</Box>
					</Item>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Item>
						<Box sx={card.box}>
							<IconWrapper bg="#FF0000">
								<Img src="/main/assets/transfer.png" />
							</IconWrapper>
							<Qty>
								<Title>
									{
										!customers?.data ? <CircularProgress /> : customers?.data?.length
									}
								</Title>
								<Text>Active<br />Accounts</Text>
							</Qty>
						</Box>
					</Item>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Item onClick={() => setNewInvestor(true)}>
						<Box sx={card.boxII}>
							<Box>
								<Typography sx={card.boxIIHeader}>Onboard Investors</Typography>
								<Typography>Add new Investor</Typography>
							</Box>
							<Box>
								<AddCircle sx={card.icon} />
							</Box>
						</Box>
					</Item>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Item onClick={() => setNewInvestment(true)}>
						<Box sx={card.boxII}>
							<Box>
								<Typography sx={card.boxIIHeader}>Add Investment</Typography>
								<Typography>Add new Investment</Typography>
							</Box>
							<Box>
								<AddCircle sx={card.icon} />
							</Box>
						</Box>
					</Item>
				</Grid>
			</Grid>
			<Fragment>
				{
					newInvestor === true &&
					<NewCustomer
						open={newInvestor}
						close={() => setNewInvestor(false)}
					/>
				}
			</Fragment>
			<Fragment>
				{
					newInvestment === true &&
					<NewInvestment
						open={newInvestment}
						close={() => setNewInvestment(false)}
					/>
				}
			</Fragment>
		</Wrapper>
	)
}

export default Investment;