import { Modal } from 'react-responsive-modal';
import { Container, Header } from '../../components/StyledComponents';
import { Grid, Typography, Button, CircularProgress } from '@mui/material';
import { TextInput, Dropdown } from '../../components/StyledComponents';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';

const FormWrapper = styled.div`
	margin: 2rem 0;
	padding-bottom: 1.5rem;
	.btn {
		height: 3.5rem;
	}
`;

const AddLoan = ({ open, close }) => {
	const init = {
		accountNumber: '', amount: '', tenure: '', interest: '', interval: '', loanType: ''
	}

	const validate = Yup.object().shape({
		accountNumber: Yup.number().required('Required'),
		amount: Yup.number().required('Required'),
		tenure: Yup.number().required('Required'),
		interest: Yup.number().required('Required'),
		interval: Yup.string().required('Required'),
		loanType: Yup.string().required('Required'),
	});

	const tenureOptions = [
		{ name: "Weeks", val: "weeks" },
		{ name: "Months", val: "months" },
	];

	const loanTypes = [
		{ name: "Assets Finance", val: "Assets Finance" },
		{ name: "Customer Business Loan", val: "Customer Business Loan" },
		{ name: "Loan Against Salary", val: "Loan Against Salary" },
		{ name: "Non-customer Business Loan", val: "Non-customer Business Loan" }
	]

	const token = sessionStorage.getItem('token');

	return (
		<Modal classNames={{ modal: 'customModal' }} open={open} onClose={close} center>
			<Container>
				<Header>New Loan</Header>
				<FormWrapper>
					<Formik
						initialValues={init}
						validationSchema={validate}
						validateOnBlur={false}
						validateOnChange={false}
						onSubmit={ async (values) => {
							const data = {
								amount: values.amount,
								tenure: values.tenure,
								period: values.interval,
								rate: values.interest,
								account_number: values.accountNumber,
								loan_type: values.loanType
							}
							const config = {
								method: 'post',
								url: 'loan/request',
								headers: {
									'Authorization': 'Bearer ' + JSON.parse(token)
								},
								data: data
							}
							try {
								const res = await axios(config);
								close();
								if(res.status === 200) {
									toast.success("Loan request submitted!");
								} else {
									toast.warn(res.response.data.message);
								}
							} catch (error) {
								console.log(error);
								toast.error(`${error.message}: ${error.response.data}`);
								close();
							}
						}}
					>
						{({ isSubmitting }) => {
							return (
								<Form>
									<Grid container spacing={4}>
										<Grid item xs={12}>
											<Typography variant="button">loan info</Typography>
										</Grid>
										<Grid item xs={12}>
											<TextInput name="accountNumber" label="Account Number" />
										</Grid>
										<Grid item xs={12}>
											<TextInput name="amount" label="Loan Amount" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput type="number" name="tenure" label="Loan tenure" />
										</Grid>
										<Grid item xs={12} md={6}>
											<Dropdown name="interval" options={tenureOptions} label="Period" />
										</Grid>
										<Grid item xs={12} md={6}>
											<Dropdown name="loanType" options={loanTypes} label="Loan Type" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="interest" label="Charge Rate" />
										</Grid>
										<Grid item xs={12} md={6}>
											<Button className='btn' fullWidth variant='contained' disabled={isSubmitting} type='submit'>
												{
													isSubmitting === true ? <CircularProgress sx={{ color: '#FFFFFF'}} /> : 'Request Loan'
												}
											</Button>
										</Grid>
										<Grid item xs={12} md={6}>
											<Button onClick={close} className='btn' fullWidth variant='outlined' disabled={isSubmitting} type='submit'>Cancel</Button>
										</Grid>
									</Grid>
								</Form>
							)
						}}
					</Formik>
				</FormWrapper>
			</Container>
		</Modal>
	)
}

export default AddLoan;
