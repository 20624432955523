import React from 'react';
import { PageTitle } from '../../components/StyledComponents';
import styled from 'styled-components';
import Cards from './DataComp/Cards';
import Table from './DataComp/Table';
import { transactions } from './DataComp/data';

const Container = styled.div``;

const Data = () => {
	const [data, setData] = React.useState([]);

	React.useEffect(() => {
		setData(transactions);
	}, []);

	return (
		<Container>
			<PageTitle>Data Table</PageTitle>
			<Cards />
			<Table data={data} />
		</Container>
	)
}

export default Data;