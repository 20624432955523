import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { Title, PageTitle, Loading } from '../../components/StyledComponents';
import SearchIcon from '@mui/icons-material/Search';
import { searchFunc } from '../../components/lib';
import { TextField, Grid, FormControl, InputLabel, InputAdornment, OutlinedInput, IconButton, Button, Typography } from '@mui/material';
import axios from 'axios';

const Container = styled.div`
	${Title} {
		font-size: 2.25rem;
		flex: 1;
	}
	a {
		text-decoration: none;
		color: inherit;
		&:hover {
			color: #C4C4C4;
		}
	}
`;
const Table = styled.div`
	margin: 2rem 0;
`;

const Collections = () => {
	const token = sessionStorage.getItem('token');
	const [text, setText] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [collections, setCollections] = useState([]);
	const handleChange = e => setText(e.target.value);

	const renderDate = (date) => {
		let newdate = new Date(date);
		return newdate.toLocaleDateString("en-GB") + " " + newdate.toLocaleTimeString("en-GB");
	}

	const cols = [
		{
			name: 'Date',
			selector: row => renderDate(row["Transaction date"]),
			sortable: true,
		},
		{
			name: 'Transaction ID',
			selector: row => row.transaction_id,
			sortable: true,
		},
		{
			name: 'Customer Name',
			selector: row => row.account_name,
			sortable: true,
		},
		{
			name: 'Amount',
			selector: row => Number(row.amount).toLocaleString(),
			sortable: true
		}
	];

	const getTransactions = async () => {
		setLoading(true);
		const config = {
			method: 'get',
			url: 'list/transactions',
			headers: { 
				'Authorization': 'Bearer ' + JSON.parse(token),
			}
		}

		try {
			const response = await axios(config);
			if(response.status === 200) {
				setLoading(false);
				let data = response.data;
				data = data.sort((a, b) => new Date(a["Transaction date"]) > new Date(b["Transaction date"]) ? -1 : 1);
				data = data.filter(item => item["Transaction Type"] === "Loan repayment");
				setCollections(data);
			}		
		} catch (error) {
			setLoading(false);
			setError(error);
			console.log(error);
		}
	}

	useEffect(() => {
		getTransactions();
	}, []);
	
	return (
		<Container>
			<PageTitle>Collections</PageTitle>
			<Grid container columnSpacing={4} sx={{ alignItems: "center", justifyContent: "space-between", marginY: '2rem' }}>
				<Grid item xs={12}>
					<FormControl sx={{ width: '100%', maxWidth: '400px'  }} variant="outlined">
						<InputLabel>Search</InputLabel>
						<OutlinedInput
							onChange={handleChange}
							value={text}
							endAdornment={
								<InputAdornment position="end">
									<IconButton edge="end">
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							}
							label="Search"
						/>
					</FormControl>
				</Grid>
			</Grid>
			<Table>
				{
					loading === true ? <Loading text="Loading data" /> :
					(
						error ? <Typography color="error">{error.response.data}: {error.message}</Typography> :
						collections &&
						<DataTable
							columns={cols}
							data={
								collections.filter(item => {
									if(text.trim() === "") {
										return item;
									} else if(item.account_name.toLowerCase().includes(text.toLowerCase())) {
										return item;
									} else if(item.account_number.toLowerCase().includes(text.toLowerCase())) {
										return item;
									} else if(item.transaction_id.toLowerCase().includes(text.toLowerCase())) {
										return item;
									}
								})
							}
							pagination paginationPerPage={20}
						/>
					)
				}
			</Table>
		</Container>
	)
}

export default Collections;