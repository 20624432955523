import React from 'react';
import { Modal } from 'react-responsive-modal';
import styled from 'styled-components';
import { Container, Header, TextInput, Dropdown, SelectMenu } from '../../components/StyledComponents';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { CircularProgress, Grid, Typography, Button } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { states } from '../../components/lib';
import { Country } from 'country-state-city';

const FormWrapper = styled.div`
	margin: 2rem 0;
	padding-bottom: 1.5rem;
	.mainForm {
		display: grid;
		grid-template-columns: 1fr 1fr;
		@media(max-width: 625px) {
			grid-template-columns: 1fr;
		}
	}
	.image-upload {
		border: 1px solid #C4C4C4;
		padding: 0 0.5rem;
		height: 2.5rem;
		margin-top: 1.5rem;
		label {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;
		}
		.img-input {
			display: none;
		}
	}
`;

const NewCustomer = ({ open, close }) => {
	
	const INIT = {
		firstName: '',
		otherName: '',
		lastName: '',
		phone: '',
		country: '',
		dob: '',
		homeAddress: '',
		businessAddress: '',
		rate: 1,
		balance: 0,
		cardNumber: '',
		contributionPlan: 'Flexible',
		accountNumber: '',
		contributionAmount: 0,
		areaCode: '',
		originState: 'state',
		gender: '',
		email: '',
		alias: ''
	}

	const VALIDATE = Yup.object().shape({
		firstName: Yup.string().trim().required('Required'),
		otherName: Yup.string().trim(),
		lastName: Yup.string().trim().required('Required'),
		phone: Yup.string().trim().required("Required"),
		country: Yup.string().trim().required("Required"),
		dob: Yup.string().trim(),
		homeAddress: Yup.string().trim().required("Required"),
		businessAddress: Yup.string().trim().required("Required"),
		rate: Yup.number().typeError("This field must be a non-zero number").required("Required"),
		balance: Yup.number().typeError("Balance must be a number"),
		cardNumber: Yup.number().typeError("This field must be a number").required('Required'),
		contributionPlan: Yup.string().trim().required("Required"),
		accountNumber: Yup.number().typeError("This field must be a number"),
		contributionAmount: Yup.number().typeError("This field must be a number"),
		areaCode: Yup.number().typeError("This field must be a number").required("Required"),
		originState: Yup.string().trim().required("Required"),
		gender: Yup.string().trim(),
		email: Yup.string().email('Required'),
		alias: Yup.string(),
	});

	let token = sessionStorage.getItem('token');
	
	const plans = [
		{ name: 'Select Plan', val: '' },
		{ name: 'Daily', val: 'daily' },
		{ name: 'Weekly', val: 'weekly' },
		{ name: 'Monthly', val: 'monthly' },
		{ name: 'Flexible', val: 'flexible' },
	];

	const gender = [
		{ name: 'Female', val: 'F' },
		{ name: 'Male', val: 'M' }
	];

	const [areas, setAreas] = React.useState(null);
	const getMarketAreas = async () => {
		const config = {
			method: 'get',
			url: "list/market_area",
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			}
		}
	
		try {
			const res = await axios(config);
			if(res.status === 200) {
					let { data } = res;
					data = data.sort((a, b) => a.area_name.localeCompare(b.area_name));
					data = data.map((item, index) => {
						return {
							name: item.area_name + " (" + item.area_code + ")" ,
							id: item.area_code,
							val: item.area_code
						}
					})
					setAreas(data);
				}
			} catch (error) {
				console.log(error);
			}
	}

	React.useEffect(() => {
		getMarketAreas();
		// eslint-disable-next-line
	}, []);

	const countries = Country.getAllCountries().map(item => {
		return {
			name: item.name,
			val: item.name,
		}
	});

	return (
		<Modal classNames={{ modal: 'customModal' }} open={open} onClose={close} center>
			<Container>
				<Header>New Customer</Header>
				<FormWrapper>
					<Formik
						initialValues={INIT}
						validationSchema={VALIDATE}
						validateOnBlur={false}
						validateOnChange={false}
						onSubmit={async (values) => {
							const data = new FormData();
							data.append('country', values.country);
							data.append('date_of_birth', values.dob);
							data.append('home_address', values.homeAddress);
							data.append('business_address', values.businessAddress);
							data.append('rate', values.rate);
							data.append('balance', values.balance);
							data.append('card_number', values.cardNumber);
							data.append('contribution plan', values.contributionPlan);
							data.append('existing_account_number', values.accountNumber);
							data.append('contribution_amount', values.contributionAmount);
							data.append('area_code', values.areaCode);
							data.append('state', values.originState);
							data.append('firstname', values.firstName);
							data.append('lastname', values.lastName);
							data.append('middlename', values.otherName);
							data.append('phone_no', values.phone);
							data.append('gender', values.gender);
							data.append('alias', values.alias);
							data.append('personal_email', values.email);

							const config = {
								method: 'post',
								url: 'register/customer',
								headers: {
									'Authorization': 'Bearer ' + JSON.parse(token)
								},
								data: data
							}

							try {
								const res = await axios(config);
								if(res.status === 200) {
									toast.success("Customer added successfully!");
									window.location.reload(false);
									// close();
								} else {
									toast.warn(res.data.message);
								}
							} catch (error) {
								console.log(error);
								toast.error(error.message);
								// close();
							}
						}}
					>
						{({ values, isSubmitting }) => {
							return (
								<Form>
									<Grid container spacing={4} alignItems="center">
										<Grid item xs={12}>
											<Typography variant="button">Personal information</Typography>
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="firstName" label="First Name" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="otherName" label="Middle Name" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="lastName" label="Last Name" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="phone" label="Phone Number" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="email" label="Email Address" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="dob" label="Date of Birth" type="date" InputLabelProps={{ shrink: true }} />
										</Grid>
										<Grid item xs={12} md={6}>
											<SelectMenu name="gender" label="Gender" options={gender} />
										</Grid>
										<Grid item xs={12} md={6}>
											<Dropdown name="country" options={countries} label="Country" />
										</Grid>
										<React.Fragment>
											{
												values.country === "Nigeria" &&
												<Grid item xs={12} md={6}>
													<Dropdown name="originState" options={states} label="State" />
												</Grid>
											}
										</React.Fragment>
										<Grid item xs={12}>
											<TextInput name="homeAddress" label="Home Address" multiline rows={2} />
										</Grid>
										<Grid item xs={12}>
											<TextInput name="businessAddress" label="Business Address" multiline rows={2} />
										</Grid>
										<Grid item xs={12}>
											<Typography variant="button">account information</Typography>
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="alias" label="Account Alias" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="cardNumber" label="Card Number" />
										</Grid>
										<Grid item xs={12} md={6}>
											{
												areas && <SelectMenu name="areaCode" label="Market Area" options={areas} />
											}
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="accountNumber" label="Existing Account Number" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="rate" label="Existing Rate" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="balance" label="Existing Balance" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="contributionAmount" label="Contribution Amount" />
										</Grid>
										<Grid item xs={12} md={6}>
											<Dropdown name="contributionPlan" label="Contribution Plan" options={plans} />
										</Grid>
										<Grid item xs={12}>
											<Button
												 variant="contained" type="submit" fullWidth
												sx={{ height: '3.5rem' }}
												disabled={isSubmitting}
											>
												{
													isSubmitting === true ?
													<CircularProgress sx={{ color: '#FFFFFF' }} /> :
													'Add customer'
												}
											</Button>
										</Grid>
									</Grid>
								</Form>
							)
						}}
					</Formik>
				</FormWrapper>
			</Container>
		</Modal>
	)
}

export default NewCustomer;