import React, { Fragment, useEffect, useState } from 'react';
import { Container, Header, Loading, Button } from '../../components/StyledComponents';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DataTable from 'react-data-table-component';
import { Button as MuiButton, Typography, Box as MuiBox } from '@mui/material';
import NewInvestment from './NewInvestment';
import axios from 'axios';

const Box = styled.div`
	border: 2px solid #C8613A;
	margin: 2rem 0;
	padding: 1rem;
	display: flex;
	@media(max-width: 768px) {
		flex-direction: column;
		align-items: center;
	}
`;
const Picture = styled.div`
	width: 100%;
	max-width: 11.5rem;
	height: 12rem;
	border: 1px solid #C4C4C4;
	border-radius: 0.25rem;
	padding: 0.25rem;
	img {
		width: 100%;
		height: 100%;
		border-radius: 0.25rem;
	}
`;
const Info = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1rem;
	width: 100%;
	@media(max-width: 768px) {
		grid-template-columns: 1fr;
		br {
			display: none;
		}
	}
`;
const InfoItem = styled.div`
	padding: 0 1rem;
	@media(max-width: 768px) {
		padding: 0;
		text-align: center;
	}
`;
const Item = styled.div`
	margin: 0.5rem;
`;
const Text = styled.p`
	font-weight: ${props => props.weight};
	font-size: 1.25rem;
`;
const HeaderRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 1rem 0;
	${Button} {
		width: 10rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
const Summary = styled.div``;

const Investor = () => {
	const userId = useParams().id;
	const token = sessionStorage.getItem('token');
	const [investor, setInvestor] = useState(null);
	let [investors, setInvestors] = useState(null);
	const [newInvestmentModal, setNewInvestmentModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const getInvestor = async cId => {
		setLoading(true);
		const data = {
			customer_id: cId
		}
		const config = {
			method: 'post',
			url: 'list/customer',
			headers: { 
				'Authorization': 'Bearer ' + JSON.parse(token),
			},
			data: data
		}

		try {
			const response = await axios(config);
			if(response.status === 200) {
				setLoading(false);
				let data = response.data[0];
				setInvestor(data);
			}		
		} catch (error) {
			setLoading(false);
			setError(error);
			console.log(error);
		}
	}

	const getInvestments = async () => {
		const config = {
			method: 'get',
			url: 'investment/all',
			headers: { 
				'Authorization': 'Bearer ' + JSON.parse(token),
			}
		}

		try {
			const response = await axios(config);
			if(response.status === 200) {
				let data = response.data.Investments.filter(item => item.customer_id === Number(userId));
				setInvestors(data);
			}		
		} catch (error) {
			console.log(error);
		}
	};

	const renderDate = (date) => {
		let newdate = new Date(date);
		return newdate.toLocaleDateString("en-GB") + " " + newdate.toLocaleTimeString("en-GB");
	};

	useEffect(() => {
		getInvestor(userId);
		return () => null;
	}, [userId]);

	useEffect(() => {
		getInvestments();
	}, []);

	const cols = [
		{
			name: 'Request Date',
			selector: row => renderDate(row["Request Date"]),
			sortable: true,
			minWidth: '11rem'
		},
		{
			name: 'Approval Date',
			selector: row => row["Approval Date"] !== null && renderDate(row["Approval Date"]),
			sortable: true,
			minWidth: '11rem'
		},
		{
			name: 'Tenure',
			selector: row => <>{ row.tenure + " " + row.period }</>,
			sortable: true,
			minWidth: '7rem'
		},
		{
			name: 'Amount Invested',
			selector: row => Number(row.amount_offered).toLocaleString(),
			sortable: true,
			minWidth: '11rem'
		},
		{
			name: 'Total Returns',
			selector: row => Number(row.total_returns).toLocaleString(),
			sortable: true,
			minWidth: '10rem'
		},
		{
			name: 'Returns Paid',
			selector: row => row["Approval Date"] && Number(row.returns_paid).toLocaleString(),
			sortable: true,
			minWidth: '9rem'
		},
		{
			name: 'Balance',
			selector: row => row["Approval Date"] && Number(row.returns_unpaid).toLocaleString(),
			sortable: true,
			minWidth: '7rem'
		},
		{
			name: 'Status',
			selector: row => row["Approval Date"] ? <span style={{ color: 'green' }}>Running</span> : <span style={{ color: 'orange' }}>Pending</span>,
			sortable: true
		}
	];

	return (
		<Container style={{ margin: 0, padding: '1rem' }}>
			<Header>Investor Information</Header>
			<React.Fragment>
				{
					loading === true ?
					<MuiBox sx={{ height: '40vh', display: 'grid', placeItems: 'center' }}>
						<Loading text="Please wait" />
					</MuiBox>
					: (
						error ? <Typography color="error">{error.message}</Typography> :
						investor &&
						<>
							<Box>
								<Picture>
									<img src="https://www.leafstudio.co.uk/wp-content/uploads/2019/11/person-icon-silhouette-png-0.png" alt="" />
								</Picture>
								<Info>
									<InfoItem>
										<Item>
											<Text style={{ fontWeight: '700' }}>{investor.account_name}</Text>
										</Item>
										<Item>
											<Text>Account number: <br />{investor.account_number}</Text>
										</Item>
										<Item>
											<Text>Card number: <br />{investor.card_number}</Text>
										</Item>
									</InfoItem>
									<InfoItem>
										<Item><Text>Home address: {investor.home_address}</Text></Item>
										<Item><Text>Business address: {investor.business_address}</Text></Item>
										<Item><Text>Area code:</Text></Item>
										<Item><Text>State: {investor.state}</Text></Item>
									</InfoItem>
								</Info>
							</Box>
							<Summary>
								<HeaderRow>
									<Text>Recent Investments</Text>
									<MuiButton variant="outlined" sx={{ height: '3.75rem' }} onClick={() => setNewInvestmentModal(true)}>
										<AddCircleIcon sx={{ marginX: '0.25rem'}} />
										<span>Add investment</span>
									</MuiButton>
								</HeaderRow>
								<React.Fragment>
									{
										investors && investors.length > 0 ?
										<DataTable
											columns={cols}
											data={investors}
										/>
										: <Text>No investments</Text>
									}
								</React.Fragment>
							</Summary>
						</>
					)
				}
			</React.Fragment>
			<Fragment>
				{
					newInvestmentModal === true &&
					<NewInvestment open={newInvestmentModal} close={() => setNewInvestmentModal(false)} />
				}
			</Fragment>
		</Container>
	)
}

export default Investor;