import React from 'react';
import { Box, Grid, Typography, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';
import styled from 'styled-components';
import axios from 'axios';
import { useEffect } from 'react';
import dayjs from 'dayjs';

const Item = styled.div`
	width: 100%;
	height: 200px;
	box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.32);
	border-radius: 0.25rem;
	padding: 0.75rem;
	background-color: #E6E6E690;
	overflow: hidden;
	&:hover {
		background-color: #F43232;
		* {
			/* background-color: #F43232; */
			/* color: #ffffff; */
		}
	}
`;

const card = {
	box: {
		backgroundColor: '#FFFFFF',
		height: '100%',
		padding: '0.75rem',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		gap: '1rem'
	},
	top: {
		display: 'flex',
		alignItems: 'center'
	},
	title: {
		width: '100%',
		fontWeight: 'bold'
	},
	amount: {
		textAlign: 'center',
		fontSize: '2rem',
		fontWeight: '600'
	}
}

const Cards = () => {
	const token = sessionStorage.getItem('token');
	const [loading, setLoading] = React.useState(false);
	const [data, setData] = React.useState({
		transactions: [], deposit: [], withdrawals: [], transfers: [], pendingTransfers: [], loans: []
	});
	const [tempData, setTempData] = React.useState({
		transactions: [], deposit: [], withdrawals: [], transfers: [], pendingTransfers: [], loans: []
	});
	const [input, setInput] = React.useState({
		transactions: '', deposit: '', withdrawals: '', transfers: '', pendingTransfers: '', loans: ''
	});
	const [totals, setTotals] = React.useState({
		all: 0, deposit: 0, withdrawals: 0, transfers: 0, pendingTransfers: 0, loans: 0
	});
	const [error, setError] = React.useState(null);

	const getTransactions = async () => {
		setLoading(true);
		const config = {
			method: 'get',
			url: 'list/transactions',
			headers: { 
				'Authorization': 'Bearer ' + JSON.parse(token),
			}
		}
		const configII = {
			method: 'get',
			url: "transaction/transfers",
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			}
		}
		const configIII = {
			method: 'get',
			url: "loan/all",
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			}
		}

		try {
			const response = await axios(config);
			const responseII = await axios(configII);
			const responseIII = await axios(configIII);
			if(response.status === 200) {
				setLoading(false);
				let res = response.data;
				let resII = responseII.data;
				let resIII = responseIII.data;
				setData({
					...data,
					transactions: res,
					deposit: res.filter(item => item["Transaction Type"] === 'Deposit'),
					withdrawals: res.filter(item => item["Transaction Type"] === 'Withdrawal'),
					transfers: res.filter(item => item["Transaction Type"] === 'Transfer'),
					pendingTransfers: resII.filter(item => item.status === "pending"),
					loans: resIII
				});
				setTempData({
					...tempData,
					transactions: res,
					deposit: res.filter(item => item["Transaction Type"] === 'Deposit'),
					withdrawals: res.filter(item => item["Transaction Type"] === 'Withdrawal'),
					transfers: res.filter(item => item["Transaction Type"] === 'Transfer'),
					pendingTransfers: resII.filter(item => item.status === "pending"),
					loans: resIII
				});
			}		
		} catch (error) {
			setLoading(false);
			setError(error);
			console.log(error);
		}
	}

	useEffect(() => {
		getTransactions();
	}, []);

	useEffect(() => {
		let total = 0;
		let depositTotal = 0;
		let withdrawalTotal = 0;
		let transferTotal = 0;
		let pendingTransfersTotal = 0;
		let loansTotal = 0;
		data.transactions.forEach(item => {
			total += Number(item.amount);
		});
		data.deposit.forEach(item => {
			depositTotal += Number(item.amount);
		});
		data.withdrawals.forEach(item => {
			withdrawalTotal += Number(item.amount);
		});
		data.transfers.forEach(item => {
			transferTotal += Number(item.amount);
		});
		data.pendingTransfers.forEach(item => {
			pendingTransfersTotal += Number(item.amount);
		});
		data.loans.forEach(item => {
			loansTotal += Number(item.amount_requested);
		});
		setTotals({ ...totals, all: total, deposit: depositTotal, withdrawals: withdrawalTotal, transfers: transferTotal, pendingTransfers: pendingTransfersTotal, loans: loansTotal });
	}, [data.transactions, data.deposit, data.loans, data.pendingTransfers, data.transfers, data.withdrawals]);

	let dayStart = dayjs().startOf('d').$d;
	let weekStart = dayjs().startOf('w').$d;
	let monthStart = dayjs().startOf('M').$d;
	const transactionsChange = (prop) => (e) => {
    let { value } = e.target;
		setInput({ ...input, [prop]: value });
		let dayData = tempData.transactions.filter(item => Date.parse(dayStart) <= Date.parse(item["Transaction date"]));
		let weekData = tempData.transactions.filter(item => Date.parse(weekStart) <= Date.parse(item["Transaction date"]));
		let monthData = tempData.transactions.filter(item => Date.parse(monthStart) <= Date.parse(item["Transaction date"]));
		if (value === "d") {
			setData({ ...data, transactions: dayData });
		} else if (value === "w") {
			setData({ ...data, transactions: weekData });
		} else if(value === "M") {
			setData({ ...data, transactions: monthData });
		} else if (value === "" ) {
			setData({ ...data, transactions: tempData.transactions });
		}
  };
	const depositChange = (prop) => (e) => {
    let { value } = e.target;
		setInput({ ...input, [prop]: value });
		let dayData = tempData.deposit.filter(item => Date.parse(dayStart) <= Date.parse(item["Transaction date"]));
		let weekData = tempData.deposit.filter(item => Date.parse(weekStart) <= Date.parse(item["Transaction date"]));
		let monthData = tempData.deposit.filter(item => Date.parse(monthStart) <= Date.parse(item["Transaction date"]));
		if (value === "d") {
			setData({ ...data, deposit: dayData });
		} else if (value === "w") {
			setData({ ...data, deposit: weekData });
		} else if(value === "M") {
			setData({ ...data, deposit: monthData });
		} else if (value === "" ) {
			setData({ ...data, deposit: tempData.deposit });
		}
  };
	const withdrawalChange = (prop) => (e) => {
    let { value } = e.target;
		setInput({ ...input, [prop]: value });
		let dayData = tempData.withdrawals.filter(item => Date.parse(dayStart) <= Date.parse(item["Transaction date"]));
		let weekData = tempData.withdrawals.filter(item => Date.parse(weekStart) <= Date.parse(item["Transaction date"]));
		let monthData = tempData.withdrawals.filter(item => Date.parse(monthStart) <= Date.parse(item["Transaction date"]));
		if (value === "d") {
			setData({ ...data, withdrawals: dayData });
		} else if (value === "w") {
			setData({ ...data, withdrawals: weekData });
		} else if(value === "M") {
			setData({ ...data, withdrawals: monthData });
		} else if (value === "" ) {
			setData({ ...data, withdrawals: tempData.withdrawals });
		}
  };
	const transfersChange = (prop) => (e) => {
    let { value } = e.target;
		setInput({ ...input, [prop]: value });
		let dayData = tempData.transfers.filter(item => Date.parse(dayStart) <= Date.parse(item["Transaction date"]));
		let weekData = tempData.transfers.filter(item => Date.parse(weekStart) <= Date.parse(item["Transaction date"]));
		let monthData = tempData.transfers.filter(item => Date.parse(monthStart) <= Date.parse(item["Transaction date"]));
		if (value === "d") {
			setData({ ...data, transfers: dayData });
		} else if (value === "w") {
			setData({ ...data, transfers: weekData });
		} else if(value === "M") {
			setData({ ...data, transfers: monthData });
		} else if (value === "" ) {
			setData({ ...data, transfers: tempData.transfers });
		}
  };
	const transferRequest = (prop) => (e) => {
    let { value } = e.target;
		setInput({ ...input, [prop]: value });
		let dayData = tempData.pendingTransfers.filter(item => Date.parse(dayStart) <= Date.parse(item["Transaction date"]));
		let weekData = tempData.pendingTransfers.filter(item => Date.parse(weekStart) <= Date.parse(item["Transaction date"]));
		let monthData = tempData.pendingTransfers.filter(item => Date.parse(monthStart) <= Date.parse(item["Transaction date"]));
		if (value === "d") {
			setData({ ...data, pendingTransfers: dayData });
		} else if (value === "w") {
			setData({ ...data, pendingTransfers: weekData });
		} else if(value === "M") {
			setData({ ...data, pendingTransfers: monthData });
		} else if (value === "" ) {
			setData({ ...data, pendingTransfers: tempData.pendingTransfers });
		}
  };
	const loanChange = (prop) => (e) => {
    let { value } = e.target;
		setInput({ ...input, [prop]: value });
		let dayData = tempData.loans.filter(item => Date.parse(dayStart) <= Date.parse(item["Request Date"]));
		let weekData = tempData.loans.filter(item => Date.parse(weekStart) <= Date.parse(item["Request Date"]));
		let monthData = tempData.loans.filter(item => Date.parse(monthStart) <= Date.parse(item["Request Date"]));
		if (value === "d") {
			setData({ ...data, loans: dayData });
		} else if (value === "w") {
			setData({ ...data, loans: weekData });
		} else if(value === "M") {
			setData({ ...data, loans: monthData });
		} else if (value === "" ) {
			setData({ ...data, loans: tempData.loans });
		}
  };

	return (
		<Grid container spacing={4}>
				<Grid item xs={12} md={6} lg={4}>
					<Item>
						<Box sx={card.box}>
							<Box sx={card.top}>
								<Typography sx={card.title}>Total Amount</Typography>
								<FormControl fullWidth>
									<InputLabel>Filter</InputLabel>
									<Select label="Filter" name="transactions" onChange={transactionsChange("transactions")} value={input.transactions}>
										<MenuItem value=''>Select</MenuItem>
										<MenuItem value='d'>Today</MenuItem>
										<MenuItem value='w'>This week</MenuItem>
										<MenuItem value='M'>This month</MenuItem>
									</Select>
								</FormControl>
							</Box>
							<Typography sx={card.amount}>
								{
									loading === true ? <CircularProgress /> :
									(
										error ? <Typography color="error" sx={{ textAlign: 'center' }}>{error.response.data}: {error.message}</Typography> :
										totals.all.toLocaleString()
									)
								}
							</Typography>
						</Box>
					</Item>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Item>
						<Box sx={card.box}>
							<Box sx={card.top}>
								<Typography sx={card.title}>Total Deposit</Typography>
								<FormControl fullWidth>
									<InputLabel>Filter</InputLabel>
									<Select label="Filter" name="deposit" onChange={depositChange("deposit")} value={input.deposit}>
										<MenuItem value=''>Select</MenuItem>
										<MenuItem value='d'>Today</MenuItem>
										<MenuItem value='w'>This week</MenuItem>
										<MenuItem value='M'>This month</MenuItem>
									</Select>
								</FormControl>
							</Box>
							<Typography sx={card.amount}>
								{
									loading === true ? <CircularProgress /> :
									(
										error ? <Typography color="error" sx={{ textAlign: 'center' }}>{error.response.data}: {error.message}</Typography> :
										totals.deposit.toLocaleString()
									)
								}
							</Typography>
						</Box>
					</Item>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Item>
						<Box sx={card.box}>
							<Box sx={card.top}>
								<Typography sx={card.title}>Total Withdrawal</Typography>
								<FormControl fullWidth>
									<InputLabel>Filter</InputLabel>
									<Select label="Filter" name="withdrawals" onChange={withdrawalChange("withdrawals")} value={input.withdrawals}>
										<MenuItem value=''>Select</MenuItem>
										<MenuItem value='d'>Today</MenuItem>
										<MenuItem value='w'>This week</MenuItem>
										<MenuItem value='M'>This month</MenuItem>
									</Select>
								</FormControl>
							</Box>
							<Typography sx={card.amount}>
								{
									loading === true ? <CircularProgress /> :
									(
										error ? <Typography color="error" sx={{ textAlign: 'center' }}>{error.response.data}: {error.message}</Typography> :
										totals.withdrawals.toLocaleString()
									)
								}
							</Typography>
						</Box>
					</Item>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Item>
						<Box sx={card.box}>
							<Box sx={card.top}>
								<Typography sx={card.title}>Total Transfers</Typography>
								<FormControl fullWidth>
									<InputLabel>Filter</InputLabel>
									<Select label="Filter" name="transfers" onChange={transfersChange("transfers")} value={input.transfers}>
										<MenuItem value=''>Select</MenuItem>
										<MenuItem value='d'>Today</MenuItem>
										<MenuItem value='w'>This week</MenuItem>
										<MenuItem value='M'>This month</MenuItem>
									</Select>
								</FormControl>
							</Box>
							<Typography sx={card.amount}>
								{
									loading === true ? <CircularProgress /> :
									(
										error ? <Typography color="error" sx={{ textAlign: 'center' }}>{error.response.data}: {error.message}</Typography> :
										totals.transfers.toLocaleString()
									)
								}
							</Typography>
						</Box>
					</Item>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Item>
						<Box sx={card.box}>
							<Box sx={card.top}>
								<Typography sx={card.title}>Transfer Requests</Typography>
								<FormControl fullWidth>
									<InputLabel>Filter</InputLabel>
									<Select label="Filter" name="pendingTransfers" onChange={transferRequest("pendingTransfers")} value={input.pendingTransfers}>
										<MenuItem value=''>Select</MenuItem>
										<MenuItem value='d'>Today</MenuItem>
										<MenuItem value='w'>This week</MenuItem>
										<MenuItem value='M'>This month</MenuItem>
									</Select>
								</FormControl>
							</Box>
							<Typography sx={card.amount}>
								{
									loading === true ? <CircularProgress /> :
									(
										error ? <Typography color="error" sx={{ textAlign: 'center' }}>{error.response.data}: {error.message}</Typography> :
										totals.pendingTransfers.toLocaleString()
									)
								}
							</Typography>
						</Box>
					</Item>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Item>
						<Box sx={card.box}>
							<Box sx={card.top}>
								<Typography sx={card.title}>Total Loans</Typography>
								<FormControl fullWidth>
									<InputLabel>Filter</InputLabel>
									<Select label="Filter" name="loans" onChange={loanChange("loans")} value={input.loans}>
										<MenuItem value=''>Select</MenuItem>
										<MenuItem value='d'>Today</MenuItem>
										<MenuItem value='w'>This week</MenuItem>
										<MenuItem value='M'>This month</MenuItem>
									</Select>
								</FormControl>
							</Box>
							<Typography sx={card.amount}>
								{
									loading === true ? <CircularProgress /> : 
									( error ? <Typography color="error" sx={{ textAlign: 'center' }}>{error.response.data}: {error.message}</Typography> : totals.loans.toLocaleString())
								}
							</Typography>
						</Box>
					</Item>
				</Grid>
			</Grid>
	)
}

export default Cards;