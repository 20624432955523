import React from 'react';
import { Modal } from 'react-responsive-modal';
import { Container, Header } from '../../components/StyledComponents';
import { Grid, Box, Typography, Button, CircularProgress } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextInput, Dropdown } from '../../components/StyledComponents';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import axios from 'axios';

const FormWrapper = styled.div`
	margin: 2rem 0;
	padding-bottom: 1.5rem;
`;

const Deposit = ({ depositOpen, close }) => {
	const INIT = {
		accountNumber: '',
		cardNumber: '',
		amount: '',
		paymentMode: ''
	};

	const VALIDATE = Yup.object().shape({
		accountNumber: Yup.number().required('Required'),
		cardNumber: Yup.number().required('Required'),
		amount: Yup.number().required('Required'),
		paymentMode: Yup.string().required('Required'),
	});

	const options = [
		{ id: 3, name: 'Card', val: 'Card' },
		{ id: 1, name: 'Cash', val: 'Cash' },
		{ id: 2, name: 'Transfer', val: 'Transfer' },
	];

	const token = sessionStorage.getItem('token');

	const [name, setName] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const validate = async (acct) => {
		setLoading(true);
		const data = {
			account_number: acct
		}
		const config = {
			method: 'post',
			url: 'transaction/confirm',
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			},
			data: data
		}
		try {
			const res = await axios(config);
			if(res.status === 200) {
				setLoading(false);
				setName(res.data.account_name);
			} else {
				toast.warn(res.data.message);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
			toast.error(error.message);
		}
	};

	return (
		<Modal classNames={{ modal: 'customModal' }} open={depositOpen} onClose={close} center>
			<Container>
				<Header>Deposit</Header>
				<FormWrapper>
					<Formik
						initialValues={INIT}
						validationSchema={VALIDATE}
						validateOnBlur={false}
						validateOnChange={false}
						onSubmit={ async (values) => {
							const data = {
								account_number: values.accountNumber,
								card_number: values.cardNumber,
								amount: values.amount,
								mode_of_payment: values.paymentMode
							}
							const config = {
								method: 'post',
								url: 'transaction/deposit',
								headers: {
									'Authorization': 'Bearer ' + JSON.parse(token)
								},
								data: data
							}
							try {
								const res = await axios(config);
								close();
								if(res.status === 200) {
									toast.success("Deposit Successful!");
								} else {
									toast.warn(res.data.message);
								}
							} catch (error) {
								console.log(error);
								toast.error(error.message);
								close();
							}
						}}
					>
						{({ values, isSubmitting }) => {
							let { accountNumber } = values;
							return (
								<Form>
									<Grid container columnSpacing={3} rowSpacing={4} alignItems="center">
										<Grid item xs={12}>
											<Typography variant="button">Transaction Details</Typography>
										</Grid>
										<Grid item xs={12}>
											<Box sx={{ display: 'flex', gap: '0.5rem' }}>
												<TextInput name="accountNumber" label="Account Number" />
												<Button disabled={loading || accountNumber.length < 8} onClick={() => validate(accountNumber)} type="button" variant='contained' sx={{ width: '10rem', height: '3.625rem' }}>
													{
														loading === true ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : 'Validate'
													}
												</Button>
											</Box>
											<React.Fragment>
												{
													name &&
													<Typography sx={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, 0.54)', marginLeft: '0.75rem' }}>{name}</Typography>
												}
											</React.Fragment>
										</Grid>
										<Grid item xs={12}>
											<TextInput name="cardNumber" label="Card Number" />
										</Grid>
										<Grid item xs={12}>
											<TextInput name="amount" label="Amount" />
										</Grid>
										<Grid item xs={12}>
											<Dropdown name="paymentMode" label="Payment Mode" options={options} />
										</Grid>
										<Grid item xs={12}>
											<Button disabled={isSubmitting} type="submit" variant="contained" fullWidth sx={{ height: '3.5rem' }}>
												{ isSubmitting === true ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : 'Deposit' }
											</Button>
										</Grid>
									</Grid>
								</Form>
							)
						}}
					</Formik>
				</FormWrapper>
			</Container>
		</Modal>
	)
}

export default Deposit;