import React, { useEffect, useState } from 'react';
import { Container, Header, Loading, Button } from '../../components/StyledComponents';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { borrowers } from './data';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DataTable from 'react-data-table-component';
import AddLoan from './AddLoan';
import { CircularProgress, Typography } from '@mui/material';

const Box = styled.div`
	border: 2px solid #C8613A;
	margin: 2rem 0;
	padding: 1rem;
	display: flex;
`;
const Picture = styled.div`
	width: 100%;
	max-width: 11.5rem;
	height: 12rem;
	background: #C4C4C4;
`;
const Info = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1rem;
	width: 100%;
`;
const InfoItem = styled.div`
	padding: 0 1rem;
`;
const Item = styled.div`
	margin: 0.5rem;
`;
const Text = styled.p`
	font-weight: ${props => props.weight};
	font-size: 1.25rem;
`;
const HeaderRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	${Button} {
		width: 10rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
const Summary = styled.div``;

const BorrowerInfo = () => {
	const userId = useParams().id;
	const [userProfile, setUserProfile] = useState(null);
	const [newLoan, setNewLoan] = useState(false);
	const getUser = id => {
		let res = borrowers.find(item => item.id === Number(id));
		setUserProfile(res);
	}
	useEffect(() => {
		getUser(userId);
		return () => null;
	}, [userId]);
	
	if(!userProfile) {
		return <Loading text={'Loading user data...'} />
	}

	const { name, account, loans } = userProfile;

	const cols = [
		{
			name: 'Loan Type',
			selector: row => row.loanType,
			sortable: true
		},
		{
			name: 'Date Released',
			selector: row => row.date,
			sortable: true
		},
		{
			name: 'Amount',
			selector: row => <>N {row.loanAmount.toLocaleString()}</>,
			sortable: true
		},
		{
			name: 'Repayment Plan',
			selector: row => row.paymentPlan,
			sortable: true
		},
		{
			name: 'Status',
			selector: row => row.status,
			sortable: true
		}
	];

	return (
		<Container style={{ margin: 0, padding: '1rem' }}>
			<Header>Customer Information</Header>
			<Box>
				<Picture />
				<Info>
					<InfoItem>
						<Item>
							<Text>{name}</Text>
						</Item>
						<Item>
							<Text>Account number: <br />{account}</Text>
						</Item>
						<Item>
							<Text>Card number: <br />4783</Text>
						</Item>
					</InfoItem>
					<InfoItem>
						<Item><Text>Home address:</Text></Item>
						<Item><Text>Business address:</Text></Item>
						<Item><Text>Area code:</Text></Item>
						<Item><Text>State: Oyo</Text></Item>
					</InfoItem>
				</Info>
			</Box>
			<Summary>
				<HeaderRow>
					<Text>Loans Summary</Text>
					<Button onClick={() => setNewLoan(true)}>
						<AddCircleIcon sx={{ marginX: '0.25rem'}} />
						<span>New Loan</span>
					</Button>
				</HeaderRow>
				{
					userProfile === null ?
					<CircularProgress size="5rem" /> :
					<DataTable
						columns={cols}
						data={userProfile.loans}
						pagination paginationPerPage={10}
						noDataComponent={<Typography>No loan data found</Typography>}
					/>
				}
			</Summary>
			<div>
				{
					newLoan === true &&
					<AddLoan open={newLoan} close={() => setNewLoan(false)} />
				}
			</div>
		</Container>
	)
}

export default BorrowerInfo;