import { Modal } from 'react-responsive-modal';
import styled from 'styled-components';
import { Container, Header, TextInput, Dropdown } from '../../components/StyledComponents';
import { Grid, Typography, Button, CircularProgress } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';

const FormWrapper = styled.div`
	margin: 2rem 0;
	padding-bottom: 1.5rem;
`;


const Transfer = ({ open, close }) => {
	const banks = [
        { id: 1, name: 'Access Bank', val: 'access' },
        { id: 2, name: 'Citibank', val: 'citibank' },
        { id: 3, name: 'Ecobank', val: 'ecobank' },
        { id: 4, name: 'Fidelity Bank', val: 'fidelity' },
        { id: 5, name: 'First Bank', val: 'first bank' },
        { id: 6, name: 'First City Monument Bank', val: 'fcmb' },
        { id: 7, name: 'Globus Bank', val: 'globus' },
        { id: 8, name: 'Guaranty Trust Bank', val: 'gtb' },
        { id: 9, name: 'Heritage Bank', val: 'heritage' },
        { id: 10, name: 'Jaiz Bank', val: 'jaiz' },
        { id: 11, name: 'Key Stone Bank', val: 'keystone' },
        { id: 12, name: 'Kuda', val: 'kuda' },
        { id: 13, name: 'Parallex Bank', val: 'parallex' },
        { id: 14, name: 'Polaris Bank', val: 'polaris' },
        { id: 15, name: 'Providus Bank', val: 'providus' },
        { id: 16, name: 'Stanbic IBTC Bank', val: 'stanbic' },
        { id: 17, name: 'Standard Chartered Bank', val: 'standard chartered' },
        { id: 18, name: 'Sterling Bank', val: 'sterling' },
        { id: 19, name: 'SunTrust Bank', val: 'suntrust' },
        { id: 20, name: 'Titan Trust Bank', val: 'titan' },
        { id: 21, name: 'Union Bank', val: 'union' },
        { id: 22, name: 'United Bank for Africa', val: 'uba' },
        { id: 23, name: 'Unity Bank', val: 'unity' },
        { id: 24, name: 'Wema Bank', val: 'wema' },
        { id: 25, name: 'Zenith Bank', val: 'zenith' },
        { id: 26, name: 'Opay', val: 'opay' },
        { id: 27, name: 'Fairmoney', val: 'fairmoney' },
        { id: 28, name: 'PalmPay', val: 'palmpay' },
        { id: 29, name: 'Rubies', val: 'rubies' },
        { id: 30, name: 'VFD Microfinance Bank', val: 'vfd' },
        { id: 31, name: 'Carbon (Paylater)', val: 'carbon' },
        { id: 32, name: 'Branch', val: 'branch' },
        { id: 33, name: 'ALAT by Wema', val: 'alat' },
        { id: 34, name: 'Eyowo', val: 'eyowo' },
		{ id: 35, name: 'FBNQuest Merchant Bank', val: 'fbnquest' },
		{ id: 36, name: 'Fidelity Pension Managers', val: 'fidelitypensions' },
		{ id: 37, name: 'FINCA Microfinance Bank', val: 'finca' },
		{ id: 38, name: 'First Bank Pension Managers', val: 'firstpensions' },
		{ id: 39, name: 'FirstCentury Merchant Bank', val: 'firstcentury' },
		{ id: 40, name: 'Flutterwave', val: 'flutterwave' },
		{ id: 41, name: 'GTBank Pension Managers', val: 'gtpensions' },
		{ id: 42, name: 'Innovate1Pay', val: 'innovate1pay' },
		{ id: 43, name: 'Interswitch', val: 'interswitch' },
		{ id: 44, name: 'LAPO Microfinance Bank', val: 'lapo' },
		{ id: 45, name: 'Leadway Pensure', val: 'leadwaypensure' },
		{ id: 46, name: 'Lidya', val: 'lidya' },
		{ id: 47, name: 'NPF Pensions', val: 'npfpensions' },
		{ id: 48, name: 'Paystack', val: 'paystack' },
		{ id: 49, name: 'PiggyVest', val: 'piggyvest' },
		{ id: 50, name: 'Renmoney', val: 'renmoney' },
		{ id: 51, name: 'Stanbic IBTC Pension Managers', val: 'stanbicpensions' },
		{ id: 52, name: 'Tangerine Life', val: 'tangerinelife' },
		{ id: 53, name: 'Termii', val: 'termii' },
		{ id: 54, name: 'Trove', val: 'trove' },
		{ id: 55, name: 'Trust Merchant Bank', val: 'trustmerchant' },
		{ id: 56, name: 'Wema Pension Managers', val: 'wemapensions' },
		{ id: 57, name: 'Zedvance', val: 'zedvance' },
    ];
	const options = [
		{ id: 3, name: 'Card', val: 'Card' },
		{ id: 1, name: 'Cash', val: 'Cash' },
		{ id: 2, name: 'Transfer', val: 'Transfer' },
	]
	const INIT = {
		senderName: '',
		senderPhoneNumber: '',
		recipientName: '',
		recipientAccountNumber: '',
		amount: '',
		bank: '',
		paymentMode: ''
	};
	
	const VALIDATE = Yup.object().shape({
		senderName: Yup.string().trim().required('Sender name is required'),
		senderPhoneNumber: Yup.string().trim().required('Sender phone number is required'),
		recipientName: Yup.string().trim().required('Recipient name is required'),
		recipientAccountNumber: Yup.string().trim().required('Recipient account number is required'),
		amount: Yup.number().required('Required'),
		bank: Yup.string().required('Required'),
		paymentMode: Yup.string().required('Required')
	});

	const token = sessionStorage.getItem('token');

	return (
		<Modal classNames={{ modal: 'customModal' }} open={open} onClose={close} center>
			<Container>
				<Header>Transfer</Header>
				<FormWrapper>
					<Formik
						initialValues={INIT}
						validationSchema={VALIDATE}
						validateOnChange={false}
						validateOnBlur={false}
						onSubmit={ async (values) => {
							const { senderName, senderPhoneNumber, recipientName, recipientAccountNumber, amount, bank, paymentMode } = values;
							const data = {
								sender_phone_number: senderPhoneNumber,
								sender_name: senderName,
								receiver_account_number: recipientAccountNumber,
								receiver_account_name: recipientName,
								amount: amount,
								bank: bank,
								mode_of_payment: paymentMode
							}
							const config = {
								method: 'post',
								url: 'transaction/transfer',
								headers: {
									'Authorization': 'Bearer ' + JSON.parse(token)
								},
								data: data
							}
							try {
								const res = await axios(config);
								if(res.status === 200) {
									toast.success("Transfer logged Successfully!");
									close();
								} else {
									toast.warn(res.data.message);
								}
							} catch (error) {
								console.log(error);
								toast.error(error.message);
								close();
							}
						}}
					>
						{({ isSubmitting }) => {
							return (
								<Form>
									<Grid container columnSpacing={3} rowSpacing={4} alignItems="center">
										<Grid item xs={12}>
											<Typography variant="button">Transaction Details</Typography>
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="senderName" label="Sender Name" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="senderPhoneNumber" label="Sender Phone Number" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="recipientName" label="Recipient Name" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="recipientAccountNumber" label="Recipient Account Number" />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextInput name="amount" label="Amount" />
										</Grid>
										<Grid item xs={12} md={6}>
											<Dropdown name="bank" label="Bank" options={banks} />
										</Grid>
										<Grid item xs={12}>
											<Dropdown name="paymentMode" label="Payment Mode" options={options} />
										</Grid>
										<Grid item xs={12}>
											<Button disabled={isSubmitting} type="submit" variant="contained" fullWidth sx={{ height: '3.5rem' }}>
												{ isSubmitting === true ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : 'transfer' }
											</Button>
										</Grid>
									</Grid>
								</Form>
							)
						}}
					</Formik>
				</FormWrapper>
			</Container>
		</Modal>
	)
}

export default Transfer;