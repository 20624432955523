import React from 'react';
import { Modal } from 'react-responsive-modal';
import styled from 'styled-components';
import { Container, Header, TextInput } from '../../components/StyledComponents';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { Grid, Button, Input, Typography } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const FormWrapper = styled.div`
  margin: 2rem 0;
  padding-bottom: 1.5rem;
  .mainForm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 625px) {
      grid-template-columns: 1fr;
    }
  }
`;

const EditUser = ({ open, close, data, onUserUpdate }) => {
  const nameParts = (data.Name || '').split(' ');
  const firstname = nameParts[0] || '';
  const lastname = nameParts[1] || '';

  const INIT = {
    firstname: firstname,
    lastname: lastname,
    middlename: data.middlename,
    personal_email: data.personal_email,
    phone_no: data.phone_no,
    address: data.address,
    // nearest_bus_stop: data.nearest_bus_stop,
    // city: data.city,
    // state: data.state,
    // country: data.country,
    // gender: data.gender,
    // date_of_birth: data.date_of_birth,
    next_of_kin_name: data.next_of_kin_name,
    kin_address: data.kin_address,
    kin_phone: data.kin_phone,
    kin_relationship: data.kin_relationship,
    guarantor_name: data.guarantor_name,
    guarantor_phone: data.guarantor_phone,
    guarantor_address: data.guarantor_address,
    area_code: data.area_code,
    profile_pic: data.profile_pic,
    password: '',
    new_password: '',
  };

  // const VALIDATE = Yup.object().shape({
  //   firstname: Yup.string().required('First Name is required'),
  //   lastname: Yup.string().required('Last Name is required'),
  //   middlename: Yup.string().nullable(),
  //   personal_email: Yup.string().email('Invalid email address').required('Email is required').nullable(),
  //   phone_no: Yup.string().required('Phone number is required').nullable(),
  //   // address: Yup.string().required('Address is required').nullable(),
  //   // nearest_bus_stop: Yup.string(),
  //   // city: Yup.string(),
  //   // state: Yup.string(),
  //   // country: Yup.string(),
  //   // gender: Yup.string(),
  //   // date_of_birth: Yup.date(),
  //   next_of_kin_name: Yup.string().nullable().notRequired(),
  //   kin_address: Yup.string().nullable().notRequired(),
  //   kin_phone: Yup.string().nullable().notRequired(),
  //   kin_relationship: Yup.string().nullable().notRequired(),
  //   guarantor_name: Yup.string().nullable().notRequired(),
  //   guarantor_phone: Yup.string().nullable().notRequired(),
  //   guarantor_address: Yup.string().nullable().notRequired(),
  //   area_code: Yup.string().nullable().notRequired(),
  //   profile_pic: Yup.mixed().nullable().notRequired(),
  //   password: Yup.string().test(
  //     'password-update',
  //     'Current password is required when updating password',
  //     (value, context) => {
  //       const { new_password } = context.parent;
  //       return !new_password || (value && value.length > 0);
  //     }
  //   ),
  //   new_password: Yup.string().test(
  //     'new-password',
  //     'New password is required when updating password',
  //     (value, context) => {
  //       const { password } = context.parent;
  //       return !password || (value && value.length > 0);
  //     }
  //   ),
  // });

  const token = sessionStorage.getItem('token');

  const handleSubmit = async (values) => {
    const { password, new_password, ...updatedFields } = values;
    const hasPasswordUpdate = password && new_password;

    const filteredUpdatedFields = Object.fromEntries(
      Object.entries(updatedFields).filter(([_, value]) => value !== null && value !== undefined)
    );

    const config = {
      method: 'put',
      url: `profile/edit/${data.id}`,
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(token),
      },
      data: {
        ...(hasPasswordUpdate ? { password, new_password } : {}),
        ...filteredUpdatedFields,
      },
    };

    try {
      const response = await axios(config);
      if (response.status === 200) {
        toast.success('User information updated successfully!');
        onUserUpdate();
        close(); // Close the modal after successful update
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <Modal classNames={{ modal: 'customModal' }} open={open} onClose={close} center>
      <Container>
        <Header>Edit User Information</Header>
        <FormWrapper>
          <Formik
            initialValues={INIT}
            // validationSchema={VALIDATE}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form>
                <Grid container spacing={4} alignItems="center">
                  <Grid item xs={12}>
                    <TextInput name="firstname" label="First Name" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput name="lastname" label="Last Name" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput name="middlename" label="Middle Name" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput name="personal_email" label="Email" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput name="phone_no" label="Phone Number" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput name="address" label="Address" />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <TextInput name="nearest_bus_stop" label="Nearest Bus Stop" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput name="city" label="City" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput name="state" label="State" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput name="country" label="Country" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput name="gender" label="Gender" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput name="date_of_birth" label="Date of Birth" type="date" />
                  </Grid> */}
                  <Grid item xs={12}>
                    <TextInput name="next_of_kin_name" label="Next of Kin Name" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput name="kin_address" label="Kin Address" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput name="kin_phone" label="Kin Phone" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput name="kin_relationship" label="Kin Relationship" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput name="guarantor_name" label="Guarantor Name" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput name="guarantor_phone" label="Guarantor Phone" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput name="guarantor_address" label="Guarantor Address" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput name="area_code" label="Area Code" />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      type="file"
                      name="profile_pic"
                      onChange={(e) => setFieldValue('profile_pic', e.currentTarget.files[0])}
                    />
                    <Typography sx={{ fontSize: '0.85rem', color: '#d32f2f', margin: '0.25rem 0.75rem' }}>
                      <ErrorMessage name="profile_pic" />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput name="password" label="Current Password" type="password" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput name="new_password" label="New Password" type="password" />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      type="submit"
                      fullWidth
                      sx={{ height: '3.5rem' }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Updating...' : 'Update'}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={close}
                      sx={{ height: '3.5rem' }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </FormWrapper>
      </Container>
    </Modal>
  );
};

export default EditUser;