export const searchFunc = (e, val, searchParam, setSearchParam, setSearchArr, imerArr) => {
	e.preventDefault();
	setSearchParam(val);
	if(val.trim() !== '') {
		const result = imerArr.filter((item) => {
			return Object.values(item).join('').toLowerCase().includes(searchParam.toLowerCase())
		});
		setSearchArr(result);
	} else if(val.trim() === '') {
		setSearchArr(imerArr);
	}
};

// states
export const states = [
  {
    "val": "FCT",
    "name": "FCT Abuja"
  },
  {
    "val": "AB",
    "name": "Abia"
  },
  {
    "val": "AD",
    "name": "Adamawa"
  },
  {
    "val": "AK",
    "name": "AkwaIbom"
  },
  {
    "val": "AN",
    "name": "Anambra"
  },
  {
    "val": "BA",
    "name": "Bauchi"
  },
  {
    "val": "BY",
    "name": "Bayelsa"
  },
  {
    "val": "BE",
    "name": "Benue"
  },
  {
    "val": "BO",
    "name": "Borno"
  },
  {
    "val": "CR",
    "name": "CrossRiver"
  },
  {
    "val": "DE",
    "name": "Delta"
  },
  {
    "val": "EB",
    "name": "Ebonyi"
  },
  {
    "val": "ED",
    "name": "Edo"
  },
  {
    "val": "EK",
    "name": "Ekiti"
  },
  {
    "val": "EN",
    "name": "Enugu"
  },
  {
    "val": "GO",
    "name": "Gombe"
  },
  {
    "val": "IM",
    "name": "Imo"
  },
  {
    "val": "JI",
    "name": "Jigawa"
  },
  {
    "val": "KD",
    "name": "Kaduna"
  },
  {
    "val": "KN",
    "name": "Kano"
  },
  {
    "val": "KT",
    "name": "Katsina"
  },
  {
    "val": "KE",
    "name": "Kebbi"
  },
  {
    "val": "KO",
    "name": "Kogi"
  },
  {
    "val": "KW",
    "name": "Kwara"
  },
  {
    "val": "LA",
    "name": "Lagos"
  },
  {
    "val": "NA",
    "name": "Nassarawa"
  },
  {
    "val": "NI",
    "name": "Niger"
  },
  {
    "val": "OG",
    "name": "Ogun"
  },
  {
    "val": "ON",
    "name": "Ondo"
  },
  {
    "val": "OS",
    "name": "Osun"
  },
  {
    "val": "OY",
    "name": "Oyo"
  },
  {
    "val": "PL",
    "name": "Plateau"
  },
  {
    "val": "RI",
    "name": "Rivers"
  },
  {
    "val": "SO",
    "name": "Sokoto"
  },
  {
    "val": "TA",
    "name": "Taraba"
  },
  {
    "val": "YO",
    "name": "Yobe"
  },
  {
    "val": "ZA",
    "name": "Zamfara"
  }
];
