import React from 'react';
import { Modal } from 'react-responsive-modal';
import { Container, Header, TextInput } from '../../../components/StyledComponents';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { CircularProgress, Grid, Typography, Button } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';


const AddComment = ({ open, close, data, getComments }) => {
	const [loading, setLoading] = React.useState(false);
	const token = sessionStorage.getItem('token');
	const init = { comment: '' };
	const validate = Yup.object().shape({
		comment: Yup.string().trim().required("Required")
	});

	const submit = async (values) => {
		setLoading(true);
		const bodyData = {
			"comment": values.comment,
			"customer_id": data.customer_id
		}
		
		const config = {
			url: 'audit/comments',
			method: 'POST',
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			},
			data: bodyData
		}
		try {
			const res = await axios(config);
			if(res.status === 200) {
				setLoading(false);
				toast.success("Comment added successfully!");
				getComments();
				close();
			} else {
				toast.warn(res.data.message);
				setLoading(false);
				close();
			}
		} catch (error) {
			setLoading(false);
			console.error(error);
			toast.error("Error saving comment.");
		}
	}
	

	return (
		<Modal classNames={{ modal: 'customModal' }} open={open} onClose={() => null} center>
			<Container>
				<Header>New Auditor Comment</Header>
				<Formik
					initialValues={init}
					validationSchema={validate}
					onSubmit={values => {
						submit(values);
					}}
					>
					{() => (
						<Form>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography variant="h5" sx={{ margin: '2rem 0' }}>
										New comment - {data.account_name}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<TextInput name="comment" label="Comment" multiline rows={5} />
								</Grid>
								<Grid item xs={6}>
									<Button disabled={loading} type="submit" fullWidth variant='contained' sx={{ height: '3.5rem' }}>
										{ loading ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : 'submit' }
									</Button>
								</Grid>
								<Grid item xs={6} sx={{ marginBottom: '2rem' }}>
									<Button disabled={loading} fullWidth variant='outlined' sx={{ height: '3.5rem' }} onClick={close}>close</Button>
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
				
			</Container>
		</Modal>
	)
}

export default AddComment;