import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { PageTitle, Loading } from '../../../components/StyledComponents';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import NewCustomer from './NewCustomer';
import DeleteModal from './DeleteModal';
import { toast } from 'react-toastify';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import View from './View';
import { Tooltip, Grid, FormControl, InputLabel, OutlinedInput, IconButton, InputAdornment, Button, Typography } from '@mui/material';

const Table = styled.div`
	margin-top: 2rem;	
	.customer-name {
		cursor: pointer;
		&:hover {
			color: #D70900;
		}
	}
`;
const Action = styled.div`
	.edit {
		color: #38383d;
		margin: 0 0.25rem;
		cursor: pointer;
	}
	.delete {
		color: #F43232;
		margin: 0 0.25rem;
		cursor: pointer;
	}
`;
const Wrapper = styled.div`
	position: relative;
	width: 100%;
	.top-box {
		display: flex;
		gap: 1rem;
		justify-content: space-between;
		.formControl {
			width: 100%;
			max-width: 400px;
		}
		.add-btn {
			height: 3.625rem;
			width: 10rem;
			border: 1px solid rgba(0, 0, 0, 0.3);
			&:hover {
				border: 1px solid #F43232;
			}
		}
	}

	@media(max-width: 768px) {
		.top-box {
			flex-direction: column;
			align-items: flex-end;
			.formControl {
				max-width: 100%;
			}
		}
	}
`;

const Customers = () => {
	let token = sessionStorage.getItem('token');
	const [loading, setLoading] = useState(false);
	const [customers, setCustomers] = useState(null);
	const [customer, setCustomer] = useState(null);
	const [error, setError] = useState(null);
	const [deleteModal, setDeleteModal] = useState(false);
	const [addModal, setAddModal] = useState(false);
	const [viewModal, setViewModal] = useState(false);

	const deleteCustomerModal = val => {
		setDeleteModal(true);
		setCustomer(val);
	}

	const openView = val => {
		setViewModal(true);
		setCustomer(val);
	}

	const deleteCustomer = async () => {
		setLoading(true);
		const usr = {
			account_number: customer.account_number,
			card_number: customer.card_number,
		}

		const config = {
			method: 'POST',
			url: 'status/deactivate/customer',
			headers: {
				Authorization: `Bearer ${JSON.parse(token)}`
			},
			data: usr
		}

		if(Number(customer.balance) !== 0) {
			toast.error("The selected customer has a non-zero balance. Please settle previous balance before deleting the customer.");
			setDeleteModal(false);
			setLoading(false);
		} else {
			try {
				setLoading(false);
				const response = await axios(config);
				if(response.status === 200) {
					getAccounts();
					setDeleteModal(false);
					toast.success('Customer deleted successfully.');
				} else {
					setDeleteModal(false);
					toast.error('Error deleting customer.');
				}
			} catch (err) {
				setLoading(false);
				console.log(err);
				toast.error('Error deleting customer');
				setDeleteModal(false);
			}
		}
	}

	const cols = [
		{
			name: 'Name',
			sortable: true,
			cell: row => <div className='customer-name' onClick={() => openView(row)}>{row.account_name}</div>
		},
		{
			name: 'Account Number',
			selector: row => row.account_number,
			sortable: true
		},
		{
			name: 'Card Number',
			selector: row => row.card_number,
			sortable: true
		},
		{
			name: 'Status',
			selector: row => row["Activity Status"],
			sortable: true
		},
		{
			name: 'Action',
			selector: row => row.user_type,
			cell: row => 
				<Action>
					<Tooltip title="Delete Customer" placement="top">
						<DeleteIcon
							className="delete"
							onClick={() => deleteCustomerModal(row)}
						/>
					</Tooltip>
				</Action>
		},
		
	];

	const getAccounts = async () => {
		setLoading(true);
		const config = {
			method: 'get',
			url: "list/customers",
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			}
		}

		try {
			const res = await axios(config);
			setLoading(false);
			if(res.status === 200) {
				let { data } = res;
				data = data.sort((a, b) => a.account_name.localeCompare(b.account_name)).filter(item => item["Activity Status"] === "Active");
				setCustomers(data);
			}
		} catch (error) {
			console.log(error);
			setError(error);
			setLoading(false);
		}
	};

	// search
	const [text, setText] = useState('');
	const handleTextChange = e => setText(e.target.value);

	useEffect(() => {
		getAccounts();
		// eslint-disable-next-line
	}, []);

	return (
			<Wrapper>
				<PageTitle>Customers</PageTitle>
				<Grid container sx={{ alignItems: "center", justifyContent: "space-between", marginY: '2rem' }}>
					<Grid item xs={12} className="top-box" >
						<FormControl className='formControl' variant="outlined">
							<InputLabel htmlFor="search-input">Search</InputLabel>
							<OutlinedInput
								id="search-input"
								onChange={handleTextChange}
								value={text}
								endAdornment={
									<InputAdornment position="end">
										<IconButton edge="end">
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								}
								label="Search"
							/>
						</FormControl>
						<Button onClick={() => setAddModal(true)} variant="outlined" className='add-btn'>
							<PersonAddAltIcon sx={{ marginRight: '0.5rem' }} />
							Add
						</Button>
					</Grid>
				</Grid>
				<Fragment>
					{
						loading === true ? <Loading text="Please wait" /> :
							(
								error ? <Typography sx={{ textAlign: 'center' }} color="error">{error.message}</Typography> :
								customers !== null &&
								<Table>
									<DataTable
										columns={cols}
										data={
											customers.filter(item => {
												if(text.trim() === "") {
													return item;
												} else if(item.account_name.toLowerCase().includes(text.toLowerCase())) {
													return item;
												} else if(item.account_number.toLowerCase().includes(text.toLowerCase())) {
													return item;
												} else if(item.card_number.toLowerCase().includes(text.toLowerCase())) {
													return item;
												}
											})
										}
										pagination paginationPerPage={20}
									/>
								</Table>
							)
					}
				</Fragment>
				<Fragment>
					{
						addModal === true &&
						<NewCustomer
							open={addModal}
							close={() => setAddModal(false)}
							getAccounts={getAccounts}
						/>
					}
				</Fragment>
				<Fragment>
					{
						deleteModal === true &&
						<DeleteModal
							open={deleteModal}
							close={() => { setDeleteModal(false); setCustomer(null); }}
							customer={customer}
							loading={loading}
							deleteCustomer={deleteCustomer}
							getAccounts={getAccounts}
						/>
					}
				</Fragment>
				<Fragment>
					{
						viewModal === true &&
						<View
							open={viewModal}
							close={() => { setViewModal(false); setCustomer(null); }}
							data={customer}
							getAccounts={getAccounts}
						/>
					}
				</Fragment>
			</Wrapper>
	)
};

export default Customers;