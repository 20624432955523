import { Modal } from 'react-responsive-modal';
import styled from 'styled-components';
import { Container } from '../../../components/StyledComponents';
import { Button } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { CircularProgress } from '@mui/material';

const Wrapper = styled.div`
	padding: 1rem 0;
	height: 250px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 0.25rem;
	.customerName {
		font-weight: 700;
	}
	.deleteBtnArray {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 1rem;
		margin-top: 1rem;
		.yes {
			background: green;
		}
	}
`;
const Warning = styled.div`
	color: #F43232;
	display: flex;
	align-items: center;
	font-weight: 700;
	font-size: 1rem;
	.warningIcon {
		margin-right: 0.25rem;
	}
`;

const DeleteModal = ({ open, close, customer, loading, deleteCustomer }) => {
	return (
		<Modal
			classNames={{ modal: 'deleteModal' }}
			open={open} onClose={close} center
			closeIcon={<DisabledByDefaultIcon color="error" />}
		>
			<Wrapper>
				<Warning>
					<WarningAmberIcon className="warningIcon" />
					This action is irreversible!
				</Warning>
				<Container>
					Are you sure you want to delete the customer <span className="customerName">{customer.account_name}</span>?
				</Container>
				<div className='deleteBtnArray'>
					<Button variant="contained" color="error" disabled={loading} onClick={deleteCustomer}>
						{
							loading === true ? <CircularProgress sx={{ color: '#FFFFFF' }} />
							: 'Yes'
						}
					</Button>
					<Button variant="outlined" color="secondary" sx={{ border: '1px solid #707070' }} disabled={loading} onClick={close}>No</Button>
				</div>
			</Wrapper>
		</Modal>
	)
}

export default DeleteModal;