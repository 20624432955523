import React from 'react';
import styled from 'styled-components';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: calc(100vh - 5rem);
	color: rgba(0, 0, 0, 0.75);
`;
const Text = styled.h1`
	font-size: 1.25rem;
	line-height: 2.25rem;
`;

const NotFound = () => {
	return (
		<Container>
			<Text style={{ display: 'flex', alignItems: 'center', fontSize: '2rem', color: '#dc0121' }}>
				<ReportGmailerrorredIcon style={{ fontSize: '2rem', marginRight: '0.5rem' }} />
				403 Forbidden Error:
			</Text>
			<Text>You do not have permissions to access the requested resource.</Text>
			<Text>Kindly contact your admin.</Text>
		</Container>
	)
}

export default NotFound;