import { Fragment, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { Container, Header } from '../../components/StyledComponents';
import { Grid, Button, Typography, CircularProgress, Tooltip } from '@mui/material';
import axios from 'axios';
import { CancelPresentation } from '@mui/icons-material';
import { toast } from 'react-toastify';

const View = ({ open, close, data, token, renderDate, getTransfers }) => {

	console.log(data);
	const gridStyle = {
		container: {
			marginTop: '0rem',
			marginBottom: '2rem'
		},
		gridItem: {
			display: 'flex',
			gap: '1rem',
		},
		gridItemHeader: {
			fontWeight: 'bold',
			color: 'rgba(0, 0, 0, 0.7)'
		},
		btn: {
			height: '3.5rem'
		},
		status: {
			color: data.status === "pending" ? "orange" : "green",
		}
	};

	const  { transaction_id } = data;

	const [loading, setLoading] = useState(false);
	const approve = async () => {
		setLoading(true);
		const data = {
			transaction_id: transaction_id
		}
		const config = {
			method: 'post',
			url: 'transaction/approve',
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			},
			data: data
		}
		try {
			const res = await axios(config);
			setLoading(false);
			if(res.status === 200) {
				toast.success("Transaction completed successfully!");
				close();
				getTransfers();
			} else {
				toast.warn(JSON.stringify(res.data));
				close();
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
			toast.error(error.message);
			close();
		}
	}

	const CloseIcon = (
		<Tooltip title="Close" sx={{ color: '#F43232' }}>
			<CancelPresentation />
		</Tooltip>
	);
	
	return (
		<Modal classNames={{ modal: 'customModal' }} open={open} onClose={close} center closeIcon={CloseIcon} >
			<Container>
				<Header>Transfer summary</Header>
				<Fragment>
						<Grid container spacing={4} sx={gridStyle.container}>
							<Grid item xs={12}>
								<Typography sx={{ fontWeight: '600' }} variant="h6">Transaction Details</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>
									Sender Name:
								</Typography>
								<Typography>
									{
										data.account_name
									}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>
									Sender Account:
								</Typography>
								<Typography>
									{data.account_number}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>
									Recipient Name:
								</Typography>
								<Typography>
									{
										data.receiver_account_name
									}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>
									Recipient Account:
								</Typography>
								<Typography>
									{data.receiver_account_number}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>
									Transaction Type:
								</Typography>
								<Typography>
									Transfer
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>
									Bank:
								</Typography>
								<Typography>
									{data.bank}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>
									Amount:
								</Typography>
								<Typography>
									N {Number(data.amount).toLocaleString()}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>
									Payment Mode:
								</Typography>
								<Typography>
									{ data.mode_of_payment }
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>
									Status:
								</Typography>
								<Typography sx={gridStyle.status}>
									{data.status}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>
									Transaction Date:
								</Typography>
								<Typography>
									{ renderDate(data.date_created) }
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>
									Completion Date:
								</Typography>
								<Typography>
									{ data.status === "pending" ? null : renderDate(data.last_updated) }
								</Typography>
							</Grid>
						</Grid>
				</Fragment>
				<Grid container spacing={4} sx={gridStyle.container}>
					<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
						<Button onClick={approve} disabled={loading === true || data.status === "success" ? true : false} variant="contained" fullWidth sx={gridStyle.btn}>
							{ loading === true ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : "done" }
						</Button>
					</Grid>
					<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
						<Button onClick={close} disabled={loading} variant="outlined" fullWidth sx={gridStyle.btn}>close</Button>
					</Grid>
				</Grid>
			</Container>
		</Modal>
	)
}

export default View;