import { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { Title, PageTitle, Loading } from '../../../components/StyledComponents';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Typography } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import ViewLoan from './ViewLoan';
import View from './View';
import { getLoans } from '../../../redux/slice';
import { useSelector, useDispatch } from 'react-redux';

const Container = styled.div`
	.customer-name {
		cursor: pointer;
		&:hover {
			color: #D70900;
		}
	}
	${Title} {
		font-size: 2.25rem;
		flex: 1;
	}
	a {
		text-decoration: none;
		color: inherit;
		&:hover {
			color: #F43232;
		}
	}
`;
const Table = styled.div`
	margin: 2rem 0;
`;

const Borrowers = () => {
	const dispatch = useDispatch();
	const loans = useSelector(state => state.nebo.loans.data);
	const loansLoading = useSelector(state => state.nebo.loans.loading);
	const [text, setText] = useState('');
	const [error, setError] = useState(null);
	const [viewModal, setViewModal] = useState(false);
	const [viewInfo, setViewInfo] = useState(false);
	const [borrower, setBorrower] = useState(null);
	const handleTextChange = (e) => setText(e.target.value);
	const viewBorrower = item => {
		setViewModal(true);
		setBorrower(item);
	}
	const view = val => {
		setBorrower(val);
		setViewInfo(true);
	}
	let token = sessionStorage.getItem('token');


	const renderDate = (date) => {
		let newdate = new Date(date);
		return newdate.toLocaleDateString("en-GB") + " " + newdate.toLocaleTimeString("en-GB");
	}

	const cols = [
		{
			name: 'Customer Name',
			selector: row => <div className='customer-name' onClick={() => view(row)}>{row.account_name}</div>,
			sortable: true,
			minWidth: '11rem'
		},
		{
			name: 'Account Number',
			selector: row => row.account_number,
			sortable: true,
			minWidth: '11rem'
		},
		{
			name: 'Request Date',
			selector: row => renderDate(row["Request Date"]),
			sortable: true,
			minWidth: '12rem'
		},
		{
			name: 'Loan Amount',
			selector: row => <>{Number(row.amount_requested).toLocaleString()}</>,
			sortable: true,
			minWidth: '12rem'
		},
		{
			name: 'Tenure',
			selector: row => row.tenure + " " + row.period,
			sortable: true
		},
		{
			name: 'Rate (%)',
			selector: row => row.rate,
		},
		{
			name: 'Amount Due',
			selector: row => <>{Number(row.total_amount_due).toLocaleString()}</>,
			sortable: true,
			minWidth: '9rem'
		},
		{
			name: 'Amount Repaid',
			selector: row => <>{Number(row.amount_repaid).toLocaleString()}</>,
			sortable: true,
			minWidth: '11rem'
		},
		{
			name: 'Balance',
			selector: row => <div style={{ color: 'red' }}>{Number(row.amount_unpaid).toLocaleString()}</div>,
			sortable: true,
			minWidth: '7rem'
		},
		{
			name: 'Status',
			selector: row => row["Loan status"],
			sortable: true
		},
		{
			name: 'Action',
			selector: row => <Visibility sx={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }} onClick={() => viewBorrower(row)} />,
			sortable: true
		}
	];

	useEffect(() => {
		token = JSON.parse(token)
		dispatch(getLoans(token));
		// eslint-disable-next-line
	}, []);
	
	return (
		<Container>
			<PageTitle>Borrowers</PageTitle>
			<Grid container columnSpacing={4} sx={{ alignItems: "center", justifyContent: "space-between", marginY: '2rem' }}>
				<Grid item xs={12}>
					<FormControl sx={{ width: '100%', maxWidth: '400px'  }} variant="outlined">
						<InputLabel htmlFor="search-input">Search</InputLabel>
						<OutlinedInput
							onChange={handleTextChange}
							value={text}
							endAdornment={
								<InputAdornment position="end">
									<IconButton edge="end">
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							}
							label="Search"
						/>
					</FormControl>
				</Grid>
			</Grid>
			<Table>
				{
					loansLoading === true ? <Loading text="Please wait" /> :
					(
						error ? <Typography color="error">{JSON.stringify(error.message)}</Typography> :
						loans &&
						<DataTable
							columns={cols}
							data={
								loans.filter(item => {
									if(text.trim() === "") {
										return item;
									} else if(item.account_name.toLowerCase().includes(text.toLowerCase())) {
										return item;
									} else if(item.account_number.toLowerCase().includes(text.toLowerCase())) {
										return item;
									}
								})
							}
							pagination paginationPerPage={20}
						/>
					)
				}
			</Table>
			<Fragment>
				{
					viewModal === true &&
					<ViewLoan
						open={viewModal}
						close={() => setViewModal(false)}
						data={borrower}
						getLoans={dispatch(getLoans(token))}
						renderDate={renderDate}
						token={token}
					/>
				}
			</Fragment>
			<Fragment>
					{
						viewInfo === true &&
						<View
							open={viewInfo}
							close={() => { setViewInfo(false); setBorrower(null); }}
							data={borrower}
							loans={loans}
						/>
					}
			</Fragment>
		</Container>
	)
}

export default Borrowers;