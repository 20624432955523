export const transactions = [
	{
		id: 49134,
		name: 'Michael Flynn',
		accountNumber: 5894573483,
		amount: 34839,
		date: '2022-07-12T10:40:01Z',
		transactionType: 'transfer',
		phone: '+12345678901',
		status: 'Pending',
		details: {
			recipient: 'James Moore',
			bank: 'Wema Bank',
			paymentMode: 'cash',
			acctNumber: 89586844983,
			phone: '+12345678901',
		},
		user: {
			id: 4783,
			role: 'Field Agent'
		}
	},
	{
		id: 49035,
		name: 'Roger Moore',
		accountNumber: 5894573489,
		amount: 5893,
		date: '2021-06-12T10:40:01Z',
		transactionType: 'loan',
		user: {
			id: 4790,
			role: 'Loan Manager'
		}
	},
	{
		id: 49036,
		name: 'Eder Humpson',
		accountNumber: 5822573483,
		amount: 92834,
		date: '2021-06-11T10:40:01Z',
		transactionType: 'collection',
		user: {
			id: 4783,
			role: 'Field Agent'
		}
	},
	{
		id: 49037,
		name: 'Thomas Header',
		accountNumber: 5894533383,
		amount: 19220,
		date: '2021-07-14T10:40:01Z',
		transactionType: 'deposit',
		user: {
			id: 9683,
			role: 'Field Agent'
		}
	},
	{
		id: 49034,
		name: 'Spongebob SQ',
		accountNumber: 5844940459,
		amount: 348390,
		date: '2021-06-11T10:40:01Z',
		transactionType: 'withdrawal',
		user: {
			id: 1932,
			role: 'Admin'
		}
	}
]