import React, { useEffect, useState, Fragment } from 'react';
import { Modal } from 'react-responsive-modal';
import { Header, Container, Loading, PageTitle } from '../../../components/StyledComponents';
import { Grid, Button, Typography, TextField, Box, CircularProgress } from '@mui/material';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import AddComment from './AddComment';
import { Visibility } from '@mui/icons-material';

const HeaderBox = styled.div`
  border: 2px solid #F4323290;
  margin: 2rem 0;
  padding: 1rem;
  display: flex;
`;
const Picture = styled.div`
    width: 100%;
    max-width: 11.5rem;
    height: 12rem;
    background: #C4C4C4;
    display: flex;
    justify-content: center;
    align-items: center;
  overflow: hidden;
    img {
        max-width: 100%;
        max-height: 100%;
    object-fit: cover;
    }
`;
const Info = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  width: 100%;
`;
const InfoItem = styled.div`
  padding: 0 1rem;
`;
const Item = styled.div`
  margin-bottom: 0.75rem;
  .name {
    font-weight: bold;
  }
`;
const Text = styled.p`
  font-weight: ${props => props.weight};
  font-size: 1.25rem;
`;

const gridStyle = {
  box: {
    marginTop: '0.5rem',
    marginBottom: '2rem',
  },
  userBox: {
    border: '2px solid #F4323290',
    padding: '1rem',
    width: '100%'
  },
  picture: {
    width: '100%',
    maxWidth: '11.5rem',
    height: '12rem',
    background: '#C4C4C4'
  },
  container: {
    marginTop: '0rem',
    marginBottom: '2rem'
  },
  gridItem: {
    display: 'flex',
    gap: '1rem',
  },
  btnArray: {
    display: 'flex',
    gap: '1rem',
    maxWidth: '25rem',
  },
  btn: {
    height: '3.5rem',
    width: '100%'
  },
  trxHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1rem'
  },
  balance: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',
    justifyContent: 'space-between',
    padding: '1rem',
    border: '1px solid #F4323290',
    borderRadius: '0.25rem',
    marginBottom: '2rem'
  },
  amount: {
    background: '#F43232',
    padding: '0.75rem 1rem',
    color: '#FFFFFF',
    minWidth: '10rem',
    textAlign: 'center'
  }
};

const ViewUser = ({ open, close, data }) => {
  let id = (data.id).toString();
  console.log(data);
  const [comment, setComment] = React.useState(false);
  const [commentsArray, setCommentsArray] = React.useState(null);
  const [commentsLoading, setCommentsLoading] = React.useState(false);
  const [viewCommentModal, setViewCommentModal] = React.useState(false);
  const [viewComment, setViewComment] = React.useState(null);
  const [commentsError, setCommentsError] = React.useState(false);
  const cols = [
    {
      name: 'Transaction ID',
      selector: row => row.transaction_id
    },
    {
      name: 'Customer Name',
      selector: row => row.account_name
    },
    {
      name: 'Amount',
      selector: row => Number(row.amount).toLocaleString()
    },
    {
      name: 'Trx Type',
      selector: row => row["Transaction Type"],
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => <Box sx={{ textTransform: 'capitalize' }}>{row.status}</Box>
    }
  ];

  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState(null);
  const [error, setError] = useState(null);
  const token = sessionStorage.getItem('token');

  const getTransactions = async () => {
    setLoading(true);
    const config = {
      method: 'get',
      url: `list/transactions/user/${data.id}`,
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(token),
      }
    }

    try {
      const response = await axios(config);
      if(response.status === 200) {
        setLoading(false);
        let data = response.data;
        data = data.sort((a, b) => new Date(a["Transaction date"]) > new Date(b["Transaction date"]) ? -1 : 1);
        data = data.filter(item => id === item.initiator_id);
        setReport(data);
      }
    } catch (error) {
      setLoading(false);
      setError(error);
      console.log(error);
    }
  }

  const getComments = async () => {
    setCommentsLoading(true);
    const config = {
      method: 'get',
      url: `audit/userComments/${data.id}`,
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(token),
      }
    }

    try {
      const response = await axios(config);
      if(response.status === 200) {
        const results = response?.data?.data;
        setCommentsLoading(false);
        setCommentsArray(results);
      }
    } catch (error) {
      setCommentsLoading(false);
      setCommentsError(error);
      console.log(error);
    }
  }

  const view = val => {
    setViewComment(val);
    setViewCommentModal(true);
  }

  const renderDate = (date) => {
    let newdate = new Date(date);
    return newdate.toLocaleDateString("en-GB") + " " + newdate.toLocaleTimeString("en-GB");
  }

  const commentsCols = [
    {
      name: 'Comment',
      selector: row => <>{row.comment.length > 40 ? row.comment.substring(0, 40) + "..." : row.comment}</>,
      sortable: true,
    },
    {
      name: 'Added By',
      selector: row => row["created by"],
      sortable: true,
    },
    {
      name: 'Date Added',
      selector: row => renderDate(row.created_on),
      sortable: true,
    },
    {
      name: 'View',
      selector: row => <Visibility onClick={() => view(row)} sx={{ cursor: 'pointer', color: 'rgba(0, 0, 0, 0.54)' }} />,
    }
  ];

  useEffect(() => {
    getTransactions();
    getComments();
  }, []);

  return (
    <Modal classNames={{ modal: 'auditModal' }} open={open} onClose={close} center>
      <Container>
        <Header>User Audit - Summary</Header>
        <HeaderBox>
          <Picture>
            <img src={data.profile_pic} alt="user" />
          </Picture>
          <Info>
            <InfoItem>
              <Item>
                <Text className='name'>{data.Name}</Text>
              </Item>
              <Item>
                <Text>User ID: {data.id}</Text>
              </Item>
              <Item>
                <Text>Designation: {data["User Role"]}</Text>
              </Item>
              <Item>
                <Text>Phone: {data.phone_no}</Text>
              </Item>
              <Item>
                <Text>Email: {data.personal_email}</Text>
              </Item>
              <Item>
                <Text>Address: {data.address}</Text>
              </Item>
            </InfoItem>
            <InfoItem>
              <Item><Text className='name'>Guarantor</Text></Item>
              <Item><Text>Name: {data.guarantor_name}</Text></Item>
              <Item><Text>Phone: {data.guarantor_phone}</Text></Item>
              <Item><Text>Address: {data.guarantor_address}</Text></Item>
            </InfoItem>
            <InfoItem>
              <Item><Text className='name'>Next of kin</Text></Item>
              <Item><Text>Name: {data.next_of_kin_name}</Text></Item>
              <Item><Text>Relationship: {data.kin_relationship}</Text></Item>
              <Item><Text>Phone: {data.kin_phone}</Text></Item>
              <Item><Text>Address: {data.kin_address}</Text></Item>
            </InfoItem>
          </Info>
        </HeaderBox>
        <Grid container spacing={4} sx={gridStyle.container}>
          <Grid xs={12} item>
            <Box sx={gridStyle.trxHeader}>
              <Typography variant="h6">Recent Transactions</Typography>
              <Button type="button" variant="outlined">
                {
                  report &&
                  <CSVLink data={report} filename="transactions.csv" className='download-link'>
                    download
                  </CSVLink>
                }
              </Button>
            </Box>
            <Box sx={{ marginY: '1rem' }}>
              <Fragment>
                {
                  loading === true && !report ?
                  <Box sx={{ height: '20vh', display: 'grid', placeItems: 'center' }}>
                    <CircularProgress />
                  </Box> :
                  (
                    error ? <Typography variant="h6" color="error" sx={{ width: '100%', maxWidth: '400px' }}>{error.response.data}: {error.message}</Typography> :
                    report !== null &&
                    <DataTable
                      columns={cols}
                      data={report}
                      pagination paginationPerPage={5}
                    />
                  )
                }
              </Fragment>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={gridStyle.trxHeader}>
              <Typography variant='h6'>Comments</Typography>
              <Button type="button" variant="outlined">
                {
                  commentsArray &&
                  <CSVLink data={commentsArray} filename="comments.csv" className='download-link'>download</CSVLink>
                }
              </Button>
            </Box>
            <React.Fragment>
              {
                commentsLoading === true ? <Loading text="Loading comments" /> :
                (
                  commentsError ? JSON.stringify(commentsError.message) :
                  commentsArray &&
                  <Box sx={{ marginY: '1rem' }}>
                    <DataTable
                      pagination paginationPerPage={10}
                      columns={commentsCols}
                      data={commentsArray}
                    />
                  </Box>
                )
              }
            </React.Fragment>
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" sx={gridStyle.btn} onClick={() => setComment(true)}>Add Comment</Button>
          </Grid>
          <Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
            <Button variant="outlined" fullWidth sx={gridStyle.btn} onClick={close}>Cancel</Button>
          </Grid>
        </Grid>
      </Container>

      <React.Fragment>
        { comment && <AddComment open={comment} close={() => setComment(false)} data={data} getComments={getComments} /> }
      </React.Fragment>

      <React.Fragment>
        {
          viewCommentModal &&
          <Modal open={viewCommentModal} onClose={() => setViewCommentModal(false)} classNames={{ modal: 'filterModal' }} center>
            <Container>
              <Header>View Comment</Header>
              <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
                <Grid item xs={12}>
                  <Typography variant="h5">{data.Name}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Added on {renderDate(viewComment.created_on)} by {viewComment["created by"]}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{viewComment.comment}</Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                  <Button variant="outlined" fullWidth onClick={() => setViewCommentModal(false)}>close</Button>
                </Grid>
              </Grid>
            </Container>
          </Modal>
        }
      </React.Fragment>
    </Modal>
  )
}

export default ViewUser;