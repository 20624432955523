import { Fragment, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { Container, Header } from '../../../components/StyledComponents';
import { Grid, Button, Typography, CircularProgress, Tooltip } from '@mui/material';
import axios from 'axios';
import { CancelPresentation } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import CancelLoan from './CancelLoan';

const ViewLoan = ({ open, close, data, token, renderDate, getLoans }) => {
	const gridStyle = {
		container: {
			marginTop: '0rem',
			marginBottom: '2rem'
		},
		gridItem: {
			display: 'flex',
			gap: '0.25rem'
		},
		itemLeft: {
			textAlign: 'right',
		},
		itemRight: {
			textAlign: 'left',
		},
		gridItemHeader: {
			fontWeight: 'bold',
			color: 'rgba(0, 0, 0, 0.7)'
		},
		btn: {
			height: '3.5rem'
		},
		status: {
			color: data.status === "pending" ? "orange" : "green",
		}
	};
	const  { id } = data;

	const [loading, setLoading] = useState(false);
	const approve = async () => {
		setLoading(true);
		const data = {
			loan_id: id
		}
		const config = {
			method: 'post',
			url: 'loan/approve',
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			},
			data: data
		}
		try {
			const res = await axios(config);
			setLoading(false);
			if(res.status === 200) {
				toast.success("Loan application approved!");
				close();
				getLoans();
			} else {
				toast.warn(JSON.stringify(res.data));
				close();
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
			toast.error(error.message);
			close();
		}
	}

	const decline = async () => {
		setLoading(true);
		const data = {
			loan_id: id
		}
		const config = {
			method: 'post',
			url: 'loan/decline',
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			},
			data: data
		}
		try {
			const res = await axios(config);
			setLoading(false);
			if(res.status === 200) {
				toast.warn("Loan application declined!");
				close();
				getLoans();
			} else {
				toast.warn(JSON.stringify(res.data));
				close();
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
			toast.error(error.message);
			close();
		}
	}

	const [cancelModal, setCancelModal] = useState(false);

	const CloseIcon = (
		<Tooltip title="Close" sx={{ color: '#F43232' }}>
			<CancelPresentation />
		</Tooltip>
	);

	const loanHealth = () => {
		let dueDate = Date.parse(data.due_date);
		const today = Date.parse(new Date());
		const amountDue = Number(data.total_amount_due);
		const amountUnpaid = Number(data.amount_unpaid);
		let last30 = (new Date()).setDate(new Date().getDate() - 30);
		if (data["Loan status"] === "Declined"){
			return "Unavailable";
		} else if(today - dueDate >= 0 && amountDue >= amountUnpaid && data["Loan status"] === "Running") {
			return "Stale";
		} else if(today - dueDate >= 0 && amountDue >= amountUnpaid && data["Loan status"] === "Past due") {
			return "Past due";
		} else if(last30 >= dueDate && data["Loan status"] === "Running") {
			return "Bad Debt"
		} else {
			return "Healthy"
		}
	}
	
	const health = loanHealth();

	useEffect(() => {
		loanHealth();
	}, []);
	
	return (
		<Modal classNames={{ modal: 'customModal' }} open={open} onClose={close} center closeIcon={CloseIcon} >
			<Container>
				<Header>Loan summary</Header>
				<Grid container columnSpacing={1} rowSpacing={3} sx={gridStyle.container}>
					<Grid item xs={12}>
						{/* <Typography sx={{ fontWeight: '600' }} variant="h6">Transaction Details</Typography> */}
					</Grid>
					<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>Requester Name:</Typography>
								<Typography>{ data.account_name }</Typography>
					</Grid>
					<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
						<Typography sx={gridStyle.gridItemHeader}>Requester Account Number:</Typography>
						<Typography>{data.account_number}</Typography>
					</Grid>
					<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
						<Typography sx={gridStyle.gridItemHeader}>Amount Requested:</Typography>
						<Typography>N{ Number(data.amount_requested).toLocaleString() }</Typography>
					</Grid>
					<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
						<Typography sx={gridStyle.gridItemHeader}>Loan Tenure:</Typography>
						<Typography>{ data.tenure } { data.period }</Typography>
					</Grid>
					<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
						<Typography sx={gridStyle.gridItemHeader}>Interest Rate:</Typography>
						<Typography>{ data.rate }</Typography>
					</Grid>
					<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
						<Typography sx={gridStyle.gridItemHeader}>Loan Type:</Typography>
						<Typography>{ data.loan_type ? data.loan_type : 'Other' }</Typography>
					</Grid>
					<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
						<Typography sx={gridStyle.gridItemHeader}>Payback Amount:</Typography>
						<Typography>{ Number(data.total_amount_due).toLocaleString() }</Typography>
					</Grid>
					<Fragment>
						{
							(data.approval_id && data["Loan status"] !== "Declined") &&
							<>
								<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
									<Typography sx={gridStyle.gridItemHeader}>Amount Repaid:</Typography>
									<Typography>{ Number(data.amount_repaid).toLocaleString() }</Typography>
								</Grid>
								<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
									<Typography sx={gridStyle.gridItemHeader}>Outstanding Balance:</Typography>
									<Typography>{ Number(data.amount_unpaid).toLocaleString() }</Typography>
								</Grid>
							</>
						}
					</Fragment>
					<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
						<Typography sx={gridStyle.gridItemHeader}>Request Date:</Typography>
						<Typography>{ renderDate(data["Request Date"]) }</Typography>
					</Grid>
					<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
						<Typography sx={gridStyle.gridItemHeader}>Approval Date: </Typography>
						<Typography>{ data["Loan status"] === "Running" ? renderDate(data["Approval Date"]) : null }</Typography>
					</Grid>
					<Fragment>
						{
							data.due_date && 
							<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
								<Typography sx={gridStyle.gridItemHeader}>Due Date:</Typography>
								<Typography>{ renderDate(data.due_date) }</Typography>
							</Grid>
						}
					</Fragment>
					<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
						<Typography sx={gridStyle.gridItemHeader}>Loan Status:</Typography>
						<Typography
							sx={{
								color: data["Loan status"] === "Running" ? 'green' : (data["Loan status"] === "Pending approval" ? "orange" : 'red'),
							}}
						>{ data["Loan status"] }</Typography>
					</Grid>
					<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
						<Typography sx={gridStyle.gridItemHeader}>Loan Health:</Typography>
						<Typography
							sx={{
								color: health === "Healthy" ? 'green' : (health === "Stale" ? "orange" : 'red'),
							}}
						>
							{ health }
						</Typography>
					</Grid>
				</Grid>
				<Fragment>
					{
						data["Loan status"] === "Running" &&
						<Grid item xs={12}>
							<Button onClick={() => setCancelModal(true)} variant="outlined">Cancel loan</Button>
						</Grid>
					}
				</Fragment>
				<Grid container spacing={4} sx={gridStyle.container}>
					<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
						<Button onClick={approve} disabled={loading === true || data.approval_id ? true : false} variant="contained" fullWidth sx={gridStyle.btn}>
							{ loading === true ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : "approve" }
						</Button>
					</Grid>
					<Grid item xs={12} sm={6} sx={gridStyle.gridItem}>
						<Button onClick={data.approval_id ? close : decline} disabled={loading} variant="outlined" fullWidth sx={gridStyle.btn}>
							{ data.approval_id ? "close" : "decline" }
						</Button>
					</Grid>
				</Grid>
			</Container>
			<Fragment>
				{
					cancelModal === true &&
					<CancelLoan open={cancelModal} close={() => setCancelModal(false)} loan={data} closeParent={close} getLoans={getLoans} />
				}
			</Fragment>
		</Modal>
	)
}

export default ViewLoan;