import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { PageTitle, Loading } from '../../../components/StyledComponents';
import { Visibility } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { FormControl, Grid, InputLabel, OutlinedInput, InputAdornment, IconButton, Typography } from '@mui/material';
import { searchFunc } from '../../../components/lib';
import axios from 'axios';
import ViewUser from './ViewUser'

const Table = styled.div`
	margin-top: 2rem;
`;
const Action = styled.div`
	.delete {
		color: #F43232;
		margin: 0 0.25rem;
		cursor: pointer;
	}
`;
const Wrapper = styled.div`
	position: relative;
	width: 100%;
`;

const Users = () => {
	const [users, setUsers] = useState(null);
	const [tempUsers, setTempUsers] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [detailsModal, setDetailsModal] = useState(false);
	const [user, setUser] = useState(null);

	const view = (val) => {
		setUser(val);
		setDetailsModal(true);
	}

	let token = sessionStorage.getItem('token');

	const getUsers = async () => {
		setLoading(true);
		const config = {
			method: 'get',
			url: "list/users",
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			}
		}
	
		try {
			const res = await axios(config);
			setLoading(false);
			if(res.status === 200) {
					let { data } = res;
					data = data.sort((a, b) => a.Name.localeCompare(b.Name));
					setTempUsers(data);
					setUsers(data);
				}
			} catch (error) {
				console.log(error);
				setError(error);
				setLoading(false);
			}
	}

	const cols = [
		{
			name: 'Name',
			selector: row => row.Name,
			sortable: true
		},
		{
			name: 'Email',
			selector: row => row.personal_email,
			sortable: true
		},
		{
			name: 'Designation',
			selector: row => row["User Role"],
			sortable: true
		},
		{
			name: 'Date Created',
			selector: row => new Date(row.date_created).toLocaleDateString("en-GB"),
			sortable: true,
			minWidth: '10rem'
		},
		{
			name: 'Status',
			selector: row => row["Activity Status"],
			sortable: true
		},
		{
			name: 'Action',
			selector: row => row.user_type,
			cell: row =>
				<Action>
					<Visibility sx={{ cursor: 'pointer', color: 'rgba(0, 0, 0, 0.54)' }} onClick={() => view(row)} />
				</Action>
		},
		
	];

	// search n filter .start
	const [txt, setTxt] = useState('');
	const onSearch = e => {
		e.preventDefault();
		let val = e.target.value;
		searchFunc(e, val, txt, setTxt, setUsers, tempUsers);
	}

	useEffect(() => {
		getUsers();
	}, []);
	
		return (
			<Wrapper>
				<PageTitle>Audit Users</PageTitle>
				<Grid container columnSpacing={4} sx={{ alignItems: "center", justifyContent: "space-between", marginY: '2rem' }}>
					<Grid item xs={12}>
						<FormControl sx={{ width: '100%', maxWidth: '400px' }}>
							<InputLabel htmlFor="search-input">Search</InputLabel>
							<OutlinedInput
								id="search-input"
								onChange={onSearch}
								value={txt}
								endAdornment={
									<InputAdornment position="end">
										<IconButton edge="end">
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								}
								label="Search"
							/>
						</FormControl>
					</Grid>
				</Grid>
				<Fragment>
					{
						loading === true && !users ? <Loading text={"Please wait"} /> :
						(
							error ? <Typography color="error" sx={{ width: '100%', textAlign: 'center' }}>{error.response.data}: {error.message}</Typography> :
							users !== null &&
							<Table>
								<DataTable
									columns={cols}
									data={users}
									pagination paginationPerPage={10}
									noDataComponent={<Typography variant="h6" color="error" sx={{ width: '100%', maxWidth: '400px' }}>No users found</Typography>}
								/>
							</Table>
						)
					}
				</Fragment>
				<Fragment>
					{
						detailsModal === true &&
						<ViewUser
							open={detailsModal}
							close={() => setDetailsModal(false)}	
							data={user}
						/>
					}
				</Fragment>
			</Wrapper>
		)
}

export default Users;