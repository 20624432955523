import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { logout } from '../../auth';
import { Drawer as MuiDrawer, Typography, IconButton, Divider, List, ListItem, ListItemIcon, ListItemText, ListItemButton } from '@mui/material';
import DrawerHeader from './DrawerHeader';
import { AccountCircle, NotificationImportant, MoveUp, Group, Groups, TableView, Dashboard, SyncOutlined, AccountBalance, ChevronLeft, ChevronRight, CollectionsBookmark, PersonOutline, Assessment, Paid, Logout, PersonOutlineOutlined, FactCheck } from '@mui/icons-material';

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} - 12px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} - 12px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const DrawerComp = ({ open, handleDrawerClose }) => {
	const theme = useTheme();
	const history = useHistory();
  const endSession = () => {
    logout();
    history.push("/admin/auth");
  }

  let user = sessionStorage.getItem('user');
  const [userRole, setUserRole] = React.useState(null);
  
  React.useEffect(() => {
    if (user) {
      user = JSON.parse(user);
      setUserRole(Number(user.role_id));
    }
  }, [user]);

	return (
		<Drawer variant="permanent" open={open}>
        <DrawerHeader>
					<Typography sx={{ color: 'rgba(0, 0, 0, 0.7)', fontWeight: 500, px: 2, }}>Admin Center</Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </DrawerHeader>
        <React.Fragment>
          {
            (userRole && (userRole === 6 || userRole === 1)) &&
            <>
              <Divider />
              <List>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => history.push("/")}>
                  <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                      <Dashboard />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => history.push("/admin/customers")}>
                  <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                      <AccountCircle />
                    </ListItemIcon>
                    <ListItemText primary="Customers" sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </List>
            </>
          }
        </React.Fragment>
        <React.Fragment>
          { (userRole && (userRole === 5 || userRole === 1)) &&
            <>
              <Divider />
              <List>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => history.push("/transfers")}>
                  <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                      <SyncOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Transfer center" sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => history.push("/transfers/transactions")}>
                  <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                      <MoveUp />
                    </ListItemIcon>
                    <ListItemText primary="Transfers" sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </List>
            </>
          }
        </React.Fragment>
        <React.Fragment>
          { userRole && (userRole === 1 || userRole === 7) &&
            <>
              <Divider />
              <List>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => history.push("/withdrawals")}>
                  <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                      <Assessment />
                    </ListItemIcon>
                    <ListItemText primary="Withdrawals" sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </List>
            </>
          }
        </React.Fragment>
        <React.Fragment>
          { userRole && (userRole === 1 || userRole === 3 || userRole === 4) &&
            <>
              <Divider />
              <List>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => history.push("/loans")}>
                  <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                      <AccountBalance />
                    </ListItemIcon>
                    <ListItemText primary="Loans" sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => history.push("/loans/borrowers")}>
                  <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                      <PersonOutlineOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Borrowers" sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => history.push("/loans/collection")}>
                  <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                      <CollectionsBookmark />
                    </ListItemIcon>
                  <ListItemText primary="Collection Sheets" sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => history.push("/investments")}>
                  <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                      <Paid />
                    </ListItemIcon>
                    <ListItemText primary="Investments" sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => history.push("/investments/investors")}>
                  <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                      <Groups />
                    </ListItemIcon>
                    <ListItemText primary="Investors" sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </List>
              <Divider />
            </>
          }
        </React.Fragment>
        <React.Fragment>
          { userRole && (userRole === 1 || userRole === 7) &&
            <>
              <List>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => history.push("/admin/dashboard")}>
                  <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                      <Dashboard />
                    </ListItemIcon>
                    <ListItemText primary="Administration" sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => history.push("/admin/users")}>
                  <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                      <PersonOutline />
                    </ListItemIcon>
                    <ListItemText primary="Users" sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </List>
            </>
          }
        </React.Fragment>
        
        <React.Fragment>
          { userRole && (userRole === 1 || userRole === 7) &&
            <>
              <Divider />
              <List>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => history.push("/admin/reporting")}>
                  <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                      <Assessment />
                    </ListItemIcon>
                    <ListItemText primary="Reporting" sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </List>
            </>
          }
        </React.Fragment>
        <React.Fragment>
          { userRole && (userRole === 1 || userRole === 2) &&
            <>
              <Divider />
              <List>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => history.push("/admin/audit")}>
                  <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                      <FactCheck />
                    </ListItemIcon>
                    <ListItemText primary="Audit" sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => history.push("/admin/audit/data")}>
                  <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                      <TableView />
                    </ListItemIcon>
                    <ListItemText primary="Data Table" sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => history.push("/admin/audit/customers")}>
                  <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                      <AccountCircle />
                    </ListItemIcon>
                    <ListItemText primary="Customers" sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => history.push("/admin/audit/users")}>
                  <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                      <Group />
                    </ListItemIcon>
                    <ListItemText primary="Users" sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => history.push("/admin/audit/notifications")}>
                  <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                      <NotificationImportant />
                    </ListItemIcon>
                    <ListItemText primary="Notifications" sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </List>
            </>
          }
        </React.Fragment>
        <Divider />
        <List>
          {/* <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
              <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                <NotificationsNone />
              </ListItemIcon>
              <ListItemText primary="Notifications" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem> */}
          {/* <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
              <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                <AccountBox />
              </ListItemIcon>
              <ListItemText primary="My Profile" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem> */}
          <ListItem disablePadding sx={{ display: 'block' }} onClick={() => endSession()}>
            <ListItemButton sx={{ minHeight: 48, px: 0.5, justifyContent: open ? 'initial' : 'center' }}>
              <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', ml: 1, justifyContent: 'center' }}>
                <Logout />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List> 
        <Divider />
      </Drawer>
	)
}

export default DrawerComp;