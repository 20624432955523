import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { PageTitle } from '../../components/StyledComponents';
import { AddCircle, ArrowDropUp } from '@mui/icons-material';
import NewUser from './NewUser';
import { Grid, Typography, Box, CircularProgress } from '@mui/material';
import NewInvestment from '../Investment/NewInvestment';
import AddLoan from '../Loans/AddLoan';
import NewAreaCode from './NewAreaCode';
import axios from 'axios';
import { getReports, getLoans } from '../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';

const Wrapper = styled.div`
	position: relative;
	width: 100%;
`;
const Item = styled.div`
	width: 100%;
	height: 250px;
	box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.32);
	border-radius: 0.125rem;
	padding: 0.75rem;
	background-color: #E6E6E690;
	overflow: hidden;
	cursor: pointer;
	transition: all 0.2s;
	&:hover {
		transform: scale(1.0125);
	}
`;
const Img = styled.img`
	width: 100%;
	/* max-height: 4rem; */
`;

const card = {
	box: {
		backgroundColor: '#FFFFFF',
		height: '100%',
		padding: '0.75rem',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '1rem'
	},
	boxII: {
		backgroundColor: '#FFFFFF',
		height: '100%',
		padding: '0.75rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		gap: '1rem'
	},
	boxIIHeader: {
		fontSize: '1.25rem',
		fontWeight: 'bold',
		marginBottom: '0.5rem'
	},
	icon: {
		color: '#F43232',
		fontSize: '3rem'
	}
}

const cardStyle = {
	box: {
		backgroundColor: '#FFFFFF',
		minHeight: '100%',
		padding: '0.5rem',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start'
	},
	titleSmall: {
		color: 'rgba(0, 0, 0, 0.45)',
		fontSize: '1.25rem',
	},
	titleLarge: {
		fontWeight: 700,
		fontSize: '2.75rem',
		color: 'rgba(0, 0, 0, 0.85)'
	},
	titleBox: {
		display: 'flex',
		alignItems: 'center'
	},
	subTitle: {
		fontSize: '1.25rem',
	},
	icon: {
		color: '#52C41A',
		fontSize: '2.5rem',
	},
	footer: {
		marginTop: '0.5rem',
		fontSize: '0.875rem'
	}
}

const Admin = () => {
	const dispatch = useDispatch()
	const [newUserModal, setNewUserModal] = useState(false);
	const [newInvestmentModal, setNewInvestmentModal] = useState(false);
	const [newLoanModal, setNewLoanModal] = useState(false);
	const [newAreaModal, setNewAreaModal] = useState(false);
	const [users, setUsers] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const report = useSelector(state => state.nebo.report);
	const loans = useSelector(state => state.nebo.loans);

	let token = JSON.parse(sessionStorage.getItem('token'));

	const getUsers = async () => {
		setLoading(true);
		const config = {
			method: 'get',
			url: "list/users",
			headers: {
				'Authorization': 'Bearer ' + token
			}
		}
	
		try {
			const res = await axios(config);
			setLoading(false);
			if(res.status === 200) {
					let { data } = res;
					setUsers(data);
				}
			} catch (error) {
				console.log(error);
				setError(error);
				setLoading(false);
			}
	}

	React.useEffect(() => {
		getUsers();
		dispatch(getReports(token));
		dispatch(getLoans(token));
	}, []);

	return (
		<Wrapper>
			<PageTitle>Admin Dashboard</PageTitle>
			<Grid container spacing={4}>
				<Grid item xs={12} md={6} lg={4}>
					<Item>
						<Box sx={cardStyle.box}>
								<Typography sx={cardStyle.titleSmall} variant='button'>Contributors</Typography>
								<Typography sx={cardStyle.titleLarge}>{ !users ? <CircularProgress /> : users.length }</Typography>
								<Box sx={cardStyle.titleBox}>
									<Typography sx={cardStyle.subTitle}>Active Users</Typography>
									<ArrowDropUp sx={cardStyle.icon} />
								</Box>
							</Box>
					</Item>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Item>
						<Box sx={cardStyle.box}>
							<Typography sx={cardStyle.titleSmall} variant='button'>transactions</Typography>
							<Typography sx={cardStyle.titleLarge}>{ !report.data ? <CircularProgress /> : report?.data?.length}</Typography>
							<Img src="/main/assets/trx-chart.svg" alt="trx" />
							<Typography sx={cardStyle.footer}>Daily visits: 1,234</Typography>
						</Box>
					</Item>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Item>
						<Box sx={cardStyle.box}>
							<Typography sx={cardStyle.titleSmall} variant='button'>active loans</Typography>
							<Typography sx={cardStyle.titleLarge}>{ !loans.data ? <CircularProgress /> : loans?.data?.length }</Typography>
							<img src="/main/assets/loans-chart.svg" alt="loans" />
							<Typography sx={cardStyle.footer}>Conversion Rate: 60%</Typography>
						</Box>
					</Item>
				</Grid>
			</Grid>
			<Grid container spacing={4} sx={{ marginY: '1rem' }}>
				<Grid item xs={12} md={6} lg={4}>
					<Item onClick={() => setNewUserModal(true)}>
						<Box sx={card.boxII}>
							<Box>
								<Typography sx={card.boxIIHeader}>New User</Typography>
								<Typography>New User</Typography>
							</Box>
							<Box>
								<AddCircle sx={card.icon} />
							</Box>
						</Box>
					</Item>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Item onClick={() => setNewInvestmentModal(true)}>
						<Box sx={card.boxII}>
							<Box>
								<Typography sx={card.boxIIHeader}>New Investment</Typography>
								<Typography>New Investment</Typography>
							</Box>
							<Box>
								<AddCircle sx={card.icon} />
							</Box>
						</Box>
					</Item>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Item onClick={() => setNewLoanModal(true)}>
						<Box sx={card.boxII}>
							<Box>
								<Typography sx={card.boxIIHeader}>New Loan</Typography>
								<Typography>New Loan</Typography>
							</Box>
							<Box>
								<AddCircle sx={card.icon} />
							</Box>
						</Box>
					</Item>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Item onClick={() => setNewAreaModal(true)}>
						<Box sx={card.boxII}>
							<Box>
								<Typography sx={card.boxIIHeader}>New Area Code</Typography>
								<Typography>New Area Code</Typography>
							</Box>
							<Box>
								<AddCircle sx={card.icon} />
							</Box>
						</Box>
					</Item>
				</Grid>
			</Grid>
			<Fragment>
				{
					newUserModal === true &&
					<NewUser
						open={newUserModal}
						close={() => setNewUserModal(false)}
					/>
				}
			</Fragment>
			<Fragment>
				{
					newInvestmentModal === true &&
					<NewInvestment
						open={newInvestmentModal}
						close={() => setNewInvestmentModal(false)}
					/>
				}
			</Fragment>
			<Fragment>
				{
					newLoanModal === true &&
					<AddLoan
						open={newLoanModal}
						close={() => setNewLoanModal(false)}
					/>
				}
			</Fragment>
			<Fragment>
				{
					newAreaModal === true &&
					<NewAreaCode
						open={newAreaModal}
						close={() => setNewAreaModal(false)}
					/>
				}
			</Fragment>
		</Wrapper>
	)
}

export default Admin;