import React from 'react';
import { AppBar, Toolbar, Box, IconButton } from '@mui/material';
import sc from 'styled-components';
import { AccountCircle, Menu } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const drawerWidth = 200;

const MuiAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const Logo = sc.img`
	width: 1.75rem;
	height: 1.75rem;
	margin-right: 0.5rem;
	border-radius: 0.25rem;
`;
const LogoText = sc.h2`
	font-size: 2rem;
	font-weight: 700;
	color: #F43232;
	line-height: 1.5rem;
`;
const LogoContainer = sc.div`
	display: flex;
	align-items: flex-end;
`;
const Avatar = sc.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  p {
    color: #222222;
  }
  .icon {
    color: #222222;
  }
`;

const AppBarComp = ({ open, handleDrawerOpen, user }) => {
	return (
		<MuiAppBar position="fixed" open={open} sx={{ backgroundColor: '#ffffff' }} >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
							color: '#F43232',
              ...(open && { display: 'none' }),
            }}
          >
            <Menu />
          </IconButton>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <LogoContainer>
              <Logo src="/main/assets/logo.svg" alt="" />
              <LogoText>Nebo</LogoText>
            </LogoContainer>
            <Avatar>
              <p>{user && user.firstname}</p>
              <AccountCircle className="icon" />
            </Avatar>
          </Box>          
        </Toolbar>
      </MuiAppBar>
	)
}

export default AppBarComp;