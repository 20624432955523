import { useState } from 'react';
import styled from 'styled-components';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { TextInput, Check } from '../components/StyledComponents';
import { CircularProgress, Grid, FormControl, IconButton, Button as MuiButton } from '@mui/material';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify'


const Container = styled.section`
	display: flex;
	* {
		letter-spacing: -0.02em;
	}
	a {
		text-decoration: none;
		color: inherit;
	}
	@media(max-width: 625px) {
		flex-direction: column;
	}
`;
const Title = styled.h1`
	color: ${props => props.color};
	font-size: 3rem;
	font-weight: 700;
	line-height: 56px;
	@media(max-width: 625px) {
		text-align: center;
	}
`;
const Left = styled.div`
	height: 100vh;
	display: grid;
	place-items: center;
	width: 100%;
	width: 50vw;
	padding: 1rem;
	background: linear-gradient(180deg, rgba(9, 30, 66, 0.31) 0%, rgba(9, 99, 255, 0) 100%);
	@media(max-width: 625px) {
		height: 30vh;
		width: 100%;
	}
`;
const LogoContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
const Logo = styled.img`
	border-radius: 0.5rem;
	aspect-ratio: 1;
`;
const Main = styled.div`
	width: 50vw;
	display: grid;
	place-items: center;
	padding: 1rem;
	@media(max-width: 625px) {
		width: 100%;
		margin: 4rem 0;
	}
`;
const MainWrapper = styled.div`
	max-width: 400px;
	@media(max-width: 825px) {
		max-width: unset;
		width: 100%;
	}
	@media(max-width: 625px) {
		max-width: 400px;
	}
`;
const Text = styled.p`
	font-size: ${props => props.size};
	color: ${props => props.color};
	font-weight: ${props => props.weight};
	margin-top: ${props => props.mt};
	@media(max-width: 625px) {
		text-align: center;
	}
`;

const Line = styled.div`
	background-color: rgba(0, 0, 0, 0.1);
	height: 1px;
	width: 100%;
	max-width: 300px;
	margin: 0 auto;
`;
const Link = styled.a`
	display: block;
	color: #F43232 !important;
	text-align: center;
	margin: 1rem 0;
`;


export default function Login() {
	const [type, setType] = useState('password');

	const switchType = () => {
		type === "password" ? setType("text") : setType("password");
	}
	const history = useHistory();

	const login = async (data, setSubmitting) => {  
		const URL = "signin";
		const user = {
			email: data.username,
			password: data.passcode
		}
		try {
			const res = await axios.post(URL, user);
			const { status } = res;
			if (status === 200) {
				const { data } = res;
				sessionStorage.setItem("token", JSON.stringify(data.token));
				sessionStorage.setItem("user", JSON.stringify(data.userinfo));
				const { userinfo } = res.data;
				if (userinfo.role_id === 1 || userinfo.role_id === 6) {
					history.push("/");
				} else if (userinfo.role_id === 3 || userinfo.role_id === 4) {
					history.push("/loans");
				} else if (userinfo.role_id === 5) {
					history.push("/transfers");
				} else if (userinfo.role_id === 2) {
					history.push("/admin/audit");
				}
			}
		} catch (err) {
			console.log(err);
			setSubmitting(false);
			let msg = JSON.stringify(err.response.data);
			if (msg) {
				toast.error(msg)
			} else {
				toast.error(err.message);
			}
		}
	}

	// formik
	const INIT = { username: '', passcode: '', rememberMe: false };
	const schema = Yup.object().shape({
		username: Yup.string().trim().required("Please enter your username"),
		passcode: Yup.string().trim().required("Please enter your password"),
		rememberMe: Yup.boolean().oneOf([true, false]),
	});

	return (
		<Container>
			<Left>
				<LogoContainer>
				<Logo src="/main/assets/logo.svg" alt=""/>
				<Title color="#F43232">Nebo</Title>
				</LogoContainer>
			</Left>
			<Main>
				<MainWrapper>
					<Title>Sign in</Title>
					<Text size="1.25rem">Sign in if you have an account here.</Text>
					<Formik
						initialValues={INIT}
						validationSchema={schema}
						validateOnBlur={false}
						onSubmit={(values, { setSubmitting }) => {
							login(values, setSubmitting);
						}}
					>
						{({ isSubmitting, handleSubmit }) => {
							return (
								<FormikForm onSubmit={handleSubmit}>
									<Grid container rowSpacing={4} sx={{ marginY: '1.5rem'}}>
										<Grid item xs={12}>
											<TextInput name="username" label="Username" fullWidth />
										</Grid>
										<Grid item xs={12}>
											<FormControl fullWidth sx={{ position: 'relative' }}>
												<TextInput type={type} name="passcode" label="Password" />
												<IconButton onClick={switchType} sx={{ position: 'absolute', top: '15%', right: '1%', background: '#FFFFFF', }}>
													{ type === "text" ? <VisibilityOff /> : <Visibility /> }
												</IconButton>
											</FormControl>
										</Grid>
										<Grid item xs={12} sx={{ marginY: '-0.5rem' }}>
											<Check name="rememberMe" label="Remember Me" />
										</Grid>
										<Grid item xs={12}>
											<MuiButton variant="contained" fullWidth disabled={isSubmitting} type="submit" sx={{ height: '3.25rem' }}>
												{
													isSubmitting === true ? <CircularProgress size="2rem" sx={{ color: '#FFFFFF' }} /> : 'Sign in'
												}
											</MuiButton>
										</Grid>
									</Grid>
								</FormikForm>
							)
						}}
					</Formik>
					<Line />
					<Link href="/privacy-policy">Privacy Policy</Link>
				</MainWrapper>
			</Main>
		</Container>
	)
}
