import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { PageTitle, Loading } from '../../components/StyledComponents';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { Tooltip, Button, FormControl, Grid, InputLabel, OutlinedInput, InputAdornment, IconButton, Typography } from '@mui/material';
import { searchFunc } from '../../components/lib';
import axios from 'axios';
import DeleteUser from './DeleteUser';
import View from './View';
import { CSVLink } from 'react-csv';

const Table = styled.div`
	margin-top: 2rem;
	.user-name {
		cursor: pointer;
		&:hover {
			color: #D70900;
		}
	}
`;
const Action = styled.div`
	.delete {
		color: #F43232;
		margin: 0 0.25rem;
		cursor: pointer;
	}
`;
const Wrapper = styled.div`
	position: relative;
	width: 100%;
	.header-bar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;
		@media(max-width: 768px) {
			flex-direction: column;
			align-items: flex-end;
		}
	}
	.formControl {
		width: 100%;
		max-width: 400px;
		@media(max-width: 768px) {
			max-width: unset;
		}
	}
`;

const Users = () => {
	const activeUser = JSON.parse(sessionStorage.getItem('user'));
	const [users, setUsers] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [deleteModal, setDeleteModal] = useState(false);
	const [viewModal, setViewModal] = useState(false);
	const [user, setUser] = useState(null);
	const deleteUserModal = (val) => {
		setUser(val);
		setDeleteModal(true);
		console.log(val)
	};

	const openView = val => {
		setViewModal(true);
		setUser(val);
	}

	let token = sessionStorage.getItem('token');

	const getUsers = async () => {
		setLoading(true);
		const config = {
			method: 'get',
			url: "list/users",
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			}
		}
	
		try {
			const res = await axios(config);
			setLoading(false);
			if(res.status === 200) {
					let { data } = res;
					data = data.sort((a, b) => a.Name.localeCompare(b.Name));
					console.log(data);
					setUsers(data);
				}
			} catch (error) {
				console.log(error);
				setError(error);
				setLoading(false);
			}
	}

	const handleUserUpdate = () => {
		getUsers();
	  }
	
	  const updateUserData = (updatedData) => {
		setUsers((prevUsers) =>
		  prevUsers.map((user) => (user.id === updatedData.id ? updatedData : user))
		);
	  };

	const cols = [
		{
			name: 'Name',
			selector: row => row.Name,
			sortable: true,
			cell: row => <div className="user-name" onClick={() => openView(row)}>{row.Name}</div>
		},
		{
			name: 'Email',
			selector: row => row.personal_email,
			sortable: true
		},
		{
			name: 'Designation',
			selector: row => row["User Role"],
			sortable: true
		},
		{
			name: 'Status',
			selector: row => row["Activity Status"],
			sortable: true
		},
		{
			name: 'Action',
			selector: row => row.user_type,
			cell: row => <Action>
				<Tooltip title="Delete user">
					<IconButton>
						<DeleteIcon
							className="delete"
							onClick={() => deleteUserModal(row)}
						/>
					</IconButton>
				</Tooltip>
			</Action>
		},
		
	];

	// search n filter
	const [text, setText] = useState('');
	const handleTextChange = e => setText(e.target.value);

	useEffect(() => {
		getUsers();
		//eslint-disable-next-line
	}, []);
	
		return (
			<Wrapper>
				<PageTitle>Users</PageTitle>
				<Grid container columnSpacing={4} sx={{ alignItems: "center", justifyContent: "space-between", marginY: '2rem' }}>
					<Grid item xs={12} className="header-bar">
						<FormControl className='formControl'>
							<InputLabel htmlFor="search-input">Search</InputLabel>
							<OutlinedInput
								id="search-input"
								onChange={handleTextChange}
								value={text}
								endAdornment={
									<InputAdornment position="end">
										<IconButton edge="end">
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								}
								label="Search"
							/>
						</FormControl>
						<Button variant="outlined" sx={{ height: '3.625rem', paddingX: '2rem' }}>
							{	users &&
								<CSVLink data={users} filename="user.csv" className='download-link'>
									download
								</CSVLink>
							}
						</Button>
					</Grid>
				</Grid>
				<Fragment>
					{
						loading === true && !users ? <Loading text={"Please wait"} /> :
						(
							error ? <Typography variant="h6" color="error" sx={{ width: '100%', maxWidth: '400px' }}>{error.response.data}: {error.message}</Typography> :
							users !== null &&
							<Table>
								<DataTable
									columns={cols}
									data={
										users.filter(item => {
											if(text.trim() === "") {
												return item;
											} else if(item.Name.toLowerCase().includes(text.toLowerCase())) {
												return item;
											} else if(item.personal_email.toLowerCase().includes(text.toLowerCase())) {
												return item;
											} else if(item.phone_no.toLowerCase().includes(text.toLowerCase())) {
												return item;
											}
										})
									}
									pagination paginationPerPage={20}
									noDataComponent={<Typography variant="h6" color="error" sx={{ width: '100%', maxWidth: '400px' }}>No users found</Typography>}
								/>
							</Table>
						)
					}
				</Fragment>
				<Fragment>
					{
						deleteModal === true &&
						<DeleteUser
							open={deleteModal}
							close={() => setDeleteModal(false)}	
							user={user}
							getUsers={getUsers}
							activeUser={activeUser}
						/>
					}
				</Fragment>
				<Fragment>
					{
						viewModal === true &&
						<View
							open={viewModal}
							close={() => { setViewModal(false); setUser(null); }}
							data={user}
							setUser={setUser}
							onUserUpdate={handleUserUpdate}
							updateUserData={updateUserData}
						/>
					}
				</Fragment>
			</Wrapper>
		)
}

export default Users;