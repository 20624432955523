import React from 'react';
import Layout from './Layout';
import { token } from './auth';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest}) => {
  return (
    <Layout>
      <Route 
        {...rest}
        render = {(props) => token() ? <Component {...props} /> : 
          <Redirect to={{ pathname: '/auth/login', state: { from: props.location }}} />
        }
      />
    </Layout>
  );
};

export default PrivateRoute;
