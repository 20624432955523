import React from 'react';
import { Modal } from 'react-responsive-modal';
import { Header } from '../../../components/StyledComponents';
import { Grid, Button, Typography, Box, CircularProgress } from '@mui/material';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';


const Container = styled.div`
	width: 100%;
	margin: 2rem 0;
`;

const CancelLoan = ({ open, close, closeParent, loan, getLoans }) => {
	const token = sessionStorage.getItem('token');
	const [loading, setLoading] = React.useState(false);
	const cancelLoan = async () => {
		setLoading(true);
		const data = {
			loan_id: loan.id
		}
		const config = {
			method: 'post',
			url: 'loan/cancel',
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			},
			data: data
		}
		try {
			const res = await axios(config);
			setLoading(false);
			if(res.status === 200) {
				toast.success("Loan canceled successfully!");
				close();
				closeParent();
				getLoans();
			} else {
				toast.warn(JSON.stringify(res.data));
				close();
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
			toast.error(error.message + ": " + error.response.data);
			close();
		}
	}

	return (
		<Modal classNames={{ modal: 'auditModal' }} open={open} onClose={close} center>
			<Container>
				<Header>Cancel Loan</Header>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<Box sx={{ margin: '1.5rem 0' }}>
							<Typography variant="h5">
								Sure to cancel the { Number(loan.amount_disbursed).toLocaleString() } loan issued to { loan.account_name }?
							</Typography>
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
							<WarningAmberIcon sx={{ fontSize: '2rem', color: 'red' }} />
							<Typography sx={{ color: 'red' }} variant="h6">This action is irreversible</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Button onClick={cancelLoan} disabled={loading} fullWidth variant="contained" sx={{ height: '3.5rem' }}>
							{
								loading === true ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : 'proceed'
							}
						</Button>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Button fullWidth variant="outlined" sx={{ height: '3.5rem' }} onClick={close} disabled={loading}>close</Button>
					</Grid>
				</Grid>
			</Container>
		</Modal>
	)
}

export default CancelLoan;
