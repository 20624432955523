import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { borrowers } from './data';
import { Container, Header, Loading, Button } from '../../components/StyledComponents';

const Box = styled.div`
	border: 2px solid #C8613A;
	margin: 2rem 0;
	padding: 1rem;
	display: flex;
	${Button} {
		width: 15rem;
	}
`;
const Picture = styled.div`
	width: 100%;
	max-width: 11.5rem;
	height: 12rem;
	background: #C4C4C4;
`;
const Image = styled.img``;
const Info = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1rem;
	width: 100%;
`;
const InfoItem = styled.div`
	padding: 0 1rem;
`;
const Item = styled.div`
	margin: 0.5rem;
`;
const Text = styled.p`
	font-weight: ${props => props.weight};
	font-size: 1.25rem;
`;
const HeaderRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 1rem 0;
	${Button} {
		width: 10rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
const Summary = styled.div`
	.rdt_TableHeadRow {
		background: #CF7552;
		font-weight: 700;
		font-size: 1rem;
		color: #FFFFFF;
	}
`;

const CollectionInfo = () => {
	const userId = useParams().id;
	const [userProfile, setUserProfile] = useState(null);
	const getUser = id => {
		let res = borrowers.find(item => item.id === Number(id));
		setUserProfile(res);
	}
	useEffect(() => {
		getUser(userId);
		return () => null;
	}, [userId]);
	
	if(!userProfile) {
		return <Loading text={'Loading user data...'} />
	}

	const { name, account } = userProfile;
	const cols = [
		{
			name: 'Name',
			selector: row => row.name,
			sortable: true
		},
		{
			name: 'Date Released',
			selector: row => row.release_date,
			sortable: true
		},
		{
			name: 'Amount',
			selector: row => <>NGN {row.amount.toLocaleString()}</>,
			sortable: true
		},
		{
			name: 'Repayment Plan',
			selector: row => row.repayment,
			sortable: true
		},
		{
			name: 'Status',
			selector: row => row.status,
			sortable: true
		}
	];
	const data = [
		{
			id: 5893,
			name: 'Johnson Byrde',
			amount: 59034,
			release_date: '12/23/2021',
			repayment: 'monthly',
			maturity: 'Feb 2023',
			status: 'active'
		},
		{
			id: 5894,
			name: 'Yohan Fitcher',
			amount: 1848340,
			release_date: '12/23/2021',
			repayment: 'Bi-weekly',
			maturity: 'Jan 2022',
			status: 'paid'
		}
	]
	return (
		<Container style={{ margin: 0, padding: '1rem' }}>
			<Header>Customer Information</Header>
			<Box>
				<Picture />
				<Info>
					<InfoItem>
						<Item>
							<Text>{name}</Text>
						</Item>
						<Item>
							<Text>Account number: <br />{account}</Text>
						</Item>
						<Item>
							<Text>Card number: <br />4783</Text>
						</Item>
					</InfoItem>
					<InfoItem>
						<Item><Text>Home address:</Text></Item>
						<Item><Text>Business address:</Text></Item>
						<Item><Text>Area code:</Text></Item>
						<Item><Text>State: Oyo</Text></Item>
					</InfoItem>
				</Info>
			</Box>
			<Box style={{ padding: '1.5rem 1rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<Text weight="700">Loan balance:</Text>
				<Button>NGN {Number((Math.random() * 473547).toFixed(2)).toLocaleString()}</Button>
			</Box>
			<Summary>
				<HeaderRow>
					<Text>Transactions</Text>
				</HeaderRow>
				<DataTable columns={cols} data={data} />
			</Summary>
		</Container>
	)
}

export default CollectionInfo;