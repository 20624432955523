import React from 'react';
import { Modal } from 'react-responsive-modal';
import styled from 'styled-components';
import { Container } from '../../components/StyledComponents';
import { Button } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Checkbox, FormControlLabel, TextField, CircularProgress } from '@mui/material';


const Wrapper = styled.div`
	padding: 1rem 0;
	min-height: 250px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 0.25rem;
	.customerName {
		font-weight: 700;
	}
	.deleteBtnArray {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 1rem;
		margin-top: 1rem;
		.yes {
			background: green;
		}
	}
`;
const Warning = styled.div`
	color: #F43232;
	display: flex;
	align-items: center;
	font-weight: 700;
	font-size: 1rem;
	.warningIcon {
		margin-right: 0.25rem;
	}
`;

const DeleteUser = ({ open, close, user, getUsers, activeUser }) => {
	const [loading, setLoading] = React.useState(false);
	const[checked, setChecked] = React.useState(false);
	const [password, setPassword] = React.useState('');
	const token = sessionStorage.getItem('token');

	const deleteUser = async () => {
		setLoading(true);
		const usr = {
			user_email: user.personal_email,
		}
		const config = {
			method: 'post',
			url: "status/deactivate/user",
			headers: {
				'Authorization': 'Bearer ' + JSON.parse(token)
			},
			data: usr
		}
		if(user.personal_email === activeUser.email) {
			toast.error("You can't delete your own account");
			setLoading(false);
			close();
		} else if(activeUser.role_id !== 1) {
			toast.error("You do not have the permission to perform this operation.");
			setLoading(false);
			close();
		} else {
			try {
				const response = await axios(config);
				if(response.status === 200) {
					toast.success('User deleted successfully');
					window.location.reload(false);
					setLoading(false);
					close();
				} else {
					toast.error('Error deleting user!');
					setLoading(false);
					close()
				}
			} catch (err) {
				setLoading(false);
				console.log(err);
				toast.error('Error deleting user');
				close();
			}
		}
	}

	return (
		<Modal
			classNames={{ modal: 'deleteModal' }}
			open={open} onClose={close} center
			closeIcon={<DisabledByDefaultIcon color="error" />}
		>
			<Wrapper>
				<Warning>
					<WarningAmberIcon className="warningIcon" />
					This action is irreversible!
				</Warning>
				<Container>
					Are you sure you want to delete the user <span className="customerName">{user.Name}</span>?
				</Container>
				<div style={{ margin: '0.5rem 0', display: 'grid', gap: '1rem' }}>
					<FormControlLabel control={<Checkbox value={checked} onChange={e => setChecked(e.target.checked)} />} label="I understand the risks" />
				</div>
				<div className='deleteBtnArray'>
					<Button variant="contained" color="error" disabled={(checked === false || loading === true) ? true : false} onClick={deleteUser} sx={{ height: '3.5rem' }}>
						{
							loading === true ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : 'Yes'
						}
					</Button>
					<Button variant="outlined" color="secondary" disabled={loading} onClick={close} sx={{ height: '3.5rem', border: '1px solid #707070' }}>No</Button>
				</div>
			</Wrapper>
		</Modal>
	)
}

export default DeleteUser;